import React from 'react'
import Page from '../../components/page/page';
import LegalPage from '../../components/legal-page/legal-page';
import TitleCard from '../../components/title-card/TitleCard';
import cardBackground from '../../assets/privacy-policy-banner/bg-banner.png';
import MetaDecorator from '../../hooks/metaDecorator';

const subtext = [
    'This policy sets out the food safety requirements that we require Vendors to follow in order to sell their goods in the Marketplace.',
    <br />, <br />,
    'All Menu Items that you sell on the Marketplace must comply with your Agreement with Localo, including all Applicable Law and Localo Policies. If you are found to be in material breach of these obligations, we may suspend the provision of our Services.'
];

const headers = [
    'Purpose',
    'What we expect from our Sellers'
];

const markdown = [
    `# Purpose

We take the protection of our customers very seriously and we are sure our vendors are doing so too. Good food hygiene systems are important to ensure that consumers are healthy and that their food is astounding. This policy sets out the food hygiene and safety requirements that we require Vendors to follow in order to sell their goods on the marketplace.
`,

    `# What we expect from our Sellers

You are responsible for ensuring that the products that you sell are safe and compliant with Applicable Law.  If you are uncertain about any legal requirements, please seek advice from an expert. The FSA Food Hygiene for your business guidance and the FSA Safer Food Better Business can also be consulted.

We expect you to have a food safety management system in place that deals with all aspects of food safety and hygiene for your business in compliance with food hygiene regulations, including (but not limited to):

- HACCP plans;
- Cross-contamination;
- Allergens;
- Cleaning and Waste;
- Maintenance;
- Chilling, defrosting, freezing, cooking; reheating; 
- Temperature control: cold and hot holding-in particular, when waiting for the Rider's collection  
- Personal hygiene and fitness to work;
- Pest Control;
- COSHH;
- Takeaway packaging;
- Staff training;
- Supplier assurance;
- Traceability; 
- Monitoring and auditing; and
- Recall/withdrawal.
`
];

export default function FoodHygieneSafetyPolicy() {
    return (
        <Page>
            <MetaDecorator
                title="Localo | Food Hygiene and Safety Policy"
                description="Localo | Read about our food hygiene and safety policy here"
            />
            <TitleCard overlay="mustard-mint" background={cardBackground}>
                <h1>Food Hygiene and Safety Policy</h1>
            </TitleCard>

            <LegalPage
                title="Food hygiene and safety policy"
                headers={headers}
                contents={markdown}
                subtitle="Last updated: 17 December 2020"
                subtext={subtext}
            />
        </Page>
    )
}
