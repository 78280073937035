import React from 'react';
import ContainerComponent from '../container/container';
import Vegetable from '../../assets/join-localo/vegetable.svg';
import Subscription from '../../assets/join-localo/subscription.svg';
import DeliveryVan from '../../assets/join-localo/delivery-van.svg';
import './join-localo-why.scss';

export default function JoinLocaloWhy() {
    return (
        <div className='join-localo-why' id='why-localo'>
            <ContainerComponent>
                <div className='join-localo-why__list'>
                    <div className='join-localo-why__item'>
                        <div className='join-localo-why__icon'>
                            <img src={Vegetable} alt='Box-based buying' />
                        </div>
                        <h2 className='join-localo-why__title'>Box-based buying</h2>
                        <p className='join-localo-why__desc'>The idea of Localo is to make it easier for your customers to shop with you. That’s why we suggest a ‘box-based’ approach, where you put together a package of the things they’re most likely to want. Like a weekly fruit and veg delivery. A freezer-filling family-sized meat pack. Regular pet products or monthly bath and body treats. Customers can pick the box you’ve compiled, or customise it according to what they need. So everything’s nice and convenient for them and for you. </p>
                    </div>
                    <div className='join-localo-why__item'>
                        <div className='join-localo-why__icon'>
                            <img src={Subscription} alt='Sign up for subscription' />
                        </div>
                        <h2 className='join-localo-why__title'>Sign up for subscription</h2>
                        <p className='join-localo-why__desc'>You want your customers to keep coming back. So do we, which is why Localo lets them set up a recurring subscription to your service. That way they get a regular delivery at regular intervals and you get their regular custom.</p>
                    </div>
                    <div className='join-localo-why__item'>
                        <div className='join-localo-why__icon'>
                            <img src={DeliveryVan} alt='Delivery’s a doddle' />
                        </div>
                        <h2 className='join-localo-why__title'>Delivery’s a doddle</h2>
                        <p className='join-localo-why__desc'>Our local networks of delivery drivers collect orders from you and take them to customers, hassle-free. Our intelligent software takes care of the logistics, so all you have to do is pop everything into bags or boxes and our drivers do the rest. And that means you can focus on what you do best — running a brilliant local business. </p>
                    </div>
                </div>
            </ContainerComponent>
            <div className='join-localo-why__hero'></div>
        </div>
    )
}