import React from 'react';
import './order-item.scss';

const OrderItemOneTIme = ({ item, price }) => {
  return (
    <div className='order__item-one_time'>
      <p className='order__item-one_time-text'>{item}</p>
      <p className='order__item-one_time-text'>{price}</p>
    </div>
  );
};

export default OrderItemOneTIme;
