import React from 'react';
import { Helmet } from 'react-helmet';

const MetaDecorator = ({ title, description }) => {
  return (
    <Helmet title={title} defaultTitle={title}>
      <title data-react-helmet="true">{title}</title>
      <meta name="title" content={title} data-react-helmet="true" />
      <meta name="description" content={description} />
      <meta property="og:title" content={title} data-react-helmet="true" />
      <meta
        property="og:description"
        content={description}
        data-react-helmet="true"
      />
      <meta property="og:type" content="website" data-react-helmet="true" />
    </Helmet>
  );
};

export default MetaDecorator;
