import React from 'react'
import ReactMarkdown from 'react-markdown'
import { useEffect } from 'react'
import Container from '../container/container'
import './legal-page.scss'

function flatten(text, child) {
    return typeof child === 'string'
        ? text + child
        : React.Children.toArray(child.props.children).reduce(flatten, text)
}

function HeadingRenderer(props) {
    var children = React.Children.toArray(props.children)
    var text = children.reduce(flatten, '')
    var slug = text.toLowerCase().replace(/\W/g, '-')
    return React.createElement('h' + props.level, { id: slug }, props.children)
}

export default function LegalPage({ title, subtitle, subtext, contents = [], headers = [] }) {
    const [activeID, setActiveID] = React.useState(headers[0] ? headers[0].toLowerCase().replace(/\W/g, '-') : '');

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <Container>
            <div className='legal-page'>
                <div className='legal-page-left'>
                    <div className='legal-page-links'>
                        {
                            headers.map((header, key) => {
                                return (<a onClick={(e) => { setActiveID(e.target.className) }} key={key} className={header.toLowerCase().replace(/\W/g, '-')} href={`#${header.toLowerCase().replace(/\W/g, '-')}`}>{header.replace('_', ' ')}</a>)
                            })
                        }
                    </div>
                </div>
                <div className='legal-page-right'>
                    <p className='legal-page-right-title'>{title}</p>
                    <p className='legal-page-right-subtitle'>{subtitle}</p>
                    {subtext !== null ? (
                        <p>{subtext}</p>
                    ) : null}
                    {contents.map((content, key) => {
                        return (
                            <div key={key} className={`legal-page-markdown ${headers[key] && activeID === headers[key].toLowerCase().replace(/\W/g, '-') ? 'active' : ''}`}>
                                <ReactMarkdown source={content} renderers={{ heading: HeadingRenderer }} />
                            </div>
                        )
                    })
                    }
                </div>
            </div>
        </Container>
    )
}
