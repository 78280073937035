import React from 'react';
import ContainerComponent from '../container/container';
import './join-localo-boost-sells.scss';

export default function JoinLocaloBoostSells() {
    return (
        <div className='join-localo-boost-sells'>
            <ContainerComponent>
                <h2 className='join-localo-boost-sells__title'>
                    Boost your shop’s sales
            </h2>
                <p className='join-localo-boost-sells__desc'>
                    This platform works for you, no matter the size of your store. We take care of all the logistics, so all you have to do is pop the order we pass on into the boxes its boxes or bags or other bundles, and have it ready for our delivery person to pick up. we provide. It’s a new route to an established market that might otherwise go to the nearest superstore.
            </p>
            </ContainerComponent>
        </div>
    )
}