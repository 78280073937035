import React, { useEffect, useState } from 'react'
import request from '../../helpers/request'
import style from './bid-grid.module.scss'

export default function BidGrid({ postcode }) {
  const [bids, setBids] = useState([])
  const [bidVendors, setBidVendors] = useState([])
  useEffect(() => {
    request(false).get(`/bids?postcode=${postcode}`).then(e => {
      setBids(e.data.data)
    })
    request(false).get(`/bidvendors?postcode=${postcode}`).then(e => {
      setBidVendors(e.data.data)
    })
  }, [ postcode ])
  return (
    bids.length > 0 ? 
      <div>
        <div className={style['bid-grid-header']}>
          <h2>Explore other shops on your high street</h2>
          <div>
            {
              bids.map(bid => {
                return React.Children.toArray(<Bid logo={bid.logo ? bid.logo.url : null} link={bid.website} />)
              })
            }
          </div>
        </div>
        <div className={style['bid-grid-grid']}>
          {
            bidVendors.map(bv => {
              return React.Children.toArray(
                <BidVendor website={bv.website} name={bv.name} social={bv.social} image={bv.images.length ? bv.images[0].url : null} />
              )
            })
          }
        </div>
      </div> : null
  )
}

const Bid = ({ logo, link }) => {
  return (
    <div className={style.bid}>
      <a href={link} target="_blank" rel="noopener noreferrer">
        <img alt="" src={logo} />
      </a>
    </div>
  )
}

const BidVendor = ({ name, image, social, website }) => {
  return (
    <div className={style.vendor}>
      <img alt="" src={image} />
      <h3>{name}</h3>
      <div className={style['vendor-link']}>
        <a className={style['vendor-link-website']} href={website} target="_blank" rel="noopener noreferrer">
          <p>Visit site</p>
        </a>
        {
          (social && social.link) ? <a className={style['vendor-link-social']} href={social.link} target="_blank" rel="noopener noreferrer">
            <span className={social.icon}></span>
          </a> : null
        }
      </div>
    </div>
  )
}