import React, { useEffect, useMemo, useState } from 'react'
import Request from '../../helpers/request'
import SimpleSelect from 'react-simple-styleable-select'

export default function SelectPaymentCard({onChange}) {
    const [cards, setCards] = useState([]);
    const [defaultCard, setDefaultCard] = useState(null);

    useEffect(() => {
        Request(true).get('/self/cards').then(e => {
            setCards(e.data.cards)
            setDefaultCard(e.data.default)
            onChange(e.data.default)
        })
    }, [onChange])

    const cardSelect = useMemo(() => {
        return cards.map(_ => {
            return {
                value: _.id,
                label: `**** **** **** ${_.card.last4}`
            }
        })
    }, [cards]);

    return (
        cardSelect.length ? <>
            <div>
                <p style={{textAlign: 'center', color: 'var(--color-raspberry-sorbet)'}}>Use an existing card</p>
                <SimpleSelect placeholder="Select a payment method" defaultValue={defaultCard} options={cardSelect} onChange={(_, val) => onChange(val)} />
            </div>
        </> : null
    )
}
