import React, { useState } from 'react';
import ContainerComponent from '../container/container';
import './join-localo-faq.scss';

const Accordion = ({ title, children }) => {
    const [isOpen, setOpen] = useState(false);
    return (
        <div className="join-localo-faq__item">
            <div
                className={`join-localo-faq__que`}
                onClick={() => setOpen(!isOpen)}
            >
                {title}
                <span className={`join-localo-faq__icon ${isOpen ? "is-open" : ""}`}></span>
            </div>
            <div className={`join-localo-faq__ans ${!isOpen ? "is-collapsed" : ""}`}>
                <div className='join-localo-faq__ans-content'>{children}</div>
            </div>
        </div>
    );
};

export default function JoinLocaloFAQ() {
    return (
        <div className='join-localo-faq' id='faq'>
            <ContainerComponent>
                <div className='join-localo-faq__title'>Frequently Asked Questions</div>
                <p className='join-localo-faq__desc'>Here are some common questions about selling on Localo.</p>
                <Accordion title="What’s this going to cost?">
                    Contact the team for the latest commission rates.
                </Accordion>
                <Accordion title="How do I create a Localo shop?">
                    It's easy. Just give us your shop name, address and phone number, tell us your opening times, and choose your main product category (eg butcher). Follow the simple instructions for setting up a merchant account to receive payments, and we’ll add you to the Localo service.
                </Accordion>
                <Accordion title="What can I sell?">
                    Almost anything that’s in your shop, subject to any legal restrictions. We’ve made suggestions for You’ll be able to bundle products into ready-made boxes of your own design, which your customers will be able to but your customers will also be able to create their own, based on the information you providecustomise to suit their own needs.
                </Accordion>
                <Accordion title="How do I get paid?">
                    Dead easy: your customer pays though our payment gateway (Stripe), which accepts payment methods including PayPal, Google Wallet and Apple Pay. The payment is immediately deposited into your Stripe merchant account. You can withdraw it to your bank account after seven days (see merchant terms and conditions for more details).
                </Accordion>
            </ContainerComponent>
        </div>
    )
}