import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import request from '../../helpers/request';
import Container from '../container/container';
import ProductGridItem from '../product-grid-item/product-grid-item';
import Loader from '../../components/loader/loader';

import './product-grid.scss';
// import { useHistory } from 'react-router';

export default function ProductGrid({ postcode, tag }) {
  const [loader, setLoader] = useState(false);

  const [inlineLoader, setInlineLoader] = useState(false);

  const [products, setProducts] = useState([]);

  const [page, setPage] = useState(1);

  const [more, setMore] = useState(true);
  // const history = useHistory();
  useEffect(() => {
    getProducts(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tag, postcode]);

  async function getProducts(withLoader = false) {
    setLoader(withLoader);
    setInlineLoader(true);
    try {
      let data;
      if (tag) {
        data = (
          await request(false).get(
            `/products?limit=20&page=${page}&tags[]=` + tag
          )
        ).data;
      } else {
        try {
          data = (
            await request(false).get(
              `/products?limit=20&page=${page}&postcode=${postcode}`
            )
          ).data;
        } catch (error) {
          data = {
            data: [],
          };
          return;
        }
      }
      const updatedProducts = [...products, ...data.data];
      setProducts(updatedProducts);

      if (data.meta.total <= updatedProducts.length) {
        setMore(false);
      }
      setPage(page + 1);
      setLoader(false);
      setInlineLoader(false);
    } catch (error) {
      console.error(error);
    } finally {
      setInlineLoader(false);
      setLoader(false);
    }
  }

  return (
    <Container>
      {!loader && (
        <div className="product-grid">
          {products
            ? products.map((product) => {
                return (
                  <ProductGridItem
                    postcode={postcode}
                    tag={tag}
                    key={product._id}
                    {...product}
                  />
                );
              })
            : null}
        </div>
      )}
      {inlineLoader && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: 30,
          }}
        >
          <div className="product-grid__loader" />
        </div>
      )}
      <div
        style={{ display: 'flex', justifyContent: 'center', marginBottom: 30 }}
      >
        {more && (
          <button
            onClick={() => getProducts()}
            className="button btn-animation"
            style={{ height: 40 }}
          >
            Load more
          </button>
        )}
      </div>
      {loader && <Loader loadingText="Connecting with all your local shops" />}
    </Container>
  );
}
