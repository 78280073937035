import React from 'react';
import { useEffect } from 'react'
import Page from '../components/page/page';
import JoinLocaloHero from '../components/join-localo-hero/join-localo-hero';
import JoinLocaloNavs from '../components/join-localo-navs/join-localo-navs';
import JoinLocaloForm from '../components/join-localo-form/join-localo-form';
import JoinLocaloWhy from '../components/join-localo-why/join-localo-why';
import JoinLocaloStartSelling from '../components/join-localo-start-selling/join-localo-start-selling';
import JoinLocaloWhatSell from '../components/join-localo-what-sell/join-localo-what-sell';
import JoinLocaloSTS from '../components/join-localo-sts/join-localo-sts';
import JoinLocaloBoostSells from '../components/join-localo-boost-sells/join-localo-boost-sells';
import JoinLocaloHelp from '../components/join-localo-help/join-localo-help';
import JoinLocaloFAQ from '../components/join-localo-faq/join-localo-faq';
import JoinLocaloContact from '../components/join-localo-contact/join-localo-contact';
import JoinLocaloNewsletter from '../components/join-localo-newsletter/join-localo-newsletter';

export default function JoinLocalo() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <Page>
            <JoinLocaloHero />
            <JoinLocaloNavs />
            <JoinLocaloForm />
            <JoinLocaloWhy />
            <JoinLocaloStartSelling />
            <JoinLocaloWhatSell />
            <JoinLocaloSTS />
            <JoinLocaloBoostSells />
            <JoinLocaloHelp />
            <JoinLocaloFAQ />
            <JoinLocaloContact />
            <JoinLocaloNewsletter />
        </Page>
    )
}
