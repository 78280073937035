import React from 'react';

import Request from '../../helpers/request';
import { useForm } from 'react-hook-form';
import './contact-form.scss';

export default function ContactForm({ submit }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const onContactUs = (form) => {
    Request(true)
      .post('/forms', {
        type: 'Contact Us',
        data: {
          ...form,
        },
      })
      .then(() => {
        reset([]);
      });
  };

  return (
    <div className="contact-form">
      <div className="left">
        <form
          className="form"
          netlify="true"
          onSubmit={handleSubmit(onContactUs)}
        >
          <div>
            <input
              type="text"
              placeholder="Your name"
              {...register('name', {
                required: true,
                maxLength: { value: 20, message: 'length must be < 20' },
              })}
            />
            {errors.name && (
              <span className="error">
                {errors.name.message || 'This field is required'}
              </span>
            )}
          </div>
          <div>
            <input
              type="email"
              placeholder="Email"
              {...register('email', {
                required: true,
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: 'Invalid email address',
                },
              })}
            />
            {errors.email && (
              <span className="error">
                {errors.email.message || 'This field is required'}
              </span>
            )}
          </div>
          <div>
            <input
              type="number"
              placeholder="Phone Number"
              {...register('phoneNumber', {
                required: true,
                minLength: {
                  value: 10,
                  message: 'Please enter a valid phone number',
                },
                maxLength: {
                  value: 12,
                  message: 'Please enter a valid phone number',
                },
                pattern: {
                  value: /[0-9]/,
                  message: 'Please enter a valid phone number',
                },
              })}
            />
            {errors.phoneNumber && (
              <span className="error">
                {errors.phoneNumber.message || 'This field is required'}
              </span>
            )}
          </div>
          <div>
            <input
              type="text"
              placeholder="Order Number"
              {...register('orderNumber', { required: true })}
            />
            {errors.orderNumber && (
              <span className="error">
                {errors.orderNumber.message || 'This field is required'}
              </span>
            )}
          </div>
          <div>
            <textarea
              rows={7}
              placeholder="Message"
              {...register('message', { required: true })}
            ></textarea>
          </div>
          <div>
            <button
              type="submit"
              className="button button-green button-green-solid"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
      <div className="right">
        <div className="social">
          <p className="purple-heading">
            You can also follow us on social media
          </p>

          <a
            className="item"
            href="https://www.facebook.com/localoltd"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="fab fa-facebook"></span>
            <p>Facebook</p>
          </a>
          <a
            className="item"
            href="https://twitter.com/shoplocalo"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="fab fa-twitter"></span>
            <p>Twitter</p>
          </a>
          <a
            className="item"
            href="https://www.instagram.com/lovelocalo/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="fab fa-instagram"></span>
            <p>Instagram</p>
          </a>
        </div>
        <div>
          <p className="purple-heading">You can write to us at</p>
          <address>
            Localo Ltd, <br />
            Atlantic Chambers, <br />
            6th Floor 45 Hope Street, <br />
            Glasgow, <br />
            G2 6AE, <br />
            United Kingdom
          </address>
        </div>
        <div>
          <p className="purple-heading">You can phone us on</p>
          <p>0141 319 4652</p>
        </div>
      </div>
    </div>
  );
}
