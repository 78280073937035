import React, { useMemo } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import SimpleSelect from "react-simple-styleable-select";

import Container from "../container/container";
import BasketFooter from "./basketFooter";
import ProductCustomisationModal from "../product-customisation-modal/product-customisation-modal";
import ProgressBar from "../../components/progress-bar/progress-bar";
import { getProduct } from "./redux/selector";

function Step2Item({ item, index, removeItem, updateItem, ...props }) {
  const [showCustomisation, setShowCustomisation] = useState(false);
  const [currentDay, setCurrentDay] = useState({});

  const product = useSelector((state) => getProduct(state, item.id));
  const deliverySlots = product[0]?.slots || [];
  const price = product[0]?.cost || 0;
  // const vendor = product[0]?.vendor || null;

  const days = useMemo(() => {
    const ret = [];
    Object.keys(deliverySlots).forEach((day) => {
      ret.push({
        id: deliverySlots[day].id,
        day: deliverySlots[day].day,
        available: deliverySlots[day].available,
        date: deliverySlots[day].date,
      });
    });
    return ret;
  }, [deliverySlots]);

  useEffect(() => {
    if (!days || !days.length) return;
    let firstDay = days.filter((a) => a.available)[0];
    setCurrentDay(firstDay);
  }, [days]);

  const remove = (e) => {
    e.preventDefault();
    removeItem(index);
  };

  return [
    <div className="item" key={`schedule-item-${index}`}>
      <div className="shop-name not-on-laptop">
        {/* {vendor ? vendor.name : null} */}
      </div>
      <div className="item-left-container">
        <div className="img-wrapper">
          {item.raw.product.images && item.raw.product.images[0] ? (
            <img alt="" src={item.raw.product.images[0].url} />
          ) : (
            ""
          )}
        </div>
        <div className="item-text">
          <div className="shop-name is-for-large">
            {/* {vendor ? vendor.name : null} */}
          </div>
          <h2 className="box-name">{item.raw.product.name}</h2>
          <div className="get-it">Get it {currentDay.day || ""}</div>
          <div className="size-and-price">
            <p>{item.raw.variant.title} box</p>
            <p>£{price.toFixed(2)} per box</p>
          </div>
          <div className="customise-box">
            <p
              style={{
                color: "var(--color-mint-green)",
                textDecoration: "underline",
                fontWeight: 600,
                marginTop: "auto",
              }}
              onClick={() => setShowCustomisation(true)}
            >
              Customise Box
            </p>
            <button onClick={(e) => remove(e)} className="remove-btn">
              <i className="far fa-trash fa-lg"></i>
            </button>
          </div>
        </div>
      </div>
      <div className="item-right-container">
        {/* <p>
          Subscription:{' '}
          {item.frequency !== 'Once' ? (
            <>
              {item.quantity} times {item.frequency}
            </>
          ) : (
            <>delivered once</>
          )}
        </p> */}
        <div className="slot-wrapper">
          {item.slots
            ? item.slots.map((slot, slotIndex) => {
                return React.Children.toArray(
                  <Step2ItemSlot
                    deliverySlots={deliverySlots}
                    item={item}
                    index={index}
                    slotIndex={slotIndex}
                    updateItem={updateItem}
                    key={`item-${index}-slot-${slotIndex}`}
                  />
                );
              })
            : ""}
        </div>
      </div>
    </div>,
    <ProductCustomisationModal
      showModal={showCustomisation}
      key={`schedule-modal-${index}`}
      toggleModal={setShowCustomisation}
      customisation={item.customisations}
      variant={item.raw.variant}
      setCustomisation={(c) =>
        updateItem(index, { ...item, customisations: c })
      }
    />,
  ];
}

function Step2ItemSlot({ item, index, slotIndex, slot, updateItem, ...props }) {
  const change = React.useCallback(
    (ev) => {
      let temp = item;
      temp.slots[slotIndex] = {
        ...temp.slots[slotIndex],
        [ev.target.name]: ev.target.value,
      };

      if (ev.target.name === "day") {
        temp.slots[slotIndex] = {
          ...temp.slots[slotIndex],
          slot: null,
        };
      }

      // console.log(temp);

      updateItem(index, temp);
    },
    [index, item, slotIndex, updateItem]
  );

  const [currentDay, setCurrentDay] = useState(item.slots[slotIndex].day);

  const days = useMemo(() => {
    const ret = [];
    Object.keys(props.deliverySlots).forEach((day) => {
      ret.push({
        id: props.deliverySlots[day].id,
        day: props.deliverySlots[day].day,
        date: props.deliverySlots[day].date,
        available: props.deliverySlots[day].available,
      });
    });
    // console.log('ret', ret);
    return ret;
  }, [props.deliverySlots]);

  const times = useMemo(() => {
    // console.log(props.deliverySlots, currentDay);
    if (!props.deliverySlots || !props.deliverySlots.Monday) return [];

    let key = Object.keys(props.deliverySlots).filter(
      (a) => props.deliverySlots[a].id === currentDay
    );
    if (key.length === 0) return [];
    if (key.length > 1) return [];

    key = key[0];

    return props.deliverySlots[key].slots;
  }, [props.deliverySlots, currentDay]);

  useEffect(() => {
    if (!days || !days.length) return;
    if (item.slots && item.slots.length && item.slots[slotIndex]) {
      // console.log('Has item');
      let firstDay = days.filter((a) => a.available)[0];
      // console.log('First day', firstDay);
      if (!item.slots[slotIndex].day) {
        change({ target: { name: "day", value: firstDay.id } });
        setCurrentDay(firstDay.id);
      }
    }
  }, [item.slots, change, days, slotIndex, times]);

  useEffect(() => {
    // console.log('current time', item.slots[slotIndex].slot);
    // console.log(times);
    if (
      !times ||
      !times.length ||
      !times.filter((a) => a.available).length ||
      !times[0]
    )
      return;
    let firstTime = times[0].id;
    // console.log(
    //   'First time',
    //   firstTime,
    //   item.slots[slotIndex].slot,
    //   !item.slots[slotIndex].slot
    // );
    if (!item.slots[slotIndex].slot)
      change({ target: { name: "slot", value: firstTime } });
  }, [currentDay, times, item.slots, slotIndex, change]);

  useEffect(() => {
    // console.log(item.slots[slotIndex]);
  }, [item.slots, slotIndex]);

  let slotOptions = days
    .filter((a) => a.available)
    .map((a) => {
      return {
        value: a.id,
        label: a.date,
        disabled: !a.available,
      };
    });

  let timeOptions = times
    .filter((a) => a.available)
    .map((a) => {
      return {
        value: a.id,
        label: a.timeFrame,
        disabled: !a.available,
      };
    });

  return (
    <div className="slot">
      {item.frequency !== "Once" && item.slots.length > 1 ? (
        <div className="delivery-note">
          <span>
            {item.frequency === "Weekly" ? "Week's" : ""}{" "}
            {slotIndex === 0 && "first "} {slotIndex === 1 && "second "}{" "}
            {slotIndex === 2 && "third "} delivery
          </span>
        </div>
      ) : (
        ""
      )}
      <div className="select__container">
        {/* {item.frequency === 'Monthly' ? (
          <select
            name='week'
            className='week'
            onChange={change}
            value={item.slots[slotIndex].week}
          >
            <option value={1}>Once per Month</option>
            <option value={2}>Every Two Weeks</option>
            <option value={3}>Three times per Month</option>
            <option value={4}>Once per Week</option>
          </select>
        ) : (
          ''
        )} */}

        {/* <select
          name='day'
          onChange={(e) => {
            change(e);
            setCurrentDay(e.target.value);
          }}
          value={item.slots[slotIndex].day}
        >
          {days
            .filter((a) => a.available)
            .map((a) => {
              return (
                <option value={a.id} disabled={!a.available}>
                  {a.date}
                </option>
              );
            })}
        </select> */}
        {slotOptions.length > 0 && item.slots[slotIndex].day && (
          <SimpleSelect
            name="day"
            onChange={(ev, val) => {
              // change(ev);
              change({ target: { name: "day", value: val } });
              setCurrentDay(val);
            }}
            defaultValue={item.slots[slotIndex].day}
            value=""
            options={slotOptions}
            hasSharpCorners
            fixedHeight
            style={{ color: "black" }}
          />
        )}
      </div>
      <div className="select__container">
        {timeOptions.length > 0 && item.slots[slotIndex].slot && (
          <SimpleSelect
            name="slot"
            onChange={(ev, val) => {
              // change(ev);
              change({ target: { name: "slot", value: val } });
            }}
            defaultValue={item.slots[slotIndex].slot}
            value=""
            options={timeOptions}
            hasSharpCorners
            fixedHeight
            style={{ color: "black" }}
          />
        )}
        {/* <select
          name='slot'
          onChange={change}
          value={item.slots[slotIndex].slot}
        >
          {times
            .filter((a) => a.available)
            .map((a) => {
              return (
                <option value={a.id} disabled={!a.available}>
                  {a.timeFrame}
                </option>
              );
            })}
        </select> */}
        {/* <p style={{opacity: 0.5}}>
          Your {item.frequency === 'Once' ? 'first' : null } box will be delivered on <strong>{days.find(_ => _.id === currentDay) ? days.find(_ => _.id === currentDay).date : null}</strong>&nbsp;
          { item.frequency !== 'Once' ? <span>and {item.frequency} on a <strong>{days.find(_ => _.id === currentDay) ? days.find(_ => _.id === currentDay).day : null}
          </strong> between <strong>{times.find(_ => _.id === item.slots[slotIndex].slot) ? times.find(_ => _.id === item.slots[slotIndex].slot).timeFrame : null}</strong> after that</span> : null}
        </p> */}
      </div>
      <p className="will-deliver">
        Your {/* {item.frequency === 'Once' ? 'first' : null }  */}
        {item.quantity === 1 ? (
          "box"
        ) : item.quantity > 1 && item.frequency === "Weekly" ? (
          <>
            {slotIndex === 0 && "first box "} {slotIndex === 1 && "second box "}{" "}
            {slotIndex === 2 && "third box "}
          </>
        ) : (
          "boxs"
        )}{" "}
        will be delivered on{" "}
        <strong>
          {days.find((_) => _.id === currentDay)
            ? days.find((_) => _.id === currentDay).day
            : null}
        </strong>
        &nbsp;
        {item.frequency !== "Once" && item.frequency !== "Daily" ? (
          <span>
            <strong></strong> between{" "}
            <strong>
              {times.find((_) => _.id === item.slots[slotIndex].slot)
                ? times.find((_) => _.id === item.slots[slotIndex].slot)
                    .timeFrame
                : null}
            </strong>{" "}
            and{" "}
            {item.frequency === "Fortnightly"
              ? "fortnightly on the same day/time slot"
              : ""}{" "}
            {item.frequency === "Weekly"
              ? "weekly on the same day/time slot"
              : ""}{" "}
            {item.frequency === "Monthly"
              ? "monthly on the same day/time slot"
              : ""}{" "}
            after that
          </span>
        ) : (
          <>
            <strong>
              between{" "}
              {times.find((_) => _.id === item.slots[slotIndex].slot)
                ? times.find((_) => _.id === item.slots[slotIndex].slot)
                    .timeFrame
                : null}
            </strong>
            {item.frequency === "Daily" ? " and daily after that" : ""}
          </>
        )}
      </p>
    </div>
  );
}

export default function BasketStep2({
  back,
  cart,
  next,
  updateItem,
  removeItem,
  ...props
}) {
  const { t } = useTranslation("common");

  return (
    <Container>
      <div className="basket">
        <div className="basket-header">
          {cart && cart.length ? <ProgressBar currentStep={2} /> : ""}
          <h1 className="title-back-button">
            <button onClick={back} className="back">
              {t("basket.back")}
            </button>
            <span>{t("basket.checkout-choose-how-often")}</span>
          </h1>
        </div>
        <div className="items">
          {cart.map((item, index) => {
            return React.Children.toArray(
              <Step2Item
                removeItem={removeItem}
                item={item}
                index={index}
                key={`schedule-item-${index}`}
                updateItem={updateItem}
              />
            );
          })}
        </div>
      </div>
      <BasketFooter next={next} back={back} />
    </Container>
  );
}
