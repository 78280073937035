import React from 'react';
import { useTranslation } from 'react-i18next';
import LocaloVan from '../../assets/localo-van-big.png';
import './join-local-marketplace.scss';

export default function JoinLocalMarketplace() {
    const { t } = useTranslation('common');

    return (
        <div className="join-local-marketplace">
            <div className="join-local-marketplace__heading">
                <h2 className="join-local-marketplace__headingTitle">{t('jlm.title')}</h2>
                <h3 className="join-local-marketplace__headingDesc">{t('jlm.text')}</h3>
            </div>
            <img src={LocaloVan} alt={t('jlm.title')} />
            <div className="join-local-marketplace__item">
                <div className="title">{t('jlm.support-local.title')}</div>
                <div className="desc">{t('jlm.support-local.text')}</div>
            </div>
            <div className="join-local-marketplace__item">
                <div className="title">{t('jlm.save-time.title')}</div>
                <div className="desc">{t('jlm.save-time.text')}</div>
            </div>
            <div className="join-local-marketplace__item">
                <div className="title">{t('jlm.sort-your-co.title')}<sub>2</sub></div>
                <div className="desc">{t('jlm.sort-your-co.text')}</div>
            </div>
        </div>
    );
}