import React from 'react';
import Container from '../container/container';
import SwiperCarousel from '../swiper-carousel/swiper-carousel';

import './product-navigator.scss';

export default function ProductNavigator({
  categories,
  onSelectCategory,
  selectedCategory,
}) {
  return (
    <div className="product-navigator">
      <Container>
        <SwiperCarousel
          className="product-navigator__carousel"
          slideShowDesktop={categories.length}
        >
          {categories.map((category) => {
            const { name, images, _id: id, icon, withIcon } = category;

            return React.Children.toArray(
              <div
                onClick={() => onSelectCategory(id, name)}
                className={`product-navigator__item ${
                  id === selectedCategory ? 'isSelected' : ''
                }`}
              >
                <div className="product-navigator__img">
                  {withIcon ? icon : <img src={images[0].url} alt="Backery" />}
                </div>
                <div className="product-navigator__title">{name}</div>
              </div>
            );
          })}
        </SwiperCarousel>
      </Container>
    </div>
  );
}
