import React from 'react';
import ContainerComponent from '../container/container';
import Vegetable from '../../assets/join-localo/vegetable.svg';
import Subscription from '../../assets/join-localo/subscription.svg';
import DeliveryVan from '../../assets/join-localo/delivery-van.svg';
import './join-localo-help.scss';

export default function JoinLocaloHelp() {
    return (
        <div className='join-localo-help' id='how-we-help'>
            <ContainerComponent>
                <div className='join-localo-help__header'>
                    <h2 className='join-localo-help__header__title'>Help when you need it</h2>
                    <div className='join-localo-help__header__desc'>We’re here to help you thrive, with all the support and advice you need. Not sure it’s for you?</div>
                </div>
                <div className='join-localo-help__list'>
                    <div className='join-localo-help__item'>
                        <div className='join-localo-help__icon'>
                            <img src={Vegetable} alt='Talk to us' />
                        </div>
                        <h3 className='join-localo-help__title'>Talk to us</h3>
                        <p className='join-localo-help__desc'>
                            You can reach our support staff by email or ask us to call you to discuss your questions. We’re betting we can make it work for you.
                    </p>
                    </div>
                    <div className='join-localo-help__item'>
                        <div className='join-localo-help__icon'>
                            <img src={Subscription} alt='Tips for success' />
                        </div>
                        <h3 className='join-localo-help__title'>Tips for success</h3>
                        <p className='join-localo-help__desc'>
                            There’s always something new to learn – we’ll help you be more competitive with best practices for your business in our always up-to-date Seller Handbook.
                    </p>
                    </div>
                    <div className='join-localo-help__item'>
                        <div className='join-localo-help__icon'>
                            <img src={DeliveryVan} alt='Keeping you posted' />
                        </div>
                        <h3 className='join-localo-help__title'>Keeping you posted</h3>
                        <p className='join-localo-help__desc'>
                            The Localo Success newsletter gives you tips on improving your shop, layout, payment methods, display, stocktaking — everything you need to know. And we’ll send it all straight to your inbox.
                    </p>
                    </div>
                </div>
            </ContainerComponent>
        </div>
    )
}