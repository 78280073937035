import React from "react";

import "./product-card.scss";

export default function ProductCard({ img, title, subText }) {
  return (
    <div className="product-card">
      {/* <div className="product-card__badges">
        <div className="product-card__badge product-card__badge--vg">
          Vg
        </div>
        <div className="product-card__badge product-card__badge--v">
          V
        </div>
        <div className="product-card__badge product-card__badge--gf">
          GF
        </div>
      </div>
      <div className="product-card__offer">
        %
      </div> */}
      <div className="product-card__img">
        <img src={img} alt={title} />
      </div>
      <div className="product-card__content">
        <div className="product-card__title">{title}</div>
        <div className="product-card__subText">{subText}</div>
      </div>
    </div>
  );
}
