import React from 'react'
import ContactForm from '../components/contact-form/contact-form'
import Container from '../components/container/container'
import Footer from '../components/footer/footer'
import Header from '../components/header/header'
import TitleCard from '../components/title-card/TitleCard'
import MetaDecorator from '../hooks/metaDecorator';

export default function Contact() {
    return (
        <div>
            <MetaDecorator
                title="Localo | Get in touch with us"
                description="Talk to us about making an order, receiving a delivery or more ways we can help you."
            />
            <Header />
            <TitleCard overlay="raspberry-sorbet">
                <h1>Contact Us</h1>
            </TitleCard>
            <Container>
                <ContactForm />
            </Container>
            <TitleCard className="contact-title" overlay="green-blue" invert={true}>
                <h2>Need Help?</h2>
                <h3>Checkout our FAQs section</h3>
                <p>You can phone, write, email or chat online <br /> with our customer support team</p>
            </TitleCard>
            <Footer />
        </div>
    )
}
