import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { ReactComponent as FlatLogo } from "../../assets/logo-flat.svg";
import Container from "../container/container";
import "./footer.scss";

export default function Footer({ isBasket }) {
  const { t } = useTranslation("common");
  const history = useHistory()
  return (
    <footer className={isBasket ? 'footer--inBasket' : ''}>
      <Container>
        <div className="footer-layout">
          <div onClick={() => history.push('/')} className="footer-logo">
            <FlatLogo />
            <p>{t("brand.tagline")}</p>
          </div>
          <div className="upper-row">

            <div className="links">
              <ul className="social">
                <li>
                  <a href='https://www.facebook.com/localoltd' target='_blank' rel='noopener noreferrer'>
                    <i className="fab fa-facebook-f"></i>
                  </a>
                </li>
                <li>
                  <a href='https://www.instagram.com/lovelocalo/' target='_blank' rel='noopener noreferrer'>
                    <i className="fab fa-instagram"></i>
                  </a>
                </li>
                <li>
                  <a href='https://twitter.com/shoplocalo' target='_blank' rel='noopener noreferrer'>
                    <i className="fab fa-twitter"></i>
                  </a>
                </li>
              </ul>
            </div>
            <div className="links">
              <div className="cols">
                <ul className="primary">
                  <li>
                    <Link to="/about">{t("footer.links.about")}</Link>
                  </li>
                  <li>
                    <Link to="/contact">{t("footer.links.contact")}</Link>
                  </li>
                  <li>
                    <Link to="/join-localo">{t("footer.links.join")}</Link>
                  </li>
                  <li>
                    <Link to="/about">{t("footer.links.faqs")}</Link>
                  </li>
                  <li>
                    <Link to="/sitemap">Sitemap</Link>
                  </li>
                </ul>
                <ul className="secondary">
                  <li>
                    <Link to="/legal/environmental-policy">{t("footer.links.env-policy")}</Link>
                  </li>
                  <li>
                    <Link to="/legal/food-hygiene-and-safety-policy">{t("footer.links.food-safety-policy")}</Link>
                  </li>
                  <li>
                    <Link to="/legal/terms-and-conditions-for-sellers">{t("footer.links.ts-seller")}</Link>
                  </li>
                  <li>
                    <Link to="/legal/terms-of-service-for-customers">{t("footer.links.ts-customers")}</Link>
                  </li>
                  <li>
                    <Link to="/legal/website-and-app-terms-of-Service">{t("footer.links.localo-webapp-ts")}</Link>
                  </li>
                  <li>
                    <Link to="/legal/privacy">{t("footer.links.privacy")}</Link>
                  </li>
                  <li>
                    <Link to="/legal/cookies-policy">{t("footer.links.cookies-policy")}</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="lower-row">
            <p
              className="copyright"
              dangerouslySetInnerHTML={{ __html: t("footer.copyright") }}
            />
          </div>
          <div className='dev-by-row'>
            Website development by <a href='https://www.skeinagency.com/' rel='noopener noreferrer' target='_blank'>Skein Agency</a>
          </div>
        </div>
      </Container>
    </footer>
  );
}
