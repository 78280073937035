import React from 'react'
import { Link } from 'react-router-dom';
import ProductCard from '../product-card/product-card';

import './product-grid-item.scss';

export default function ProductGridItem({_id: id, vendor, name, images, postcode, tag, ...product}) {
    let vendorId = "vendor", vendorName = "";
    if(vendor !== null){
        vendorId = vendor._id;
        vendorName = vendor.name;
        // vendorAddress = vendor.address !== null ? vendor.address.locality : "";
    }
    
    return (
        <Link to={postcode ? `/products/${postcode}/products/${vendorId}/${id}` : `/tag/${tag}/products/${vendorId}/${id}` }>
            <ProductCard img={images && images.length ? images[0].url : ''} title={name} subText={vendorName} />
        </Link>
    )
}
