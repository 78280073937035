import React, { useState, useEffect } from 'react';
import Page from '../components/page/page';
import HeaderImage from '../components/header-image/image';
import HighstreetSelect from '../components/highstreet-select/highstreet';
// import AppBanner from '../components/app-banner/banner';
import VendorOfTheWeek from '../components/vendor-of-the-week/votw';
import HowLocaloWorks from '../components/how-localo-works/how';
// import WhatIsLocalo from '../components/what-is-localo/what';
// import Boxes from '../components/some-of-our-boxes/boxes';
// import HereAreSomeIdeas from '../components/here-are-some-ideas/ideas';
import MetaDecorator from '../hooks/metaDecorator';
import FreeTreatModal from '../components/free-treat-modal/free-treat-modal';
// import FirstOrder from '../components/first-order/first-order';
import JoinLocalMarketplace from '../components/join-local-marketplace/join-local-marketplace';
import FindLocalShops from '../components/find-local-shops/find-local-shops';

const FREE_TREAT_MODAL = 'ftm_showed';
const SECOND = 1000; // second

export default function Home() {
  const [freeTreatModal, setFreeTreatModal] = useState(false);

  useEffect(() => {
    const freeTreatModal = JSON.parse(
      window.localStorage.getItem(FREE_TREAT_MODAL)
    );
    if (!freeTreatModal?.isShownFTM) {
      setTimeout(() => {
        setFreeTreatModal(true);
        window.localStorage.setItem(
          FREE_TREAT_MODAL,
          JSON.stringify({ time: new Date(), isShownFTM: true })
        );
      }, 20 * SECOND);
    }
  }, []);

  const toggleFreeTreatModal = () => {
    setFreeTreatModal(!freeTreatModal);
  };

  return (
    <>
      <MetaDecorator
        title="Localo | Foodboxes delivered quickly from your local shops"
        description="Localo is a marketplace for your high street and your local independent shops - buy
            foodboxes on the app and they’re delivered to your door."
      />
      <Page>
        <div className="home">
          <HeaderImage />
          <HighstreetSelect />
          {/* <HereAreSomeIdeas /> */}
          {/* <Boxes /> */}
          {/* <WhatIsLocalo /> */}
          <JoinLocalMarketplace />
          <FindLocalShops />
          <HowLocaloWorks />
          <VendorOfTheWeek />
          {/* <AppBanner/> */}
          {/* <FirstOrder /> */}
          <FreeTreatModal
            showModal={freeTreatModal}
            toggleModal={toggleFreeTreatModal}
          />
        </div>
      </Page>
    </>
  );
}
