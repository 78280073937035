import React, { useState } from 'react';
import ReactModal from 'react-modal';
import Request from '../../helpers/request';

import useViewport from '../../hooks/useViewport';

export default function EmptyProductModal({
  showModal = false,
  toggleModal = () => {},
}) {
  const { width } = useViewport();
  const [email, setEmail] = useState('');
  const [height, setHeight] = useState(0);
  const [thankMessage, setThanksMessage] = useState(false);

  const onSubmitEmailAddress = () => {
    if (!email) return;
    Request(false)
      .post('/forms', {
        type: 'no-service',
        data: {
          email: email,
        },
      })
      .then(() => {
        setEmail('');
        setThanksMessage(true);
        setHeight(50);
        setTimeout(() => {
          onClose();
        }, 2000);
      });
  };

  const onClose = () => {
    setThanksMessage(false);
    setEmail('');
    setHeight(0);
    toggleModal(false);
  };

  return (
    <ReactModal
      isOpen={showModal}
      style={{
        content: {
          width: width < 700 ? '95%' : '500px',
          top: '50%',
          left: '50%',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          padding: '0px',
          height:
            width < 700
              ? `calc(306px + ${height}px)`
              : `calc(324px + ${height}px)`,
        },
      }}
      onRequestClose={onClose}
    >
      <div className="l-modal">
        <div className="l-modal__title">Sorry!</div>
        <p>We’re working on expanding the service to your area.</p>
        <p>
          Enter your email address to be notified when you can order from your
          local shops.
        </p>
        <input
          placeholder="Email address"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="l-modal__input"
        />
        <div className="l-modal__btns">
          <button onClick={onClose} className="btn-no-thanks" type="button">
            No Thanks
          </button>
          <button
            onClick={onSubmitEmailAddress}
            className="btn-register"
            type="button"
          >
            Register
          </button>
        </div>
        {thankMessage && <div className="l-modal__thankyou">Thank you!</div>}
      </div>
    </ReactModal>
  );
}
