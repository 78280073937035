import React, { useState } from 'react'
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js'
import request from '../../helpers/request'
import { store } from 'react-notifications-component';

import './add-card-to-profile.scss'
import { forwardRef } from 'react';
import { useDispatch } from 'react-redux';
import { setIsLoading } from '../basket/redux/action';
// import SimpleSelect from 'react-simple-styleable-select';
// import { useMemo } from 'react';

const AddCardToProfile = forwardRef((props, ref) => {
    const dispatch = useDispatch()
    const showButton = props.showButton
    const onChange = props.onChange
    const elements = useElements();
    const stripe = useStripe();

    const [selected, setSelected] = useState(false)

    // const [cards, setCards] = useState([])

    const [inProgress, setInProgress] = useState(false)
    const [success, setSuccess] = useState(false)

    // const getCards = () => {
    //     // console.log(cards)
    //     request(true).get('/self/cards').then(e => {
    //         setCards(e.data)
    //     })
    // }

    // useEffect(() => {
    //     getCards()
    // }, [inProgress, getCards])

    // useEffect(() => {
    //     getCards()
    // }, [])

    const addCardToAccount = async () => {
        // props.setIsLoading(true)
        const card = elements.getElement(CardNumberElement)

        if (!stripe || !elements) {
            // console.log('Stripe has not loaded.')
            return
        }

        setInProgress(true)

        request(true).get('/self/cards/setup').then(setupIntent => {

            // console.log(card)

            stripe.confirmCardSetup(setupIntent.data.client_secret, {
                payment_method: {
                    card
                }
            }).then(e => {
                // console.log('PAYMENT METHOD', e)
                if (e.error) {
                    setInProgress(false)
                    dispatch(setIsLoading(false))
                    store.addNotification({
                        title: "Error",
                        message: e.error.message,
                        type: "error",
                        insert: "bottom",
                        container: "bottom-center",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 5000,
                            onScreen: true
                        }
                    });
                } else {
                    setInProgress(false)
                    setSuccess(true)
                    setSelected(true)
                    onChange(e.setupIntent.payment_method)
                    setTimeout(() => {
                        setSuccess(false)
                        setSelected(false)
                    }, 1500);
                }
            }).catch((e) => {
                setInProgress(false)
                dispatch(setIsLoading(false))
                // console.log(e)
            })

        }).catch(() => {
            setInProgress(false)
            dispatch(setIsLoading(false))
        })
        // props.setIsLoading(false)
    }



    return (
        <div className="add-card-to-profile">
            {/* <div className="left">
                { cards && cards.length ? <>
                    <p>Use an existing card</p>
                    <select onChange={e => onChange(e.target.value)} defaultValue="disabled">
                        <option disabled={true} value="disabled">Select a card</option>
                        {
                            cards.map(card => {
                                return (<option value={card.id}>**** **** **** {card.card.last4} ({card.card.brand})</option>)
                            })
                        }
                    </select>
                </> : '' }
            </div> */}

            <div className="right">
                {/* <p>Add a new card</p> */}
                <div className="add-a-card">
                    <div>
                        <p>Card Number</p>
                        <CardNumberElement />
                    </div>
                    <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '1em' }}>
                        <div>
                            <p>Expiration</p>
                            <CardExpiryElement />
                        </div>
                        <div>
                            <p>CVC</p>
                            <CardCvcElement />
                        </div>
                    </div>
                    {/* <CardElement /> */}
                    
                    <button style={{display: showButton ? undefined : 'none'}} ref={ref} className={`button ${success ? 'button-green-solid' : ''}`} onClick={() => addCardToAccount()} disabled={inProgress || selected}>{inProgress ? 'Adding...' : success ? 'Ready to go!' : 'Use this card'}</button>

                </div>
            </div>
        </div>
    )
})

export default AddCardToProfile
