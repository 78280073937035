import React, {useEffect} from 'react';

function useOutsideCatch(ref, active, cb) {
    React.useEffect(() => {
        function handleOutsideClick(ev) {
            if (active && ref.current && !ref.current.contains(ev.target)) {
                cb();
            }
        }

        document.addEventListener("mousedown", handleOutsideClick);

        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        }
    })
}

export default function Dropdown ({ dropdown, children, ...props })
{
    const [isOpen, setIsOpen] = React.useState(false);

    const ref = React.useRef(null);

    useOutsideCatch(ref, isOpen, () => { setIsOpen(false) });
    
    useEffect(() => {
        document.body.classList.toggle('modal-open', isOpen);
      }, [isOpen]);

    return (
        <div className="dropdown" ref={ ref }>
            <div { ...props } onClick={ () => setIsOpen(!isOpen) }>
                { children }
            </div>
            <div className={`dropdown-content ${isOpen ? 'show' : ''}`}>
                <button onClick={ () => setIsOpen(!isOpen) }>
                    <span className="close-menu"></span>
                </button>
                { dropdown }
            </div>
        </div>
    )
}
