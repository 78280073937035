import React from 'react';
import { Link, withRouter, useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import './sticky-view-basket.scss';

function StickyViewBasket(props) {
  const { t } = useTranslation('common');
  const History = useHistory();
  const params = useParams();
  const { location } = props;

  const goBack = () => {
    const { highstreet, productId } = params;
     if(highstreet && productId)
      History.go(-2);
    else
      History.goBack();
  }
  
  return (
    <div className="not-on-laptop">
        <div className='sticky-step-1-bar'>
          {location.pathname !== "/" ? (
            <div className='back' onClick={goBack}>
                {t('basket.back')}
            </div>
            ) : <div/>}
            <Link to='/basket?step=1'>
                <button className={`button button-raspberry button-view-basket`}>
                <span className="fa-layers fa-fw">
                    <i className="far fa-shopping-cart"></i>
                </span>
                {t('basket.view-basket')}
                </button>
            </Link>
        </div>
    </div>
  );
}

export default withRouter(StickyViewBasket);