import React, { useEffect, useState } from 'react'
import Container from '../container/container'
import ShopGridItem from '../shop-grid-item/shop-grid-item'
import request from '../../helpers/request'

import './shop-grid.scss'
import BidGrid from '../bid-grid/bid-grid'

export default function ShopGrid({postcode}) {

    const [vendors, setVendors] = useState([])

    const [inlineLoader, setInlineLoader] = useState(false);

    const [page, setPage] = useState(1);

    const [more, setMore] = useState(true);

    useEffect(() => {
        getVendors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [postcode])

    async function getVendors () {
        const vendorLists = (await request(false).get(`/vendors?limit=20&page=${page}${postcode ? '&postcode='+postcode : ''}`)).data
        const updatedVendors = [...vendors, ...vendorLists.data]
        setVendors(updatedVendors);

        // to check all data are fetched or not
        if(vendorLists.meta.total <= updatedVendors.length){
            setMore(false)
        }
        // set page to fetch next
        setPage(page + 1);
        setInlineLoader(false)
    }


    return (
        <Container>
            <div className="shop-grid">
                {
                    vendors ? vendors.map(vendor => {
                        return React.Children.toArray(<ShopGridItem postcode={postcode} key={vendor._id} {...vendor} />)
                    }) : null
                }
            </div>
            {/* If there are bids */}
            {!!vendors?.length && inlineLoader && <div style={{display: 'flex', justifyContent:'center', marginBottom: 30}}>
				 <div className="product-grid__loader"/>
			</div>}
			<div style={{display: 'flex', justifyContent:'center', marginBottom: 30}}>
			{!!vendors?.length && more && <button onClick={getVendors} style={{ height: 40 }} className="button btn-animation">Load more</button>}
			</div>

            <BidGrid postcode={postcode} />
            
        </Container>
    )
}
