import React from 'react'
import Page from '../../components/page/page';
import LegalPage from '../../components/legal-page/legal-page';
import TitleCard from '../../components/title-card/TitleCard';
import cardBackground from '../../assets/privacy-policy-banner/bg-banner.png';
import MetaDecorator from '../../hooks/metaDecorator';

const subtext = [
    'This page (together with the documents referred to on it) tells you the terms of use on which you may make use of our website www.shoplocalo.com (our "Site") or any application we make available via an app store or otherwise (our "Service"), whether as a guest or a registered user. Before you start using the Website or our Service, please read these terms of use carefully. By accessing our Site or by using our Service, you indicate that you accept these terms of use and that you agree to abide by them. If you do not agree to these terms of use, do not use access our Site or use our Service.'
];

const headers = [
    '1. Information About Us',
    '2. Accessing Our Service Or Our Services',
    '3. Acceptable Use',
    '4. Interactive Features Of Our Site',
    '5. Content Standards',
    '6. Suspension And Termination',
    '7. Intellectual Property Rights',
    '8. Reliance On Information Posted',
    '9. Our Site And Our Service Change Regularly',
    '10. Our Liability',
    '11. Details About You and Your Access to Our Site and Use of Our Services',
    '12. Uploading Information to Our Website and to our service',
    '13. Links From Our Site',
    '14. Jurisdiction And Applicable Law',
    '15. Variations',
    '16. Your Concerns'
];

const markdown = [
    `# 1. Information About Us

www.shoplocalo.com is a website operated by Localo Ltd ("we", "us" or "Localo"), incorporated and registered in Scotland, whose registered office is at Atlantic Chambers, 6th floor 45 Hope Street, Glasgow G2 6AE, United Kingdom. Our Company registration number is SC669324. Localo is a business where grocery and product Boxes are prepared by independent Sellers (our "Partner Sellers") and delivered by us.
`,

    `# 2. Accessing Our Service Or Our Services

Access to our Site and Service is allowed on a temporary basis and we reserve the right to revoke or change access to our Site or Service without notice (see below). We shall not be liable if our Site or our Service is not available for any reason at any time or for any period of time. From time to time, we may restrict access to some parts of our Site or our Service, or our entire Site or Service to users who have registered with us. You are responsible for maintaining the confidentiality of your login details and any activities that occur under your account. If you have any concerns about your login details or think they have been misused, you should contact team@shoplocalo.com straight away to let us know. We can deactivate your account at any time.
`,

    ` # 3. Acceptable Use

You can use our Service for legal purposes only. You may not use our Site or our Service in any way that breaches any applicable local, national or international law or regulation or to send, knowingly receive, upload, download, use or re-use any material which does not comply with our content standards in clause 5 below.  You also agree not to access, intervene, destroy or interrupt any part of our Site or our Service or any network or equipment used in the provision of our Service without any authority.
`,

    ` # 4. Interactive Features Of Our Site

From time to time, we can provide you with certain features that allow you to connect with our Site or Service, such as chat rooms. Generally, we do not moderate any interactive service we provide although we may remove contents in contravention of these Terms of Use as set out in section 6. If we plan to facilitate an interactive service, we will make this clear before you use the service and will usually provide you with a means of contacting the moderator if there is any question or difficulty. 
`,

    ` # 5. Content Standards

These content standards apply to any and all material which you contribute to our Service (the "Contributions"), and to any interactive services associated with it. You must abide by the spirit of the following requirements and the text. The criteria shall extend to each part of any contribution as well as to the whole of that contribution. Contributions must be correct (where they state the facts), truthful (where they state opinions) and comply with applicable law in the United Kingdom and in any country from which they are written. Contributions shall not: 

- Include any material that is defamatory of any individual, pornographic, insulting, abusive or inflammatory, promote sexually explicit material or promote violence or promote discrimination on grounds of race, sex, religion, nationality, disability, sexual orientation or age;
- Infringes any other person's copyright, software right or trademark;
- Are likely to mislead or be rendered in contravention of an individual
- Be likely to deceive or infringe any legitimate duty owed to a third party, such as a contractual duty or an obligation of trust, or encourage any criminal activity;
- Are threatening, abusing or violating the privacy of others, or because irritation, inconvenience or excessive distress or are likely to interrupt, offend, embarrass, alarm or disturb anyone else;
- Use it to impersonate another person, or to distort your identity or association with any individual, or to give the impression that they emanate from us if that is not the case;
- To support, encourage or assist any unlawful act, such as (for example) infringement of copyright or abuse of computers.

`,

    ` # 6. Suspension And Termination

No compliance with Section 3 (Acceptable Use) and/or Section 5 (Content Standards) in these Terms of Use constitute a serious violation of the Terms of Use and can result in any or all of the following acts being taken:

- Termination of your right to use our Service instantly, temporarily or permanently;
- Immediate, temporary or permanent deletion of any posting or content that you upload to our Service;
- Warning issued to you;
- Civil action against you, including litigation seeking reimbursement of all expenses (including, but not limited to, fair administrative and legal expenses) arising out of the infringement;
- The submission to law enforcement officials of such information as we fairly believe is appropriate.

The responses mentioned in this clause are not restricted and we can take any further action that we reasonably consider necessary.
`,

    ` # 7. Intellectual Property Rights

We are the owner or holder of all intellectual property rights in our Platform and our Operation, and in the material published thereon (excluding your contributions). Those works are protected by copyright laws and treaties around the world. All such rights are reserved. You may not, except for your own personal, non-commercial use, copy, print, republish, stream, distribute, broadcast, transmit, make available to the public or otherwise use any of the material on our web.
`,

    ` # 8. Reliance On Information Posted

Comments and other materials shared on our Website are not meant to amount to recommendations on which to rely. Therefore, we disclaim all responsibility and liability resulting from any dependence imposed on such materials by any visitor to our Service, or by anyone who may be aware of any of its contents.
`,

    ` # 9. Our Site And Our Service Change Regularly

We aim to update our Site and our Service regularly and may change the content at any time. If required, we can suspend or indefinitely close your access to our Site and our Service. Any of the material on our Site or our Service may be out of date at any given time, and we are under no obligation to update such material.
`,

    ` # 10. Our Liability

We have taken great care in the planning of our Site and our Service. However, we will not be liable for any mistakes or omissions relating to such material or for any technical difficulties that may occur with our Site or our Service. If we are told of any inaccuracies on our Site or in our Service we will attempt to rectify this as soon as reasonably possible. To the degree allowed by law, we preclude any liability (whether arising out of a contract, in negligence or otherwise) for any loss or harm that you or any third party may incur in connexion with our Site, our Service, and any website connected to our Site and any materials posted thereon. This does not affect our responsibility for death or personal injury as a result of our negligence, or our responsibility for fraudulent misappropriation or misappropriation as a fundamental matter, or any other liability which cannot be excluded or restricted under applicable law.
`,

    ` # 11. Details About You and Your Access to Our Site and Use of Our Services

As a result of your use of our Service, we collect some data about you. This is described in more detail in our privacy policy.
`,

    ` # 12. Uploading Information to Our Website and to our service

Any information you submit to our Service or data we collect as outlined above (section 11) shall be deemed non-confidential and non-proprietary; And you accept and consent that we have the right to use, edit, transmit, sell and reveal any such material or data to third parties for any reason relevant to our business. In so far as such content is protected by intellectual property rights, you grant us a perpetual, worldwide, royalty-free licence to use, copy, alter, distribute, sell and disclose any such mate to third parties
`,

    ` # 13. Links From Our Site

If our Site includes links to other sites and services provided by third parties, these links are provided for your information only. We have no influence over the contents of these pages or services and take no responsibility for them or for any loss or harm that may result from your use of them.
`,

    ` # 14. Jurisdiction And Applicable Law

The Scottish courts shall have jurisdiction over any claims arising out of or relating to a visit to our Site or the use of our Services. These terms of use and any dispute or claim arising out of or in connexion with them or their subject-matter or creation (including non-contractual disputes or claims) shall be regulated by and interpreted in accordance with the law of Scotland.
`,

    ` # 15. Variations

We can amend these Terms of Use at any time by changing this page. You are supposed to review this page from time to time to note any improvements we make, as they are binding on you.
`,

    ` # 16. Your Concerns

If you have any concerns about material which appears on our Service, please contact team@shoplocalo.com
`
];

export default function WebsiteAppTermsOfService() {
    return (
        <Page>
            <MetaDecorator
                title="Localo | Website &amp; App Terms of Service"
                description="Localo | Read about our Website &amp; App Terms of Service here"
            />
            <TitleCard overlay="mustard-mint" background={cardBackground}>
                <h1>Localo Website &amp; App<br />Terms of Service</h1>
            </TitleCard>

            <LegalPage
                title="Localo Website &amp; App Terms of Service"
                headers={headers}
                contents={markdown}
                subtitle="Last updated: 17 December 2020"
                subtext={subtext}
            />
        </Page>
    )
}
