import React from 'react';
import { useEffect } from 'react';
import Session from '../../stores/session';
import { view } from '@risingstack/react-easy-state';

export default view(function Logout ({ history, ...props })
{
    useEffect(() => {
        Session.reset();
        
        history.push('/');
    });

    return(
        <></>
    );
})
