import { view } from '@risingstack/react-easy-state';
import React from 'react'
import basket from '../../stores/basket';

import './header-basket.scss';

export default view(function HeaderBasket({ active }) {
  return (
    <div className="header-basket">
      {
        basket.items.length > 0 ? 
        <div className="pulse">
          <div className="circle"></div>
        </div> : null
      }
      <span className="fa-layers fa-fw">
        <i title="Basket" className={active === 'basket' ? "far fa-shopping-cart active" : "far fa-shopping-cart"}></i>
        {
          basket.items.length > 0 ? <span className={active === 'basket' ? "fa-layers-counter active-bg" : "fa-layers-counter is-active"}></span> : null
        }
      </span>
      <span className={active === 'basket'? "title active" : "title"}>Basket</span>
    </div>
  )
})
