import React from 'react';
import './join-localo-navs.scss';

export default function JoinLocaloNavs() {
    return (
        <div className='join-localo-navs'>
            <a href='#join-localo' className='join-localo-navs__nav'>Sellers</a>
            <a href='#why-localo' className='join-localo-navs__nav'>Why Localo?</a>
            <a href='#fees' className='join-localo-navs__nav'>Fees</a>
            <a href='#how-we-help' className='join-localo-navs__nav'>How we help</a>
            <a href='#faq' className='join-localo-navs__nav'>FAQs</a>
        </div>
    )
}