import React, { useEffect, useState } from 'react';
import Container from '../container/container';
import request from '../../helpers/request';

import './category-grid.scss';
import { Link } from 'react-router-dom';

export default function CategoryGrid({
  postcode,
  setGoTo,
  match,
  categoryName,
}) {
  const [vendors, setVendors] = useState([]);
  const [products, setProducts] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);

  useEffect(() => {
    async function getVendors() {
      setVendors((await request(false).get('/categories?limit=500')).data.data);
    }

    getVendors();
  }, []);

  useEffect(() => {
    if (match.category !== selectedCategory) {
      setGoTo(`/products/${postcode}/categories/categories/${match.category}`);
      getProducts(match.category);
      setSelectedCategory(match.category);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.category]);

  const getProducts = (categoryId, featured) => {
    setSelectedCategory(categoryId);

    request(false)
      .get(
        `/products?category=${categoryId}&postcode=${postcode}&limit=500${
          featured ? 'featured=1' : ''
        }`
      )
      .then((e) => {
        setProducts(e.data.data);
      })
      .catch((err) => {
        setProducts([]);
      });
  };

  return (
    <Container>
      {categoryName && <div className="category-header">{`${products.length} ${categoryName || ''}`}</div>}
      <div className="shop-grid">
        {!selectedCategory
          ? vendors.map((vendor) => {
              return (
                <CategoryItem
                  getProducts={getProducts}
                  postcode={postcode}
                  key={vendor._id}
                  {...vendor}
                />
              );
            })
          : products
          ? products.map((product) => {
              // console.log(product);
              return (
                <ProductGridItem
                  postcode={postcode}
                  key={product._id}
                  {...product}
                />
              );
            })
          : []}
      </div>
    </Container>
  );
}

function CategoryItem({
  _id: id,
  name,
  category,
  images,
  vendorLocality,
  vendorNames,
  highstreet,
  getProducts,
}) {
  return (
    <div
      className="shop-grid-item shop-grid-item-layout "
      onClick={() => {
        getProducts(id);
      }}
    >
      <div className="shop-grid-item-header">
        <img alt="" src={images && images.length ? images[0].url : ''} />
      </div>
      <div className="shop-grid-item-meta-title">{name}</div>
      <div className="shop-grid-item-meta">
        {category ? <p>{category.name}</p> : ''}
        {/* {vendorNames.length > 0 ? (
          <p className='shop-grid-item-meta-address'>{vendorNames.toString()}</p>
        ) : (
          ''
        )}
        {vendorLocality.length > 0 ? (
          <p className='shop-grid-item-meta-address'>{vendorLocality.toString()}</p>
        ) : (
          ''
        )} */}
      </div>
    </div>
  );
}

function ProductGridItem({ _id: id, vendor, name, images, postcode }) {
  let vendorId = vendor !== null ? vendor._id : 'vendor';
  return (
    <Link to={`/products/${postcode}/categories/${vendorId}/${id}`}>
      <div className="product-grid-item">
        <div className="product-grid-item-image">
          <img alt="" src={images && images.length ? images[0].url : ''} />
        </div>
        <div className="shop-grid-item-meta-title">{name}</div>
        <div className="shop-grid-item-meta-address">
          {vendor && vendor.name ? <p>{vendor.name}</p> : ''}
          {vendor && vendor.address && vendor.address.locality ? (
            <p>{vendor.address.locality}</p>
          ) : (
            ''
          )}
        </div>
      </div>
    </Link>
  );
}
