import React from 'react';
import Container from '../container/container';
import ProductCard from '../product-card/product-card';
import FreshBox from '../../assets/products/fresh-box.png';
import Shop from '../../assets/products/shop.png';
import CafeShop from '../../assets/products/cafe-shop.png';
import ShopCard from '../shop-card/shop-card';
import './product-view.scss';

const title = 'Chilled & Fresh Box';
const subText = 'Super Asia, Pollokshields';
const shopTitle = 'Christie’s Butchers';
const shopType = 'Shop type';
const shopStreet = 'Street name, Locality';

export default function ProductView() {
  return (
    <div className="product-view">
      <Container>
        <div className="product-view__header">
          <div className="product-view__name">12 Fruit & veg boxes</div>
        </div>
        <div className="product-view__row">
          <div className="product-view__cell">
            <ProductCard img={FreshBox} title={title} subText={subText} />
          </div>
          <div className="product-view__cell">
            <ProductCard img={FreshBox} title={title} subText={subText} />
          </div>
          <div className="product-view__cell">
            <ProductCard img={FreshBox} title={title} subText={subText} />
          </div>
          <div className="product-view__cell">
            <ProductCard img={FreshBox} title={title} subText={subText} />
          </div>
          <div className="product-view__cell">
            <ProductCard img={FreshBox} title={title} subText={subText} />
          </div>
          <div className="product-view__cell">
            <ProductCard img={FreshBox} title={title} subText={subText} />
          </div>
          <div className="product-view__cell">
            <ProductCard img={FreshBox} title={title} subText={subText} />
          </div>
          <div className="product-view__cell">
            <ProductCard img={FreshBox} title={title} subText={subText} />
          </div>
          <div className="product-view__cell">
            <ProductCard img={FreshBox} title={title} subText={subText} />
          </div>
          <div className="product-view__cell">
            <ProductCard img={FreshBox} title={title} subText={subText} />
          </div>
          <div className="product-view__cell">
            <ProductCard img={FreshBox} title={title} subText={subText} />
          </div>
          <div className="product-view__cell">
            <ProductCard img={FreshBox} title={title} subText={subText} />
          </div>
        </div>
        <div className="product-loadmore">
          <div>Load more</div>
        </div>

        <div className="product-view__header">
          <div className="product-view__name">12 Local shops</div>
        </div>
        <div className="product-view__row">
          <div className="product-view__cell">
            <ShopCard
              img={Shop}
              title={shopTitle}
              shopType={shopType}
              shopStreet={shopStreet}
            />
          </div>
          <div className="product-view__cell">
            <ShopCard
              img={CafeShop}
              title={shopTitle}
              shopType={shopType}
              shopStreet={shopStreet}
            />
          </div>
          <div className="product-view__cell">
            <ShopCard
              img={Shop}
              title={shopTitle}
              shopType={shopType}
              shopStreet={shopStreet}
            />
          </div>
          <div className="product-view__cell">
            <ShopCard
              img={CafeShop}
              title={shopTitle}
              shopType={shopType}
              shopStreet={shopStreet}
            />
          </div>
          <div className="product-view__cell">
            <ShopCard
              img={Shop}
              title={shopTitle}
              shopType={shopType}
              shopStreet={shopStreet}
            />
          </div>
          <div className="product-view__cell">
            <ShopCard
              img={CafeShop}
              title={shopTitle}
              shopType={shopType}
              shopStreet={shopStreet}
            />
          </div>
          <div className="product-view__cell">
            <ShopCard
              img={Shop}
              title={shopTitle}
              shopType={shopType}
              shopStreet={shopStreet}
            />
          </div>
          <div className="product-view__cell">
            <ShopCard
              img={CafeShop}
              title={shopTitle}
              shopType={shopType}
              shopStreet={shopStreet}
            />
          </div>
          <div className="product-view__cell">
            <ShopCard
              img={Shop}
              title={shopTitle}
              shopType={shopType}
              shopStreet={shopStreet}
            />
          </div>
          <div className="product-view__cell">
            <ShopCard
              img={CafeShop}
              title={shopTitle}
              shopType={shopType}
              shopStreet={shopStreet}
            />
          </div>
          <div className="product-view__cell">
            <ShopCard
              img={Shop}
              title={shopTitle}
              shopType={shopType}
              shopStreet={shopStreet}
            />
          </div>
          <div className="product-view__cell">
            <ShopCard
              img={CafeShop}
              title={shopTitle}
              shopType={shopType}
              shopStreet={shopStreet}
            />
          </div>
        </div>
        <div className="product-view__header">
          <div className="product-view__name product-view__name--pink">
            Explore other shops on your high street
          </div>
        </div>
        <div className="product-view__row">
          <div className="product-view__cell">
            <ShopCard img={Shop} title={' JHC Suttie Pharmacy '} visitSite fb />
          </div>
        </div>
      </Container>
    </div>
  );
}
