import { view } from "@risingstack/react-easy-state";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import Register from "../login/Register";
import Page from "../page/page";
import TopComponent from "../top-component/top-component";
import Request from "../../helpers/request";
import SessionState from "../../stores/session";
import BasketState from "../../stores/basket";
import "./index.scss";
import Login from "../login/Login";
import ProductCard from "../product-card/product-card";
const itisonImage = require("../../assets/Itison-logo.png");

export default view(function ItisonPage() {
  const [userInput, setUserInput] = useState(null);
  const [userLogged, setuserLogged] = useState(false);
  const [coupon, setCoupon] = useState(null);
  const [isValidCode, setIsValidCode] = useState(null);

  const [couponInformation, setCouponInformation] = useState(null);
  const history = useHistory();

  const lookupCoupon = useCallback(
    (ev) => {
      ev.preventDefault();
      Request(false)
        .get("/coupons/validate", {
          params: {
            code: userInput,
          },
        })
        .then((response) => {
          if (SessionState.user) {
            setuserLogged(true);
          }
          setIsValidCode(true);
          setCoupon(response.data);
        })
        .catch((_) => {
          setIsValidCode(false);
          setCoupon(null);
        });
    },
    [userInput]
  );

  useEffect(() => {
    async function go() {
      if (!coupon) return;

      // Check coupon has a product rule
      const product = coupon.target_rules.find(
        (rule) => rule.type === "Product"
      );

      if (!product || !product.values.length) return setIsValidCode(false);

      // Check coupon has a variant rule
      const variant = coupon.target_rules.find(
        (rule) => rule.type === "Variant"
      );
      if (!variant || !variant.values.length) return setIsValidCode(false);

      // Check that variant is part of product
      let productData = null;

      await Request(false)
        .get("/products/" + product.values[0])
        .then((_) => (productData = _.data))
        .catch((_) => setIsValidCode(false));

      console.log("productData", productData);

      if (!productData) return;

      const variantData = productData.products.find(
        (product) => product._id === variant.values[0]
      );

      console.log("variantData", variantData);

      if (!variantData) return setIsValidCode(false);

      const defaultCustomization = [];
      variantData.customisation.forEach((cus) => {
        if (
          cus.options &&
          Array.isArray(cus.options) &&
          cus.options.length > 0
        ) {
          defaultCustomization.push({
            option: cus._id,
            value: cus.options[0]._id,
          });
        }
      });
      setCouponInformation({
        product: productData,
        variant: variantData,
        customisations: defaultCustomization,
      });
      BasketState.coupon = coupon.code;
      BasketState.setItems([]);
      BasketState.addItemToBasket(
        productData,
        variantData,
        defaultCustomization,
        false
      );
    }

    go();

    // Retrieve product + variant information
  }, [coupon]);

  // const claimDeal = useCallback(() => {
  //   BasketState.coupon = coupon.code;
  //   BasketState.setItems([]);
  //   BasketState.addItemToBasket(
  //     couponInformation.product,
  //     couponInformation.variant,
  //     couponInformation.customisations,
  //     false
  //   );

  // }, [coupon, couponInformation]);

  return (
    <Page>
      <TopComponent />
      <div className="itisonPage">
        <div className="login-or-register border-box">
          <div className="heading">
            <img src={itisonImage} alt="itison" />
          </div>
          <p className="welcome-text">Welcome to the ItIsOn deal!</p>
          <p className="sub-text">
            Enter your order number from the ItIsOn confirmation email
          </p>
          <form className="itison-form" onSubmit={lookupCoupon}>
            <input
              type="text"
              onChange={(event) => setUserInput(event.target.value)}
              placeholder="Redemption Code"
            />
            <input type="submit" value="Verify" />
          </form>
          {isValidCode && userLogged && (
            <ProductWithInfo
              coupon={coupon}
              couponInformation={couponInformation}
              history={history}
            />
          )}
        </div>
        {isValidCode && !userLogged && (
          <RegisterWithInfo
            couponInformation={couponInformation}
            coupon={coupon}
            history={history}
          />
        )}
      </div>
    </Page>
  );
});

const RegisterWithInfo = ({ history, couponInformation, coupon }) => {
  const [stage, setStage] = useState("login");

  return (
    <div className="container-component border-box">
      <ProductWithInfo
        couponInformation={couponInformation}
        coupon={coupon}
        history={history}
      />
      <p className="signup-text">Sign up or log in to receive your boxes</p>
      <p className="signup-sub-text">Sign up for Localo</p>
      <Register
        redirectUri={"/basket?step=2"}
        fromBasket={true}
        withoutBottomLinks
        history={history}
      />
      <Login
        redirectUri={"/basket?step=2"}
        loginOrRegister={stage}
        setLoginOrRegister={(val) => setStage(val)}
        withoutBottomLinks
      />
    </div>
  );
};

const ProductWithInfo = ({ couponInformation, history }) => {
  const product = couponInformation?.product;
  const isUserLoggedIn = SessionState.user;
  // const discount =
  //   coupon.discount_amount.type === "Fixed"
  //     ? `£${coupon.discount_amount.amount / 100}`
  //     : `${coupon.discount_amount.amount * 10}%`;
  // SessionState.user

  if (!product) return null;
  return (
    <div>
      <p className="welcome-text">Thanks for purchasing the ItIsOn deal!</p>
      <p className="sub-text">Your ItIsOn deal details</p>
      <div className="itison-product-detaile" style={{ width: "250px" }}>
        <div>
          <ProductCard
            img={
              product && product.images && product.images.length
                ? product.images[0].url
                : ""
            }
            title={product && product.name}
            subText={product && product.vendorName}
          />
        </div>
      </div>
      {isUserLoggedIn && (
        <p
          onClick={() => history.push("/basket?step=2")}
          className="sub-text cursor-pointer"
        >
          Go to checkout
        </p>
      )}
      {/* <p className="thank-you-text">
        Thank you for buying{" "}
        {couponInformation && couponInformation.product.name} for £
        {coupon.maximum_discount_amount / 100} you saved {discount} off the
        normal price!
      </p> */}
      {/* {coupon ? (
        <>
          <p>
            {coupon.discount_amount.type === "Fixed"
              ? `£${coupon.discount_amount.amount / 100}`
              : `${coupon.discount_amount.amount * 10}%`}{" "}
            off up to £{coupon.maximum_discount_amount / 100}
          </p>
        </>
      ) : null} */}
      {couponInformation ? (
        <>
          {/* <h3>
            This deal is for the {couponInformation.product.name} (
            {couponInformation.variant.title})
          </h3> */}
        </>
      ) : null}
    </div>
  );
};
