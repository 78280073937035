import React from 'react';

import './shop-card.scss';

export default function ShopCard({
  img,
  title,
  shopType,
  shopStreet,
  visitSite,
  fb,
  insta,
  tw,
}) {
  return (
    <div className="shop-card">
      <div className="shop-card__badge">Shop Now</div>
      <div className="shop-card__img">
        <img src={img} alt={title} />
      </div>
      <div className="shop-card__content">
        <div className="shop-card__title">{title}</div>
        <div className="shop-card__subText">{shopType}</div>
        <div className="shop-card__subText">{shopStreet}</div>
        {visitSite && (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a className="shop-card__site" href="#">
            Visit site
          </a>
        )}
        {fb && (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a href="#">
            <i className="icon-fb" />
          </a>
        )}
        {insta && (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a href="#">
            <i className="icon-insta" />
          </a>
        )}
        {tw && (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a href="#">
            <i className="icon-tw" />
          </a>
        )}
      </div>
    </div>
  );
}
