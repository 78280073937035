import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { view } from '@risingstack/react-easy-state';
import { loadStripe } from '@stripe/stripe-js';
import { useDispatch } from 'react-redux';

import Page from '../components/page/page';
import BasketState from '../stores/basket';
import BasketComponent from '../components/basket/basket';
import BasketStep2 from '../components/basket/step2';
import BasketStep3 from '../components/basket/step3';
import BasketComplete from '../components/basket/complete';
import { removeProduct } from '../components/basket/redux/action';

export default view(function Basket() {
  const [step, setStep] = React.useState(1);
  const dispatch = useDispatch()
  const history = useHistory();
  const urlStage = new URLSearchParams(history.location.search).get('step');
  
  useEffect(() => {
    const _stage = Number(urlStage) || 1
    // setStep(_stage);
    history.replace({
      pathname: '/basket',
      search: `?step=${_stage}`
    })
    setStep(_stage)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [cart, setCart] = [
    BasketState.items,
    (value) => {
      // console.log('SET CART', value);
      BasketState.setItems(value);
    },
  ];
  console.log("cart", cart)
  const updateItem = (id, item) => {
    // console.log('UPDATE ITEM');    
    let temp = JSON.parse(JSON.stringify(cart));
    temp[id] = item;
    console.log("updated")
    setCart(temp);
  };

  const removeItem = (index) => {
    let temp = JSON.parse(JSON.stringify(cart));
    const id = temp[index].id
    dispatch(removeProduct(id))
    temp.splice(index, 1);
    setCart(temp);
  };

  const nextStep = (step) => {
    // on step changes scroll to 0 position
    window.scrollTo(0, 0)
    setStep(step)
    history.replace({
      pathname: '/basket',
      search: `?step=${step}`
    })
  }

  const [stripe] = useState(() => loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY))

  // const stripe = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
  const [summary, setSummary] = useState()

  return (
    <Elements stripe={stripe}>
      <Page>
        {step === 1 ? (
          <BasketComponent
            history={history}
            next={() => nextStep(2)}
            cart={cart}
            removeItem={removeItem}
            updateItem={updateItem}
          />
        ) : (
          ''
        )}
        {step === 2 ? (
          <BasketStep2
            back={() => nextStep(1)}
            next={() => nextStep(3)}
            cart={cart}
            updateItem={updateItem}
            removeItem={removeItem}
          />
        ) : (
          ''
        )}
        {step === 3 ? (
          <BasketStep3
            history={history}
            next={() => nextStep(4)}
            back={() => nextStep(2)}
            cart={cart}
            setCart={setCart}
            setSummary={setSummary}
          />
        ) : (
          ''
        )}
        { step === 4 ? <BasketComplete summary={summary} history={history} /> : null }
      </Page>
    </Elements>
  );
});
