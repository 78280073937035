import React from 'react';
import { useTranslation } from 'react-i18next';
import ContainerComponent from '../container/container';
import './how.scss';

import step1icon from '../../assets/how-localo-works/step-1.png';
import step2icon from '../../assets/how-localo-works/step-2.png';
import step3icon from '../../assets/how-localo-works/step-3.png';
import step4icon from '../../assets/how-localo-works/step-4.png';

export default function HowLocaloWorks() {
  const { t } = useTranslation('common');

  return (
    <div className='how-localo-works'>
      <ContainerComponent>
        <h2>{t('hlw.title')}</h2>
        <h3>{t('hlw.tagline')}</h3>
        <ul className='steps'>
          <li className='step'>
            <img src={step1icon} alt='Step 1' />
            <div className="step-desc">
            <h4>{t('hlw.steps.first.title')}</h4>
            <p>{t('hlw.steps.first.text')}</p>
            </div>
          </li>
          <li className='step'>
            <img src={step2icon} alt='Step 2' />
          <div className="step-desc">
            <h4>{t('hlw.steps.second.title')}</h4>
            <p>{t('hlw.steps.second.text')}</p>
            </div>
          </li>
          <li className='step'>
            <img src={step3icon} alt='Step 3' />
          <div className="step-desc">
            <h4>{t('hlw.steps.third.title')}</h4>
            <p>{t('hlw.steps.third.text')}</p>
            </div>
          </li>
          <li className='step'>
            <img src={step4icon} alt='Step 4' />
          <div className="step-desc">
            <h4>{t('hlw.steps.fourth.title')}</h4>
            <p>{t('hlw.steps.fourth.text')}</p>
            </div>
          </li>
        </ul>
        <div className='video-wrapper'>
          <div className="video-16-9">
          <iframe
            title='How Localo works'
            width='100%'
            height='430'
            src='https://www.youtube.com/embed/h3Ee0GLSWHI'
            frameBorder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
            allowFullScreen
          ></iframe>
          </div>
        </div>
        <div className='petals bottom right' />
      </ContainerComponent>
    </div>
  );
}
