import React from 'react'
import ItisonMain from '../components/Itison-main'

export default function Itison(props) {
    return (
        <div>
            <ItisonMain {...props}/>
        </div>
    )
}
