import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Container from '../components/container/container';
import DetailedProductGrid from '../components/detailed-product-grid/detailed-product-grid';
import Page from '../components/page/page';
import ShopDetailedHeader from '../components/shop-detailed-header/shop-detailed-header';
// import ShopOverviewCard from '../components/shop-overview-card/shop-overview-card'
// import ShopProductDetailed from '../components/shop-product-detailed/shop-product-detailed'
// import ShopProductList from '../components/shop-product-list/shop-product-list'
import TopComponent from '../components/top-component/top-component';

export default function DetailedProduct() {
  const params = useParams();
  const history = useHistory();

  return (
    <Page>
      <TopComponent>
        <Container>
          <ShopDetailedHeader
            history={history}
            postcode={params.postcode}
            type={params.type}
            vendor={params.vendorId}
            tag={params.tag}
          />
        </Container>
      </TopComponent>
      <Container className="container-detailed-product">
        <DetailedProductGrid
          type={params.type}
          postcode={params.postcode}
          history={history}
          vendor={params.vendorId}
          product={params.productId}
          variant={params.variantId}
        />
      </Container>
    </Page>
  );
}
