import React from 'react';

import './join-localo-contact.scss';

export default function JoinLocaloContact() {
    return (
        <>
            <div className='join-localo-contact join-localo-contact--questions'>
                <h2 className='join-localo-contact__title'>Still have more questions?</h2>
                <p className='join-localo-contact__desc'>Feel free to contact us</p>
                <button className='join-localo-contact__button join-localo-contact__button--green'>Get in touch</button>
            </div>
            <div className='join-localo-contact join-localo-contact--selling'>
                <h2 className='join-localo-contact__title'>Ready to start selling?</h2>
                <p className='join-localo-contact__desc'>In just a few clicks your shop can be open for business on Localo</p>
                <button className='join-localo-contact__button join-localo-contact__button--pink'>Open your Localo shop</button>
            </div>
        </>
    )
}