import React from 'react';
import Container from '../container/container';
import ProductCard from '../product-card/product-card';
import SwiperCarousel from '../swiper-carousel/swiper-carousel';
import { useHistory } from 'react-router-dom';

import './product-reel.scss';

export default function ProductReel({ postcode, name, list, id }) {
  const history = useHistory();
  const seeAll = () => {
    history.push(`/products/${postcode}/categories/categories/${id}`, {
      featured: true,
      categoryName: name,
    });
  };
  return (
    <div className="product-reel">
      <Container>
        <div className="product-reel__header">
          <div className="product-reel__name">{name}</div>
          <div onClick={seeAll} className="product-reel__link">
            See all
          </div>
        </div>
        <SwiperCarousel className="product-reel__carousel" slideShowDesktop={5}>
          {list &&
            list.map(({ _id: id, vendor, name, images, tag }) => {
              let vendorId = 'vendor',
                vendorName = '';
              if (vendor !== null) {
                vendorId = vendor._id;
                vendorName = vendor.name;
                // vendorAddress = vendor.address !== null ? vendor.address.locality : "";
              }
              const onProductClick = () => {
                if (postcode) {
                  history.push(
                    `/products/${postcode}/products/${vendorId}/${id}`
                  );
                } else {
                  history.push(`/tag/${tag}/products/${vendorId}/${id}`);
                }
              };
              return React.Children.toArray(
                <div onClick={onProductClick}>
                  <ProductCard
                    img={images && images.length ? images[0].url : ''}
                    title={name}
                    subText={vendorName}
                  />
                </div>
              );
            })}
        </SwiperCarousel>
      </Container>
    </div>
  );
}
