import React from 'react'
import Page from '../../components/page/page';
import LegalPage from '../../components/legal-page/legal-page';
import TitleCard from '../../components/title-card/TitleCard';
import cardBackground from '../../assets/privacy-policy-banner/bg-banner.png';
import MetaDecorator from '../../hooks/metaDecorator';

const subtext = [
    'Localo Ltd ("us", "we", or "our") uses cookies on www.shoplocalo.com (the "Service"). By using the Service, you consent to the use of cookies.',
    <br />, <br />,
    'Our Cookies Policy explains what cookies are, how we use cookies, how third-parties we may partner with may use cookies on the Service, your choices regarding cookies and further information about cookies.'
];

const headers = [
    'What are cookies',
    'How Localo uses cookies',
    'Third-party cookies',
    'What are your choices regarding cookies',
    'Where can you find more information about cookies'
];

const markdown = [
    `# What are cookies

Cookies are small pieces of text sent by your web browser by a website you visit. A cookie file is stored in your web browser and allows the Service or a third-party to recognize you and make your next visit easier and the Service more useful to you.

Cookies can be "persistent" or "session" cookies.
`,

    `# How Localo uses cookies

When you use and access the Service, we may place a number of cookies files in your web browser.

We use cookies for the following purposes: to enable certain functions of the Service, to provide analytics, to store your preferences, to make it easier for you to register, log in and provide feedback, to support our promotional and marketing efforts, and to analyse the way you use our product/services/application in order to further refine our offering in future.

Cookies used by our web developers during the creation of our website may include but are not limited to:

cookieconsent_status – this cookie records whether or not you have accepted our use of cookies notice (so we do not show you the agreement bar multiple times)

We use both session and persistent cookies on the Service and we use different types of cookies to run the Service:
`,

    ` # Third-party cookies

We may also use various third-party’s cookies to report usage statistics of the Service, deliver advertisements on and through the Service, and so on.

**Google**

Google creates cookies when you visit our website. We use Google Analytics for visitor tracking and website traffic monitoring.

**Font Awesome**

We use Font Awesome’s content delivery network to load icons, Font Awesome collects data about what icon files you download and when.

Font Awesome uses data about how you use Font Awesome content delivery networks to:

- optimise the content delivery networks, so website visitors can download files quickly
- diagnose and debug technical errors
- defend the content delivery networks from abuse and technical attacks
- calculate fees for Font Awesome Pro customers
- compile statistics on icon popularity
- compile statistics on the kinds of software and computers visitors use

Font Awesome usually stores data about how you use content delivery networks in identifiable form for just a few weeks. In special circumstances, like extended investigations about technical attacks, Font Awesome may preserve log data longer, for analysis. Font Awesome stores aggregate statistics about use of content delivery networks indefinitely, but those statistics don’t include data identifiable to you personally.

Font Awesome's full privacy policy is available at https://fontawesome.com/privacy

**Hot Jar**

To function properly, Hotjar stores first-party cookies on your visitor's browser. Hotjar cookies are responsible for displaying the correct content to your visitors without personally identifying anyone. 

For more information on the types of cookies Hotjar uses please read more on their Hotjar Cookie Information page https://help.hotjar.com/hc/en-us/articles/115011789248-Hotjar-Cookie-Information

**Stripe**

The chosen payments solution provider (Stripe) uses cookies for the purposes of user authentication, fraud detection, fraud prevention, certain services on its Web site, monitoring of its services and support of its advertising strategies.

A full disclosure of Stripe's use of cookies, including persistent cookies, is documented online at https://stripe.com/cookies-policy/legal. 

The Stripe cookies are an essential part of the payment operations and Localo cannot offer any service that depends on payments if these cookies are disabled by the user.
`,

    ` # What are your choices regarding cookies

If you'd like to delete cookies or instruct your web browser to delete or refuse cookies, please visit the help pages of your web browser.

Please note, however, that if you delete cookies or refuse to accept them, you might not be able to use all of the features we offer, you may not be able to store your preferences, and some of our pages might not display properly.
`,

    ` # Where can you find more information about cookies

You can learn more about cookies and the following third-party websites:

AllAboutCookies: http://www.allaboutcookies.org/

Network Advertising Initiative: http://www.networkadvertising.org/
`
];

export default function CookiesPolicy() {
    return (
        <Page>
            <MetaDecorator
                title="Localo | Cookies Policy"
                description="Localo | Read about our cookies policy here"
            />
            <TitleCard overlay="mustard-mint" background={cardBackground}>
                <h1>Localo Cookies Policy</h1>
            </TitleCard>

            <LegalPage
                title="Localo Cookies Policy"
                headers={headers}
                contents={markdown}
                subtitle="Last updated: 17 December 2020"
                subtext={subtext}
            />
        </Page>
    )
}
