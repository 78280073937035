import React from 'react';
import './img.scss';

export default function HeaderImage ()
{
    return (
        <div className="header-image">
            {/* <div className="sticker">
                <span>
                    Choose on/off or regular deliveries
                </span>
            </div> */}
        </div>
    )
}
