import React from 'react';

import './join-localo-newsletter.scss';

export default function JoinLocaloNewsletter() {
    return (
        <div className='join-localo-newsletter'>
            <h2 className='join-localo-newsletter__title'>Yes! Send me information to help me sell on Localo</h2>
            <form>
                <div className='join-localo-newsletter__field'>
                    <input type='text' className='join-localo-newsletter__control' placeholder='Email address' />
                </div>
                <button className='join-localo-newsletter__button' type='submit'>Subscribe</button>
            </form>
        </div>
    )
}