import React, { useEffect, useState } from 'react'
import ReactModal from 'react-modal'
import Request from '../../../helpers/request'
import Style from '../BasicDetails/BasicDetails.module.scss'
import moment from 'moment'
import SimpleSelect from 'react-simple-styleable-select'
export const SubscriptionsContainer = () => {
    const [subscriptions, setSubscriptions] = useState([])

    const getData = () => {
        Request(true).get('/self/subscriptions').then(e => setSubscriptions(e.data))
    }

    useEffect(() => {
        getData()
    }, [])
    return (
        <table>
            <tbody>
                <br />
                <br />
                <tr className="color-grey">
                    <td colSpan="4">
                        <h2>Active Subscriptions</h2>
                    </td>
                </tr>
                <br />
                <br />
                {
                    subscriptions.filter(e => !e.pausedUntil).map(sub => {
                        return (
                            <Subscription refresh={getData} key={`user-sub-${sub._id}`} sub={sub} />
                        )
                    })
                }
                <br />
                <br />
                <tr className="color-grey">
                    <td colSpan="4">
                        <h2>Inactive Subscriptions</h2>
                    </td>
                </tr>
                <br />
                <br />
                {
                    subscriptions.filter(e => e.pausedUntil).map(sub => {
                        return (
                            <Subscription refresh={getData} key={`user-sub-${sub._id}`} sub={sub} />
                        )
                    })
                }
            </tbody>
        </table>
    )
}

const Subscription = ({sub, refresh}) => {
    const [showModal, setShowModal] = useState(false)
    const m = moment()
    const options = [
        { value: 'null', label: 'Pause for...' },
        { value: m.add(1, 'weeks'), label: '1 Week' },
        { value: m.add(2, 'weeks'), label: '2 Weeks' },
        { value: m.add(3, 'weeks'), label: '3 Weeks' },
        { value: m.add(4, 'weeks'), label: '4 Weeks' },
    ]
    const pauseSub = (value) => {
        Request(true).post(`/self/subscriptions/${sub._id}/pause`, {
            until: value === 'null' ? null : value || null
        }).then(() => {
            refresh()
        })
    }
    const cancelSub = () => {
        Request(true).post(`/self/subscriptions/${sub._id}/cancel`).then(() => {
            refresh();
        })
    }
    return [
        <tr>
            <td>
                <div onClick={() => setShowModal(true)} style={{cursor: 'pointer'}}><span className="fas fa-edit color-mint-green fa-lg"></span></div>
            </td>
            <td>
                <p className="color-grey bold">{sub.product.name}</p>
            </td>
            <td>
                <p className="color-grey bold">{sub.vendor.name}</p>
            </td>
            <td>
                <p className="color-grey bold">£{sub.price.total}</p>
            </td>
        </tr>,
        <ReactModal overlayClassName={Style['custom-height']} style={{content: {overflow: 'unset'}}} isOpen={showModal} onRequestClose={() => setShowModal(false)}>
            <div style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', cursor: 'pointer'}} onClick={() => setShowModal(false)}>
                    <span className="fas fa-times fa-lg"></span>
                </div>
                <div className={Style['col-2']}>
                    <div>
                        <h3>{sub.product.name}</h3>
                        <h3>{sub.vendor.name}</h3>

                        <h3>Last Ordered: {moment(sub.lastOrderCreatedAt).format('DD/MM/YYYY')}</h3>
                    </div>
                    <div style={{display: 'grid', justifyContent: 'center', alignItems: 'center'}}>
                        {
                            sub.product.images.length > 0 ? <>
                                <img alt="" src={sub.product.images[0].url} style={{width: 'auto', maxHeight: 166}} />
                            </> : null
                        }
                    </div>
                </div>
                <div style={{margin: 'auto 0 0'}}>
                    <div style={{display: 'grid', gridTemplateColumns: 'max-content auto max-content'}}>
                        {
                            sub.pausedUntil ? <>
                                <button onClick={() => pauseSub(null)} className="button button-green-solid button-view-basket">Unpause</button>
                            </> : <div className="grid">
                                <SimpleSelect
                                    onChange={(_, val) => {pauseSub(val)}}
                                    options={options}
                                    hasSharpCorners
                                    fixedHeight
                                    defaultValue={'null'}
                                />
                            </div>
                        }
                        <div></div>
                        <button onClick={() => cancelSub()} className="button button-view-basket">Cancel <span className="is-for-large">&nbsp;subscription</span></button>
                    </div>
                </div>
            </div>
        </ReactModal>
    ]
}