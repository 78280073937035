export const actionType = {
  SET_DELIVERY_SLOT: 'SET_DELIVERY_SLOT',
  SET_PRODUCT_COST: 'SET_PRODUCT_COST',
  REMOVE_PRODUCT: 'REMOVE_PRODUCT',
  SET_PRODUCT_VENDER: 'SET_PRODUCT_VENDER',
  IS_LOADING: 'IS_LOADING',
};

const INITIAL_STATE = {
  product: [],
  isLoading: false,
};

const basket = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionType.REMOVE_PRODUCT:
      const allProduct = [...state.product];
      const removeIndex = allProduct.findIndex(
        ({ id }) => id === action.payload.id
      );
      allProduct.splice(removeIndex, 1);
      return {
        ...state,
        product: allProduct,
      };
    case actionType.SET_DELIVERY_SLOT: {
      const product = [...state.product];
      const slots = action.payload.slots;
      const index = product.findIndex(({ id }) => id === action.payload.id);
      if (index === -1) {
        product.push({ slots, id: action.payload.id });
      } else {
        product[index].slots = slots;
      }
      return {
        ...state,
        product,
      };
    }
    case actionType.SET_PRODUCT_COST: {
      const products = [...state.product];
      const cost = action.payload.obj;
      const index = products.findIndex(({ id }) => id === action.payload.id);
      if (index === -1) {
        products.push({ ...cost, id: action.payload.id });
      } else {
        products[index] = { ...cost, ...products[index] };
      }
      return {
        ...state,
        product: products,
      };
    }
    case actionType.SET_PRODUCT_VENDER: {
      const products = [...state.product];
      const vendor = action.payload.vendor;
      const index = products.findIndex(({ id }) => id === action.payload.id);
      if (index === -1) {
        products.push({ vendor, id: action.payload.id });
      } else {
        products[index].vendor = vendor;
      }
      return {
        ...state,
        product: products,
      };
    }
    case actionType.IS_LOADING: {
      return {
        ...state,
        isLoading: action.flag,
      };
    }
    default:
      return state;
  }
};

export default basket;
