import React from 'react';
import './container.scss';

export default function Container ({ children, className, ...props })
{
    return (
        <div className={`container-component ${className}`} {...props}>
            { children }
        </div>
    )
}
