import React from 'react'
import Page from '../../components/page/page';
import LegalPage from '../../components/legal-page/legal-page';
import TitleCard from '../../components/title-card/TitleCard';
import cardBackground from '../../assets/privacy-policy-banner/bg-banner.png';
import MetaDecorator from '../../hooks/metaDecorator';

const headers = [
    '1. Contact Details',
    '2. How We Collect Your Information',
    '3. Information We Collect From You',
    '4. Use of the information you provided',
    '5. Cookies',
    '6. Direct Marketing',
    '7. Retention of Your Information',
    '8. Disclosure of Your Information',
    '9. Security',
    '10. Your Rights',
    '11. Changes in our privacy policy',
    '12. Complaints'
];

const markdown = [
    `# 1. Contact Details

If you have any questions or concerns about our policy or our practices with regards to your personal information. If you have any queries or requests concerning this privacy policy or how we handle your data more generally, please get in touch with us using the following details.

- By contacting our general customer services team at: team@shoplocalo.com
- By contacting our Data Protection Officer: team@shoplocalo.com
`,

    `# 2. How We Collect Your Information

We collect personal information that you provide to us collect your personal information when you interact with us or use our services, such as when you use our Sites to place an order. We also similarly look at how exactly how visitors use our website Sites, to enable help us to improve our services and improve customer experience.

We collect information:

1. when you create an account with us or you change your account settings;
2. when you place an order with us and during the order process (including for payment and order delivery);
3. when you give us your consent to contact you via email, phone, post, message or via our chat function to send you marketing campaigns, or to invite you to participate in surveys about our services, or our partners’ services;
4. when you contact us directly via email, phone, post, message or via our chat function; and
5. when you browse and use our Sites (before and after you create an account with us).

We also gather data from third third-party sites, such as the promotion of platforms and our fraud detection provider.
`,

    `# 3. Information We Collect From You

Mostly as part of our duty to the privacy of our visitors and customers on our Sites, we want to be specific about the kinds of data that we will collect from you.

When you visit the Sites or place a Localo order through the Sites, you are asked to provide personal information, including your name, contact details, delivery address, order details and payment information, Information relating to debit or credit card. We may also collect your date of birth to check your age when you purchase age-restricted items As part of our commitment to the privacy of our customers and visitors to our Sites more generally, we want to be clear about the sorts of information we will collect from you.

We also collect information about your use of the Sites and information about you from any messages you post to the Sites or when you contact us or provide us with comments by e-mail, phone, letter or chat. We record the call for training and service enhancement purposes and make notes regarding your call. We collect your first and last name, email address, postal address, telephone number, etc.

We collect technical information from your mobile device computers, such as its operating system, the type of device and connection, and the IP address from which you access our Sites.

We process health information about you only where you volunteer and consent to it, for example, if you specify any food allergies.
`,

    `# 4. Use of the information you provided

We will only process the data we collect about you if there is a reason to do so and if that reason is permitted under data protection law.

If we need to provide you with the service you have requested or enter into a contract, we will use your information as follows:

- to enable us to give you access to the relevant parts of the Sites;
- to provide the services you have requested;
- so we can receive payment from you;
- to contact you, where necessary, regarding our services, in order to resolve any issues you may have with your order.

We also process your data where we have a legitimate interest in doing so-such as personalising our service, including processing data to make it easier and faster for you to place orders. Below we listed these reasons:

- Improving the efficiency and quality of service that our clients can expect from us in the future;
- To tailor the content that we or our third-party sellers or advertising partners display to you, for example, so that we can show you, sellers, in your area or make sure that you see the advertisement that is most relevant to you, Based on our identified characteristics;
- To enable our customer support team to assist you in the most efficient way possible with any inquiries or complaints and to provide a positive customer experience;
- Contact you for your views and feedback on our services or the services and/or products of our partners and notify you of any significant changes or developments to the Sites or our Products, even letting you know that our services run in a new region, where you asked us to do so;
- To give you information by post about our goods, services and promotions (if you do not want to receive them, you can let us know by contacting us (see Contact details));
- To evaluate your activities on the Sites so that we can handle, help, enhance and grow our business; and for statistical, analytical and fraud-prevention purposes;

We also process your data to implement our contractual terms with you and any other arrangement and to protect the interests of Localo, Vendors, Distribution Teams or others (including fraud prevention) for the exercise or protection of legal claims;

If you provide comments and feedback about the Sites and Services, such comments and feedback can be used on the Sites and in any marketing or advertising content. We'll only identify you by your first name and the city you live in for this reason. Where you have chosen to receive push notifications from us via our mobile application, we can send you push notifications concerning the services you requested from us and information about our services and offers. You can decide to stop receiving push notifications from us at any time by changing your mobile device preferences or by contacting us (see Contact details).

We will also review data from your locality data about your use of our services to create profiles that connect to you and for you. This means that we can make some assumptions about what you may be interested in and use this, for example, to give you more tailor-made marketing messages, to introduce you to sellers we consider you might like, or to let you know about special deals or products which we think you would interest you. This practice is called profiling. You have some privileges over-processing of this kind. For more information please see ‘Your Rights' section below.

Where we rely on genuine interests as a basis for the handling of your personal data, we carry out a 'balancing test' to ensure that our processing is necessary and that your fundamental information is and your basic privacy rights aren't outweighed by our legitimate interests. For more information on these balancing tests please contact us using the description above.

Where we have a legal obligation to do so, we can use your information to establish a record of your order(s) and to satisfy any legal obligation or regulatory requirement that we are subject to.
`,

    `# 5. Cookies

You can set your browser to refuse all or some browser cookies, or to alert you when websites are set, or to access cookies. If you disable or reject cookies, please keep note that some parts of the Web can become unavailable or may not function properly. For more information on the cookies we use, please see our Cookie Policy https:/www.shoplocalo.com/cookies.

use, please see our Cookie Policy https:/www.shoplocalo.com/cookies.

You can set your browser to refuse all or some browser cookies, or to alert you when websites set or access cookies. If you disable or refuse cookies, please note that some parts of the Site may become inaccessible or not function properly. For more information about the cookies we use, please see our Cookie Policy https:/www.shoplocalo.com/cookies.
`,

    `# 6. Direct Marketing

If you have given your consent or if we have a valid interest in doing so (and are approved by law) we will use your details to let you know about our other products and services that may be of interest to you and we may contact you by email, post or telephone. You can monitor your marketing preferences by the following:

- Visit our website, www.shoplocalo.com or the smartphone applicator.
- By clicking on the "Account" button (on our website, this is in the drop-down menu);
- Scroll down to "Marketing Preferences”
- Or a member of our customer support team by writing or email Where you have given your consent or where we have a legitimate interest for doing so (and are permitted to do so by law) we will use your information to let you know about our other products and services that may be of interest to you and we may contact you to do so by email, post or phone. You can control your marketing preferences by visiting our website, www.shoplocalo.com, or mobile application; clicking on "Account" (for our website this is under the drop-down menu); and scrolling down to "Marketing Preferences".
- Or by writing or email a member of our customer support team
`,

    `# 7. Retention of Your Information

For no longer than we think is appropriate, we will not retain your information.

Information that we gather will be maintained for as long as it is appropriate to satisfy the objectives stated in the 'Use of My Information' section above, in compliance with our legitimate interest, or for a time that is expressly mandated by applicable legislation or regulations, such as the retention of information for regulatory reporting purposes.

When deciding the respective retention periods we will consider factors including:

- Our contractual responsibilities and rights about the details in question;
- The legal obligation(s) to preserve data for a certain time under applicable law;
- Statute of limitations according to applicable law(s);
- Our legitimate interests where the balancing experiments have been carried out (see the section on  We will not retain your information for any longer than we think is necessary.
- Information that we collect will be retained for as long as needed to fulfil the purposes outlined in the ‘Use of my information’ section above, in line with our legitimate interest or for a period specifically required by applicable regulations or laws, such as retaining the information for regulatory reporting purposes.
- When determining the relevant retention periods, we will take into account factors including:
- our contractual obligations and rights in relation to the information involved;
- legal obligation(s) under applicable law to retain data for a certain period of time;
- statute of limitations under applicable law(s);
- our legitimate interests where we have carried out balancing tests (see section on 'How we use your personal information' above);
- (possible) disputes; and
- Recommendations issued by the data protection authorities concerned;
- Otherwise, we will safely delete your information where we no longer need your information for the collection purposes.
`,

    `# 8. Disclosure of Your Information

The information we gather about you will be transferred to our servers located within the EU and stored there. We are very vigilant and open about who else we are sending your information to.

We only share your details with other companies in the Localo community where appropriate for the purposes set out in section 4. We share your details with service providers from third parties that provide services on

The information we collect about you will be transferred to and stored on our servers located within the EU. We are very careful and transparent about who else your information is shared with.

We share your information with other Localo group companies only where necessary for the purposes set out in section 4. We share your information with third third-party service providers that provide services on our behalf.

For example, the kinds of third party service providers with whom we share your details include:

- Payment service providers (including providers of online payments and fraud detection services);
- IT service providers (including those offering cloud services);
- Salespeople;
- Distribution Team;
- Consumer support Team; and
- Partners in promotion and advertising; which provide services on our behalf. 
- The types of third party service providers whom we share your information with include for example:
- Payment providers (including online payment providers and fraud detection providers);
- IT service providers (including cloud providers);
- Sellers;
- Delivery Team;
- Customer support partners; and
- Marketing and advertising partners.

Localo shall take all appropriate measures necessary to ensure that your data are handled in a safe manner and in compliance with this Privacy Policy when it is transmitted to third parties.

If our business enters into a joint venture with, purchases or is sold or combined with another business entity, your information may be disclosed or transferred to the target company, our new business partners or owners or their consultants

We can share your details as well:

- if we must report or share your information in order to comply with (and/or conclude that we have a duty to comply with) any legal obligation or regulatory requirement. This involves the sharing of knowledge with other companies and organisations for the safety and prevention of fraud;
- to impose our contractual conditions with you and with every other agreement;
- to protect the rights of Localo, the Vendors, the Delivery Team or others, including the prevention of fraud;
- with third parties that we judiciously deem appropriate for the prevention of crime, e.g. by the police or for health and safety purposes.

Localo will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this privacy policy when it is transferred to third parties.

If our business enters into a joint venture with, purchases or is sold to or merged with another business entity, your information may be disclosed or transferred to the target company, our new business partners or owners or their advisors.

We may also share your information:

- if we are under a duty to disclose or share your information in order to comply with (and/or where we believe we are under a duty to comply with) any legal obligation or regulatory requirement. This includes exchanging information with other companies and other organisations for the purposes of fraud protection and prevention;
- in order to enforce our contractual terms with you and any other agreement;
- to protect the rights of Localo, Sellers, Delivery Team, or others, including to prevent fraud;
- and with such third parties as we reasonably consider necessary in order to prevent crime, e.g. the police or for health and safety purposes.

International transfers of data:

Internal Data Transfers:

- In some cases, the personal data that we collect from you may be processed outside the European Economic Area ('EEA'), such as the United States, the Philippines and the countries where Localo operates (as set out on www.shoplocalo.com); these countries might not have the same safeguards as the EEA for your personal data. However, we are obligated to ensure that the personal data collected by us and our suppliers outside the EEA are covered in the same way as it would be when collected within the EEA. Therefore such protections are in effect when processing the data outside of the EEA. 
- We guarantee a comparable level of security by ensuring that at least one of the following is provided. In some cases the personal data we collect from you might be processed outside the European Economic Area ("EEA"), such as the United States, the Philippines and the countries in which Localo operates (which are set out on www.shoplocalo.com). These countries may not have the same protections for your personal data as the EEA has. However, we are obliged to ensure that the personal data that is processed by us and our suppliers outside of the EEA is protected in the same ways as it would be if it was processed within the EEA. There are therefore certain safeguards in place when your data is processed outside of the EEA.
- We ensure a similar degree of protection is afforded to it by ensuring at least one of the following safeguards is implemented:
  1. Your personal data shall be transferred to countries considered by the European Commission to have an appropriate standard of data protection;
  2. We use standard Contract Clauses accepted by the EU; and
  3. If your personal data is transmitted to US-based third-party providers, data can be passed to them if they are self-certified under the Privacy Shield system in relation to the form of data being exchanged, requiring them to have similar protection to the personal data shared between the EU and the US.

Please contact us using the contact details above if you would like more information about the countries to which personal data can be transferred and the particular process used by us when transferring your personal data from the EEA your personal data is transferred to countries that have been deemed to provide an adequate level of protection for personal data by the European Commission; we use the EU approved Standard Contractual Clauses; and where your personal data is transferred to third party providers based in the US, data may be transferred to them if they have self-certified under the Privacy Shield framework in relation to the type of data being transferred, which requires them to provide similar protection to personal data shared between the EU and the US.
`,
    `# 9. Security
We follow rigorous technology and policies to ensure proper security of the sensitive information that we carry about you.

We take precautions to protect your information from unauthorised access and improper processing, accidental loss, degradation and harm.

If you have chosen a password that allows you to enter some parts of the Web, you are responsible for keeping this password private. We suggest you do not share your password with anyone else.
    
We suggest you do not share your password with someone else. Where you have chosen a password that allows you to access certain parts of the Sites, you are responsible for keeping this password confidential.
    
Unfortunately, data transmission over the internet is not absolutely safe. Unfortunately, the transmission of information via the internet is not completely secure. Although we will take steps to protect your information, we cannot guarantee the security of your data transmitted to the Sites; any transmission is at your own risk jeopardy. Once we have received your information, we will use strict measures and security features to try preventing unauthorised entry.
`,

    `# 10. Your Rights

Subject to applicable law, you may have a number of rights concerning the data we hold about you. If you wish to exercise any of these rights, please contact our Data Protection Officer using the contact details set out above. For additional information on your rights please contact your data protection authority and see below.

To the extent provided by the law of your jurisdiction, you may request access to the personal information we maintain about you or request that we correct, update, amend, or delete your information, or that we restrict the processing of such information by contacting us as indicated below.

You may have the right to obtain your personal information in an accessible and transferable format so that you can re-use it for your own purposes across different service providers.

You can object by changing your marketing preferences, disabling cookies as set out in sections 7 and 8 above or by getting in touch (see Contact Details).
`,

    `# 11. Changes in our privacy policy

Any changes to our privacy policy will be posted to the Sites and, where appropriate, we may notify you of the changes for example by email or push notification.

This privacy policy was last updated: 17/12/2020
`,

    `# 12. Complaints

If you are not happy with our response to any complaint or feel that our processing of your information does not comply with data protection legislation, you can lodge a complaint with the Information Commissioner's Office (ICO) using the following details:

Address: Information Commissioner, Wycliffe House, Water Street, Wilmslow, Cheshire SK9 5AF
Site: - [www.ico.org.uk](https://ico.org.uk/)

Localo Ltd, Atlantic Chambers, 6th floor 45 Hope Street, Glasgow G2 6AE, United Kingdom
`
];

export default function Privacy() {
    return (
        <Page>
             <MetaDecorator
                title="Localo | Privacy Policy"
                description="Localo | Read about our privacy policy here"
            />
            <TitleCard overlay="mustard-mint" background={cardBackground}>
                <h1>Localo Privacy Policy</h1>
            </TitleCard>

            <LegalPage
                title="Localo Privacy Policy"
                headers={headers}
                contents={markdown}
                subtitle="Last Reviewed: 04/09/2020"
                subtext='Localo Ltd ("we", "our", "us" or "Localo") is committed to protecting the privacy of all users of our website www.shoplocalo.com, or mobile applications (together, the "Sites"). We hope you take some time to read through our privacy policy carefully. We are the "data controller" of the information we process, unless otherwise stated.' />
        </Page>
    )
}
