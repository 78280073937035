import React, { useCallback, useState } from 'react'
import Request from '../../../helpers/request'
import Style from '../BasicDetails/BasicDetails.module.scss'

export const PasswordContainer = () => {
    const [password, setPassword] = useState({
        old: null,
        new: null,
        confirm: null
    })
    const handleUpdatePassword = useCallback(() => {
        if (!password.old) return window.alert('You have to specify your old password to update it.')
        if (password.new !== password.confirm) return window.alert('New password does not match confirmed password.');
        Request(true).patch('/self/password', {
            currentPassword: password.old,
            newPassword: password.confirm
        }).then(e => {
            return window.alert('Your password has been updated!')
        }).catch(err => {
            return window.alert('Password failed to update. Have you entered your old password and confirmed your new one?')
        })
    }, [password])
    const updatePassword = useCallback((e) => {
        setPassword({
            ...password,
            [e.target.name]: e.target.value
        })
    }, [password])
    return (
        <div style={{display: 'flex', flexDirection: 'column', gap: 5}}>
            <div className={Style['col-2']}>
                <input className="color-mint-green-outline color-grey" style={{padding:15}} onChange={updatePassword} name="old" type="text" placeholder="Current Password" />
                <input className="color-mint-green-outline color-grey" style={{padding:15}} onChange={updatePassword} name="new" type="text" placeholder="New Password" />
            </div>
            <div className={Style['col-2']}>
                <span></span>
                <input className="color-mint-green-outline color-grey" style={{padding:15}} onChange={updatePassword} name="confirm" type="text" placeholder="Confirm New Password" />
            </div>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
                <button onClick={handleUpdatePassword} className="button button-view-basket button-green">Update</button>
            </div>
        </div>
    )
}