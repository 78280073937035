import React from 'react';
import { useTranslation } from 'react-i18next';

export default function BasketFooter({ back, next, nextText, backText }) {
  const { t } = useTranslation('common');
  return (

    <div className='sticky-step-1-bar'>
      <div className='back' onClick={back}>
        {backText ? backText : t('basket.back')}
      </div>
      {nextText !== null ? (
        <div className='next' onClick={next}>
          {nextText ? nextText : t('basket.next-step')}
        </div>
      ) : null}
    </div>
  );
}
