import React from 'react';
import ContainerComponent from '../container/container';

import './join-localo-start-selling.scss';

export default function JoinLocaloStartSelling() {
    return (
        <div className='join-localo-start-selling'>
            <ContainerComponent>
                <div className='join-localo-start-selling__wrapper'>
                    <h2 className='join-localo-start-selling__title'>Start selling today</h2>
                    <button className='join-localo-start-selling__button'>Sellers</button>
                </div>
            </ContainerComponent>
            <div className='selling-leaf-big'></div>
            <div className='selling-leaf-small'></div>
        </div>
    )
}