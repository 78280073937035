import React from 'react';
import ContainerComponent from '../container/container';
import './join-localo-sts.scss';

export default function JoinLocaloSTS() {
    return (
        <div className='join-localo-sts' id='fees'>
            <ContainerComponent>
                <h2 className='join-localo-sts__title'>Simple, transparent, secure</h2>
                <ul className='join-localo-sts__list'>
                    <li className='join-localo-sts__item'>
                        No additional monthly fees
                    </li>
                    <li className='join-localo-sts__item'>
                        Secure transactions
                    </li>
                </ul>
                <ul className='join-localo-sts__list'>
                    <li className='join-localo-sts__item'>
                        Automatic deposits
                    </li>
                    <li className='join-localo-sts__item'>
                        Seller protection
                    </li>
                </ul>
                <div className='join-localo-sts__note'>
                    Payments are processed by Stripe on our secure, SSL-encrypted platform, and have security specialists and fraud detection systems to protect you and your buyers 24/7. Read more about Stripe’s industry-leading security here{' '}
                    <a href='https://stripe.com/docs/security/stripe'>https://stripe.com/docs/security/stripe</a>
                </div>
            </ContainerComponent>
            <div className='selling-leaf-big'></div>
            <div className='selling-leaf-small'></div>
        </div>
    )
}