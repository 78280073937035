import React from 'react';
import Container from '../container/container';
import './product-back.scss';

export default function ProductBack({ history, goTo }) {
  const onBack = () => {
    history.goBack();
  };
  return (
    <div className="product-back">
      <Container>
        <button onClick={onBack} className="product-back__btn">
          Back
        </button>
      </Container>
    </div>
  );
}
