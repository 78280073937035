import React from 'react';
import './order-item.scss';

const OrderItem = ({ title, text, price }) => {
  return (
    <div className='order__item-layout'>
      <div className='left'>
        <h2 className='order__item-title'>{title}</h2>
        <p className='order__item-text'>{text}</p>
      </div>
      <div className='right'>
        <p className='order__item-title'>{price}</p>
      </div>
    </div>
  );
};

export default React.memo(OrderItem);
