import axios from 'axios';

export default function Request (authed = false) {
    let obj = {
        baseURL: process.env.REACT_APP_API_URL
    };

    if (authed) {
        obj.headers = {
            Authorization: 'Bearer ' + localStorage.getItem('user-token')
        }
    }

    const instance = axios.create(obj)

    instance.interceptors.response.use((request) => {
        const newToken = request.headers['x-jwt-update']
        if (newToken) {
            localStorage.setItem('user-token', newToken)
        }
        return request;
    })

    return instance;
}
