import React, { useMemo, useState, useEffect } from 'react';
// import SimpleSelect from 'react-simple-styleable-select';
import request from '../../helpers/request';
import './shop-product-list-item.scss';

export default function ShopProductListItem({
  _id: id,
  name,
  products,
  images,
  vendor,
  history,
  highstreet,
  showDetailView,
  quickDelivery,
  productId,
  handleCardClick
}) {
  const [deliverySlots, setDeliverySlots] = useState([]);
  const [currentDay, setCurrentDay] = useState({});

  const cheapestProduct = useMemo(() => {
    let lowestPrice = 0;
    products.forEach((p) => {
      if (p.price < lowestPrice || lowestPrice === 0) lowestPrice = p.price;
    });
    return lowestPrice.toFixed(2);
  }, [products]);

  const days = useMemo(() => {
    const ret = [];
    Object.keys(deliverySlots).forEach((day) => {
      ret.push({
        id: deliverySlots[day].id,
        day: deliverySlots[day].day,
        available: deliverySlots[day].available,
        date: deliverySlots[day].date,
      });
    });
    return ret;
  }, [deliverySlots]);

  useEffect(() => {
    request(true)
      .get(`/helpers/delivery-slots${quickDelivery ? '?quick=1' : ''}`)
      .then((e) => {
        setDeliverySlots(e.data);
      });
  }, [quickDelivery]);

  useEffect(() => {
    if (!days || !days.length) return;     
      let firstDay = days.filter((a) => a.available)[0];            
      setCurrentDay(firstDay);      
  }, [days]);
  
  /*const selectProducts = useMemo(() => {
    let returnedList = [];
    products.forEach((p) =>
      returnedList.push({ value: p._id, label: p.title })
    );
    return returnedList;
  }, [products]);

  const changed = (e, value, obj) => {
    if (!value) {
      history.push(`/products/${highstreet}/shops/${vendor}/${id}`);
    } else {
      history.push(`/products/${highstreet}/shops/${vendor}/${id}/${value}`);
      showDetailView(true);
    }
  }; */

  const truncate = (input) => input && input.length > 5 ? `${input.substring(0, 45)}...` : input;

  return (
    <div className={`shop-product-list-item ${productId === id ? 'active' : ''}`} onClick={handleCardClick}>
      <div className='shop-product-list-item-left'>
        <img alt='' src={images && images.length ? images[0].url : ''} />
        {/* {quickDelivery && <span className='quick-delivery-icon' />}  */}
      </div>
      {/* <div className={`shop-product-list-item-right ${history.location.pathname && history.location.pathname.includes(id) ? 'active' : ''}`}> */}
      <div className='shop-product-list-item-right'>
        <div className='shop-product-list-item-right-top'>
          <p>{name}</p>
          <p className='shop-product-list-item-get-it'>Get it {currentDay.day || ""}</p>
          <p className='shop-product-list-item-description'>{products.length > 0 ? truncate(products[0].shortDescription) : ""}</p>
          <p>From: £{cheapestProduct}</p>
        </div>
        {/* <div className='shop-product-list-item-right-bottom'>
          <SimpleSelect
            onChange={changed}
            defaultValue={
              history.location.pathname &&
              history.location.pathname.includes(id) &&
              selectProducts[0].value
            }
            value=''
            options={selectProducts}
            hasSharpCorners
            fixedHeight
            style={{ color: 'black' }}
          />
        </div> */}
      </div>
    </div>
  );
}
  
