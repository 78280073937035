import React, { useEffect, useState } from 'react';
import Request from '../../helpers/request';

import ProductReel from '../product-reel/product-reel';

export default function FeaturedCategories({
  postcode,
  item: { _id: id, name },
}) {
  const [featuredBoxes, setFeaturedBoxes] = useState([]);
  useEffect(() => {
    fetchFeaturedBoxes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchFeaturedBoxes = () => {
    Request(false)
      .get(`/products?limit=15&postcode=${postcode}&category=${id}`)
      .then((res) => {
        if (res.data) {
          const list = res.data.data;
          setFeaturedBoxes(list);
        }
      });
  };

  return (
    <ProductReel postcode={postcode} list={featuredBoxes} name={name} id={id} />
  );
}
