import { createSelector } from 'reselect';

function getDiscount(accumulator, item) {
  return accumulator + item.discount;
}

function getCost(accumulator, item) {
  return accumulator + item.cost;
}

const getProducts = (state) => state.basket.product;
const filterProduct = (product, id) => {
  return product.filter((item) => item.id === id);
};

export const getProduct = createSelector(
  getProducts,
  (_, id) => id,
  filterProduct
);

export const getProductTotalDiscount = createSelector(getProducts, (item) =>
  item.reduce(getDiscount, 0)
);

export const getProductTotalCost = createSelector(getProducts, (item) =>
  item.reduce(getCost, 0)
);
