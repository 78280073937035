import React from "react";
import SwiperCore, { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import 'swiper/components/pagination/pagination.scss';

import "./swiper-carousel.scss";

import useSwiperRef from "../../hooks/useSwiperRef";

SwiperCore.use([Navigation, Pagination]);

export default function SwiperCarousel({ slideShowDesktop, className, children, page }) {
  const [nextEl, nextElRef] = useSwiperRef();
  const [prevEl, prevElRef] = useSwiperRef();

  const slide = document.getElementsByClassName("swiper-slide");
  const slideLen = slide.length > slideShowDesktop ? "swiper-nav-show" : "";

  // console.log(slideLen)

  const pageShow = page ?  {
    pagination: {
      clickable: true
    }
  } : null;

  return (
    <div className={`swiper-carousel ${slideLen} ${className ? className : ''}`}>
      <div ref={prevElRef} className="swiper-button-prev"></div>
      <div ref={nextElRef} className="swiper-button-next"></div>
      <Swiper
        setWrapperSize={true}
        navigation={{
          nextEl,
          prevEl,
        }}
        {...pageShow}
        slidesPerView={"auto"}
        breakpoints={{
          360: {
            spaceBetween: 7,
          },
          1000: {
            spaceBetween: 17,
          },
        }}
      >
        {children && children.length ? children?.map((children, index) => (
          <SwiperSlide key={index}>{children}</SwiperSlide>
        ))
        :
        children ? <SwiperSlide>{children}</SwiperSlide> : null
      }
      </Swiper>
    </div>
  );
}
