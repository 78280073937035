import { store } from '@risingstack/react-easy-state'
import Request from '../helpers/request';

const Session = store({
    token: new URLSearchParams(window.location.search).get('token') || localStorage.getItem('user-token'),
    user: null,
    fetch: () => {
        localStorage.setItem('user-token', Session.token)
        return Request(true).get('/self').then(r => {
            Session.user = r.data;
            localStorage.setItem('user-token', Session.token)
        }).catch(e => {
            Session.reset();
        });
    },
    reset: () => {
        Session.token = null;
        Session.user = null;
        localStorage.removeItem('user-token');
    }
});

if (Session.user === null && Session.token !== null) {
    Session.fetch();
}

export default Session;
