import React from 'react';
import { useState } from 'react';
import { store as toast } from 'react-notifications-component';
import { Link } from 'react-router-dom';
import Errors from '../../helpers/errors';
import Request from '../../helpers/request';
import Container from '../container/container';
import Page from '../page/page';
import './login-register.scss';

export default function PasswordReset ({ location, history, ...props })
{
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    // useEffect(() => {
    //     const params = new URLSearchParams(location.search);
    //     const token = params.get('token');

    //     const newP = window.prompt('What would you like your new password to be?')

    //     const pConfirm = window.prompt('Confirm your new password.')

    //     if (!newP === pConfirm) {
    //         alert('Passwords do not match, try again')
    //         window.location.reload()
    //     }

    //     if (token !== null) {
    //         Request(false).post('auth/passwordreset', {
    //             token: token,
    //             password: newP
    //         }).catch(e => {
    //             // Absorb;
    //         });
    //     }
        
    //     history.push('/');
    // });

    const resetPassword = (e) => {
        e.preventDefault();
        const params = new URLSearchParams(location.search);
        const token = params.get('token');
        if (password === confirmPassword) {
            Request(false).post('auth/passwordreset', {
                token: token,
                password: password
            }).then((data) => {
                if (data.status === 200) {
                    toast.addNotification({
                        title: 'Great!',
                        message: `We've updated your password, head over to login to access your account.`,
                        type: 'success',
                        insert: 'bottom',
                        container: 'bottom-right',
                        animationIn: ['animate__animated', 'animate__fadeIn'],
                        animationOut: ['animate__animated', 'animate__fadeOut'],
                        dismiss: {
                          duration: 10000,
                          onScreen: true,
                        },
                      })

                    history.replace('/login');
                }
            })
            .catch(e => {
                console.log(e.response.data.error, Errors, Errors[e.response ? e.response.data ? e.response.data.error : 'GENERIC' : 'GENERIC'])
                // Absorb;
                const errors = Errors[e.response ? e.response.data ? e.response.data.error : 'GENERIC' : 'GENERIC']
                toast.addNotification({
                    title: 'Oops',
                    message: `There was an error... ${errors}`,
                    type: 'info',
                    insert: 'bottom',
                    container: 'bottom-right',
                    animationIn: ['animate__animated', 'animate__fadeIn'],
                    animationOut: ['animate__animated', 'animate__fadeOut'],
                    dismiss: {
                      duration: 10000,
                      onScreen: true,
                    },
                  })
            });
        } else {
            toast.addNotification({
                title: 'Oops',
                message: `Both password fields must match, try again.`,
                type: 'info',
                insert: 'bottom',
                container: 'bottom-right',
                animationIn: ['animate__animated', 'animate__fadeIn'],
                animationOut: ['animate__animated', 'animate__fadeOut'],
                dismiss: {
                  duration: 10000,
                  onScreen: true,
                },
              })
        }
    }

    return (
        <Page>
            <Container>
                <div className="login-or-register">
                    <h1>Reset your Password</h1>
                    <form onSubmit={e => resetPassword(e)}>
                        <div>
                            <input type="password" style={{ marginBottom: 0 }} placeholder="New Password" onChange={e => setPassword(e.target.value)} required />
                        </div>
                        <div>
                            <input type="password" style={{ marginBottom: 0 }} placeholder="Repeat New Password" onChange={e => setConfirmPassword(e.target.value)} required />
                        </div>
                        <input type="submit" value="Submit" />
                        <Link style={{textAlign: 'center', fontWeight: 700}} to="/login">Go to Login</Link>
                    </form>
                </div>
            </Container>
        </Page>
    );
}
