import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { view } from '@risingstack/react-easy-state';
import { useDispatch, useSelector } from 'react-redux';
import { store } from 'react-notifications-component';
// import { Link } from 'react-router-dom';
import Container from '../container/container';
import BasketFooter from './basketFooter';
import SessionState from '../../stores/session';
import BasketState from '../../stores/basket';
import request from '../../helpers/request';
import PaymentIcons from '../../assets/payment_icons.png';
import AddCardToProfile from '../add-card-to-profile/add-card-to-profile';
import Register from '../login/Register';
import Login from '../login/Login';
import { useRef } from 'react';
import Errors from '../../helpers/errors';
import ProgressBar from '../../components/progress-bar/progress-bar';
import { getProduct, getProductTotalCost, getProductTotalDiscount } from './redux/selector';
import { setIsLoading } from './redux/action';
import CouponCode from '../coupon-code/coupon-code';
import SelectPaymentCard from '../select-payment-card/select-payment-card';

const currency = Intl.NumberFormat('en-GB', {
  style: 'currency',
  currency: 'GBP',
});

export default view(function BasketStep3({
  cart,
  setCart,
  back,
  next,
  history,
  setSummary,
  ...props
}) {
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.basket.isLoading);
  const [acceptTerms] = useState(true);
  const [purchaseInProgress, setPurchaseInProgress] = useState(false);
  const [note, setNote] = useState('');
  const [coupon, setCoupon] = useState(undefined);
  const [stage, setStage] = React.useState('login');
  const [error, setError] = useState();
  const [pm, setPm] = useState();
  const discount = useSelector((state) => getProductTotalDiscount(state));
  const totalCost = useSelector((state) => getProductTotalCost(state));


  const checkout = async () => {
    console.log('Using PaymentMethod', BasketState.paymentMethod);

    // stripe

    if (!acceptTerms) {
      return store.addNotification({
        title: 'Oops!',
        message: 'You must accept the terms and conditions before proceeding.',
        type: 'info',
        insert: 'bottom',
        container: 'bottom-center',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
    }

    if (!BasketState.paymentMethod) {
      return store.addNotification({
        title: 'Oops!',
        message: 'Please select a payment method.',
        type: 'info',
        insert: 'bottom',
        container: 'bottom-center',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
    }

    if (purchaseInProgress) {
      return store.addNotification({
        title: 'Oops!',
        message: 'Pump the breaks! A purchase is already in progress!',
        type: 'info',
        insert: 'bottom',
        container: 'bottom-center',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
    }

    setPurchaseInProgress(true);
    dispatch(setIsLoading(true));

    const basketItems = BasketState.parseToCartFormat();

    // console.log('Getting cart from API');

    await request(true).delete(`/cart`);

    // console.log(5);
    // console.log('Adding new items to cart');
    await request(true).post('/cart', basketItems);
    // console.log(8);

    // console.log('attempting payment');

    // let payment;
    try {
      request(true)
        .post('/cart/checkout', {
          paymentMethod: BasketState.paymentMethod,
          note,
          coupon,
        })
        .then((data) => {
          if (data.status === 200) {
            setSummary(data.data);
            setPurchaseInProgress(false);
            next();
            dispatch(setIsLoading(false));

            store.addNotification({
              title: 'Purchase Complete',
              message: 'Your order is complete, and your boxes are on the way!',
              type: 'success',
              insert: 'bottom',
              container: 'bottom-center',
              animationIn: ['animate__animated', 'animate__fadeIn'],
              animationOut: ['animate__animated', 'animate__fadeOut'],
              dismiss: {
                duration: 5000,
                onScreen: true,
              },
            });
          } else {
            setError(data.error);
          }
        })
        .catch((err) => {
          console.log('err', err, err.response, JSON.stringify(err));
          dispatch(setIsLoading(false));
          setPurchaseInProgress(false);
          if (err.response) {
            setError(err.response.data.error);
            return store.addNotification({
              title: 'Oops!',
              message: 'There was an issue processing your purchase.',
              type: 'info',
              insert: 'bottom',
              container: 'bottom-center',
              animationIn: ['animate__animated', 'animate__fadeIn'],
              animationOut: ['animate__animated', 'animate__fadeOut'],
              dismiss: {
                duration: 5000,
                onScreen: true,
              },
            });
          }
        });

      // console.log('payment success data', payment);
    } catch (error) {
      setPurchaseInProgress(false);
      dispatch(setIsLoading(false));
      // console.log('payment failed data', error);
      store.addNotification({
        title: 'Oops!',
        message:
          'There was an error while processing the payment, you were not charged.',
        type: 'error',
        insert: 'bottom',
        container: 'bottom-center',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
    }
    setPurchaseInProgress(false);
    // console.log('basketItems', basketItems);
  };

  const onNextPressed = () => {
    if (isLoading) return;
    dispatch(setIsLoading(true));
    if (!pm) {
      cardRef.current.click();
    } else {
      checkout();
    }
  };

  const cardRef = useRef();
  // const [isLoading, setIsLoading] = useState(false)

  return (
    <Container className="basket-3">
      <div className="basket-header">
        {cart && cart.length ? <ProgressBar currentStep={3} /> : ''}
        <h1 className="title-back-button">
          <button onClick={back} className="back">
            {t('basket.back')}
          </button>
          <span>{t('basket.checkout-payment')}</span>
        </h1>
      </div>
      <PaymentIcon />
      <PaymentOverview cart={cart} />
      {/* <SubscriptionOverview cart={cart} /> */}
      {/* <p className='payment-info'>
        For all subscription orders your first week’s payment is taken immediately, and subsequent payments
        will be taken the day before your order is dispatched. Please have
        enough funds in your account before each delivery.
      </p> */}
      {/* <OneTimeOrderOverview cart={cart} /> */}
      <div
        className="tsandcs"
        // style={{ alignSelf: 'center', maxWidth: 300, marginTop: 20 }}
      >
        <CouponCode onChange={setCoupon} />
      </div>
      
      <div className="overview-items discount-text">
        <div className="overview-price">
          <div className="overview-price__text">
            <p className="text title">Discount</p>
            <p className="text price">{`£ ${discount || 0}`}</p>
          </div>
          <div className="overview-price__text">
            <p className="text title green-text">Total</p>
            <p className="text price green-text">{`£ ${totalCost ? totalCost.toFixed(2) : 0}`}</p>
          </div>
        </div>
      </div>
      {SessionState.user ? (
        <>
          {/* <CardElements /> */}

          {/* <div className='tsandcs'> */}
          {/* <p style={{ textAlign: 'center' }}>
              Recurring payments are collected automatically.
            </p> */}
          <br />
          {/* <input
              onChange={e => setAcceptTerms(e.target.checked)}
              type='checkbox'
            />  &nbsp; */}
          {/* <label>By placing your order you agree to our{' '}
              <a href='/legal/terms' target='_blank'>
                terms and conditions
              </a>
              , privacy and returns policies. You also consent to some of your
              data being stored by Localo, which may be used to make future
              shopping experiences better for you.
            </label>
          </div> */}

          <br />

          <div className="tsandcs">
            <textarea
              placeholder="Order Note"
              rows={5}
              style={{
                width: '100%',
                resize: 'vertical',
                border: '2px solid var(--color-mint-green)',
              }}
              onChange={(e) => setNote(e.target.value)}
            ></textarea>
          </div>

          {error ? (
            <>
              <div
                className="tsandcs"
                style={{ alignSelf: 'center', maxWidth: 420 }}
              >
                <div
                  className="background-raspberry-sorbet color-white"
                  style={{ padding: '1em' }}
                >
                  <strong>There was an error!</strong>
                  <p>{Errors[error]}</p>
                </div>
              </div>
            </>
          ) : null}

          <div
            className="tsandcs"
            style={{ alignSelf: 'center', maxWidth: 300, marginTop: 20 }}
          >
            <SelectPaymentCard
              onChange={(_) => {
                setPm(_);
                BasketState.paymentMethod = _;
              }}
            />
          </div>

          <AddCardToProfile
            ref={cardRef}
            onChange={(val) => {
              BasketState.paymentMethod = val;
              checkout();
            }}
            // setIsLoading={setIsLoading}
          />

          <PaymentButtons
            back={back}
            backText="Cancel"
            next={onNextPressed}
            nextText={
              isLoading ? 'Please wait - processing payment' : 'Pay Now'
            }
            // nextText='Pay Now'
          />
        </>
      ) : (
        <>
          {/* <div className='tsandcs'> */}
          {/* <Link to='/login?redirect_uri=/basket?step=3'>
                <button className='button'>Login or Register to checkout</button>
              </Link> */}
          {stage === 'login' || stage === 'forgotten' ? (
            <Login
              redirectUri={null}
              fromBasket={true}
              shouldNotRedirect={true}
              loginOrRegister={stage}
              setLoginOrRegister={setStage}
            />
          ) : null}
          {stage === 'register' ? (
            <Register
              redirectUri={null}
              fromBasket={true}
              shouldNotRedirect={true}
              history={history}
              setLoginOrRegister={setStage}
            />
          ) : null}
          {/* </div> */}
        </>
      )}

      <p className="payment-info">
        For all subscription orders your first week’s payment is taken
        immediately, and subsequent payments will be taken the day before your
        order is dispatched. Please have enough funds in your account before
        each delivery.
      </p>

      <div className="payment-info bottomSpace">
        {/* <p style={{ textAlign: 'center' }}>
              Recurring payments are collected automatically.
            </p> */}
        <br />
        {/* <input
              onChange={e => setAcceptTerms(e.target.checked)}
              type='checkbox'
            />  &nbsp; */}
        <label>
          By placing your order you agree to our{' '}
          <a href="/legal/terms" target="_blank">
            terms and conditions
          </a>
          , privacy and returns policies. You also consent to some of your data
          being stored by Localo, which may be used to make future shopping
          experiences better for you.
        </label>
      </div>

      <br />

      <div className="not-on-laptop">
        <BasketFooter back={back} nextText={null} />
      </div>
    </Container>
  );
});

// const CardElements = () => {
//   return (<div>
//     <div>
//       <p>Card Number</p>
//       <CardNumberElement />
//     </div>
//     <div style={{display: 'flex'}}>
//       <div>
//         <p>Expiration</p>
//         <CardExpiryElement />
//       </div>
//       <div>
//         <p>CVC</p>
//         <CardCvcElement />
//       </div>
//     </div>
//   </div>)
// }

const PaymentOverview = ({ cart }) => {
  return (
    <div className="overview-items">
      {cart.map((i, k) => {
        return <PaymentOverviewItem item={i} key={`cart-item-${k}`} />;
      })}
    </div>
  );
};

const PaymentOverviewItem = ({ item }) => {
  // console.log(item);
  const product = useSelector((state) => getProduct(state, item.id));
  const price = product[0]?.cost || 0;

  const monthlyVolume = React.useMemo(() => {
    if (item.frequency === 'Weekly') {
      return item.quantity * 4;
    } else {
      return item.quantity;

      // Both Monthly and "Just Once" work the same here.
    }
  }, [item]);

  const calcedPrice = React.useMemo(() => {
    const p = price;
    return currency.format(p);
  }, [price]);

  return (
    <div className="overview-item">
      <div className="product">
        <p>{item.raw.product.name}</p>
        <p className="price">
          {calcedPrice} x {monthlyVolume}
        </p>
      </div>
      <p className="variant">
        {item.quantity} x {item.raw.variant.title} box{' '}
        {item.frequency.toLowerCase()}.
      </p>
    </div>
  );
};

// const SubscriptionOverview = ({ cart }) => {
//   const weeks = React.useMemo(() => {
//     let weeks = { week1: 0, week2: 0, week3: 0, week4: 0 };

//     cart.forEach(item => {
//       if (item.frequency === 'Weekly') {
//         weeks.week1 = weeks.week1 + item.raw.variant.price * item.quantity;
//         weeks.week2 = weeks.week2 + item.raw.variant.price * item.quantity;
//         weeks.week3 = weeks.week3 + item.raw.variant.price * item.quantity;
//         weeks.week4 = weeks.week4 + item.raw.variant.price * item.quantity;
//       }

//       if (item.frequency === 'Monthly') {
//         item.slots.forEach(slot => {
//           if (slot.week === 1) {
//             weeks.week1 = weeks.week1 + item.raw.variant.price;
//           }

//           if (slot.week === 2) {
//             weeks.week2 = weeks.week2 + item.raw.variant.price;
//           }

//           if (slot.week === 3) {
//             weeks.week3 = weeks.week3 + item.raw.variant.price;
//           }

//           if (slot.week === 4) {
//             weeks.week4 = weeks.week4 + item.raw.variant.price;
//           }
//         });
//       }
//     });

//     return weeks;
//   }, [cart]);

//   return (
//     <div className='weekly-totals'>
//       <h2>Your weekly subscription totals</h2>
//       <div className='weeks'>
//         <div className='week'>
//           <p>Week 1</p>
//           <p>{currency.format(weeks.week1)}</p>
//         </div>
//         <div className='week'>
//           <p>Week 2</p>
//           <p>{currency.format(weeks.week2)}</p>
//         </div>
//         <div className='week'>
//           <p>Week 3</p>
//           <p>{currency.format(weeks.week3)}</p>
//         </div>
//         <div className='week'>
//           <p>Week 4</p>
//           <p>{currency.format(weeks.week4)}</p>
//         </div>
//       </div>
//     </div>
//   );
// };

const PaymentIcon = () => {
  return (
    <div className="payment-icons-container">
      <img src={PaymentIcons} alt="localo_payment_icons" />
    </div>
  );
};

// const OneTimeOrderOverview = ({ cart }) => {
//   const [boxCount, setBoxCount] = React.useState(0);

//   const boxes = React.useMemo(() => {
//     let count = 0;

//     return (
//       <>
//         {cart.map((i, k) => {
//           if (i.frequency === 'Once') {
//             count++;
//             return (
//               <div className='box' key={`one-time-${k}`}>
//                 <p>{i.raw.product.name}</p>
//                 <p>{currency.format(i.raw.variant.price * i.quantity)}</p>
//               </div>
//             );
//           }

//           return null;
//         })}
//         {setBoxCount(count)}
//       </>
//     );
//   }, [cart]);

//   return (
//     <>
//       {boxCount > 0 ? (
//         <div className='one-time'>
//           <h2>Your one-time orders</h2>
//           <div className='boxes'>{boxes}</div>
//         </div>
//       ) : (
//           ''
//         )}
//     </>
//   );
// };

function PaymentButtons({ back, next, nextText, backText }) {
  const { t } = useTranslation('common');
  return (
    <div className="payment__buttons">
      {/* <div className='back' onClick={back}>
        {backText ? backText : t('basket.cancel')}
      </div> */}
      {nextText !== null ? (
        <div className="next" onClick={next}>
          {nextText ? nextText : t('basket.next-step')}
        </div>
      ) : null}
    </div>
  );
}
