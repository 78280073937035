import React, { useEffect, useState } from 'react';
import Container from '../container/container';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Request from '../../helpers/request';

import './promotion.scss';

const key = 'Product_index_promo';
const PromotionCard = ({ title, image }) => {
  return (
    <div className="promotion__card">
      <div className="promotion__img">
        <img src={image?.url} alt="Ad or promo could go here" />
      </div>
      <div className="promotion__textHolder">
        <div className="promotion__text">{title}</div>
      </div>
    </div>
  );
};

export default function Promotion() {
  const [promotion, setPromotion] = useState([]);

  useEffect(() => {
    Request(false)
      .get(`promotions/placement?key=${key}`)
      .then((res) => {
        if (res.data) {
          const list = res.data.data;
          setPromotion(list);
        }
      });
  }, []);

  const sliderSettings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 1,
  };

  return (
    <div className="promotion">
      <Container>
        <Slider {...sliderSettings} className="promotion__carousel">
          {promotion.map((item) => {
            return React.Children.toArray(<PromotionCard {...item} />);
          })}
        </Slider>
      </Container>
    </div>
  );
}
