const { default: Request } = require("./request")

const PriceProduct = (product, variant, customisation) => {
  // console.log('PRODUCT', product)
  // console.log('VARIANT', variant)
  // console.log('CUSTOMISATION', customisation)
  return Request(false)
  .post('/helpers/product-price', {
    product: product,
    variant: variant,
    options: customisation && customisation.length > 0 ? customisation.map((c) => {
      return {
        value: variant.customisation
        .find((a) => a._id === c.option)
        .options.find((a) => a._id === c.value)
      }
    }) : [],
  })
}

module.exports = PriceProduct