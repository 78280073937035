import React, { useEffect } from 'react'
import Session from '../../stores/session';
// import { useLocation } from 'react-router-dom'
import Container from '../container/container';
import TopComponent from '../top-component/top-component';

const ProductRow = ({ product, variant, options, subscription }) => {
  // const optionsCost = useMemo(() => {
  //   if (!options) return 0;
  //   return options.reduce((p, c) => p+=c.value.priceModifier, 0)
  // }, [options])
  return (
    <div style={{ display: 'grid', gridTemplateColumns: 'auto max-content' }}>
      <div>
        <p style={{fontSize: 20, fontWeight: 700, color: '#8C8C8C'}}>{product.name}</p>
        <p style={{fontSize: 16, color: '#8C8C8C'}}>A box for {variant.title} { subscription ? `1 time ${subscription.interval} ${ subscription.interval === 'Monthly' ? `(${subscription.week} of the month on ${subscription.day} between ${subscription.time})` : ''}` : null } </p>
        <br />
        <div>
          {/* { options ? <p style={{fontSize: 16, color: '#8C8C8C'}}>Customisation Options:</p> : null } */}
          {
            options ? options.map(op => {
              return (<p style={{fontSize: 16, color: '#8C8C8C'}}>
                {op.option.title}: {op.value.name} (£{op.value.priceModifier.toFixed(2)})
              </p>)
            }) : null
          }
        </div>
      </div>
      {/* <div>
        <p style={{fontWeight: 700, fontSize: 20, color: '#8C8C8C'}}>£{((variant.price * (1-variant.discountDecimal)) + optionsCost).toFixed(2)}</p>
      </div> */}
    </div>
  )
}

export default function BasketComplete({ summary, history }) {
  // const location = useLocation();

  // const calculateTotalForDelivery = (del) => {
  //   const toReturn = {
  //     subtotal: 0,
  //     total: 0,
  //     delivery: 0,
  //     discount: 0,
  //     bagFee: 0,
  //   }
  //   const o = []
  //   del.forEach(d => {
  //     // console.log(d)
  //     d.orders.forEach(_ => {
  //       // console.log(`Is ${_._id} in array?`, o)
  //       if (o.findIndex(__ => __.order._id === _.order._id) === -1) o.push(_)
  //     })
  //     // console.log('Done', o)
  //     // console.log(d._id, toReturn)
  //   })
  //   toReturn.subtotal += o.reduce((p, c) => p + c.order.price.subtotal, 0);
  //   toReturn.total += o.reduce((p, c) => p + c.order.price.total, 0);
  //   toReturn.delivery += o.reduce((p, c) => p + c.order.price.delivery, 0);
  //   toReturn.discount += o.reduce((p, c) => p + c.order.price.discount, 0);
  //   toReturn.bagFee += o.reduce((p, c) => p + c.order.price.bagFee, 0);
  //   return toReturn
  // }

  useEffect(() => {
    window.gtag('event', 'conversion', {
      'send_to': 'AW-491308172/iWMFCNPsjYoCEIyJo-oB',
      'value': summary.price.total,
      'currency': 'GBP',
      'transaction_id': `${Session.user._id}_${new Date().toString()}`,
      'source': 'web'
    });
  }, [summary.price.total])

  return (
    <div>
      <TopComponent />

      {
        summary && summary.deliveries && summary.deliveries.length ? <>
          <Container className="container-component-small">
            <p className="purple-heading" style={{textAlign: 'center'}}>Lastly - order confirmation!</p>
            <br />
            <br />
            <br />
            <p style={{color: '#8C8C8C'}}>Thanks, we have received your payment!<br /><br /> We’ll also email you a summary of your orders for future reference.</p>
            <br />
            <br />
            <br />
            <p className="purple-heading" style={{textAlign: 'center'}}>Here are your orders</p>
            <br />
            <br />
            <br />
            {
              summary.deliveries.map(s => {
                return (
                  <div>
                    {/* <p style={{ fontSize: 14, color: '#8C8C8C' }}>Arriving: {s.day} ({s.timeSlot})</p> */}
                    <div>
                      {
                        s.orders.map(o => {
                          return (<div>
                            <p style={{ fontSize: 14, color: '#8C8C8C' }}>Order ID: #{o.order._id.substr(-6).toUpperCase()}</p>
                            <div>
                              {
                                o.items.map(i => {
                                  return (
                                    <ProductRow product={i.product} variant={i.variant} options={i.options} />
                                  )
                                })
                              }
                              <br />
                              <div>
                                  {/* <p>Total: £{o.order.price.total}</p> */}
                              </div>
                            </div>
                          </div>)
                        })
                      }
                    </div>
                  </div>
                )
              })
            }
              <br />
              <div style={{display: 'flex', justifyContent: 'flex-end', textAlign: 'center', gap: '2em', color: '#8C8C8C'}}>
                {/* <div style={{textAlign: 'center'}}>
                  <p>£{calculateTotalForDelivery(summary.deliveries).discount.toFixed(2)}</p>
                  <p style={{fontWeight: 700}}>You saved</p>
                </div> */}
                {/* <div>
                  <p style={{fontWeight: 700, fontSize: 20}}>£{calculateTotalForDelivery(summary.deliveries).subtotal.toFixed(2)}</p>
                  <p style={{}}>Subtotal</p>
                </div>
                <div>
                  <p style={{fontWeight: 700, fontSize: 20}}>£{(calculateTotalForDelivery(summary.deliveries).delivery).toFixed(2)}</p>
                  <p style={{}}>Delivery</p>
                </div> */}
                <div>
                  <p style={{fontSize: 20, fontWeight: 700}}>£{summary.price.total.toFixed(2)}</p>
                  <p style={{}}>Total</p>
                </div>
              </div>
            {
              summary && summary.subscriptions && summary.subscriptions.length ? <>
                <br />
                <br />
                <br />
                <p className="purple-heading" style={{textAlign: 'center'}}>Your subscriptions</p>
                <p style={{fontSize: 14, color: '#8C8C8C', textAlign: 'center'}}>Payment for your subscriptions will be taken the week of delivery.</p>
                <br />
                <br />
                <br />
                <div>
                  {
                    summary.subscriptions.map(sub => {
                      return (<div>
                        <ProductRow product={sub.product} variant={sub.variant} subscription={sub} />
                        </div>)
                    })
                  }
                </div>
              </> : null
            }
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
          </Container>
        </> : null
      }
      
    </div>
  )
}
