import React, { useEffect, useState } from 'react'
import Request from '../../../helpers/request'
import ReactModal from 'react-modal'
import AddCardToProfile from '../../../components/add-card-to-profile/add-card-to-profile'
import { Elements, } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import Style from '../BasicDetails/BasicDetails.module.scss'

export const PaymentDetailsList = () => {
    const [showModal, setShowModal] = useState(false)
    const [stripe] = useState(() => loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY))
    const [cards, setCards] = useState(null)

    const getData = () => {
        Request(true).get('/self/cards').then(e => setCards(e.data))
    }

    useEffect(() => {
        getData();
    }, [])

    return [
        cards ? <div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
            {
                cards.cards.map(card => {
                    return (
                        <Card key={`user-card-${card.id}`} defaultCard={cards.default} card={card} refresh={getData} />
                    )
                })
            }
        </div> : null,
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', gap: 7}}>
            <button onClick={() => setShowModal(true)} className="button button-green-solid button-view-basket">Add new card</button>
        </div>,
        <ReactModal overlayClassName={Style['custom-height']} isOpen={showModal} onRequestClose={() => setShowModal(false)}>
        <Elements stripe={stripe}>
            <AddCardToProfile showButton={true} onChange={() => {getData();setShowModal(false)}} />
        </Elements>
        </ReactModal>
    ]
}

const Card = ({card, refresh, defaultCard}) => {
    const deleteCard = () => {
        Request(true).delete(`/self/cards/${card.id}`).then(e => {
            refresh()
        }).catch(err => {
            window.alert(err.response.data)
        })
    }
    const makeDefault = () => {
        Request(true).post(`/self/cards/${card.id}/default`).then(e => {
            window.alert('Default payment method updated.')
            refresh()
        }).catch(err => {
            window.alert('Failed to update default payment method.')
        })
    }
    return (
        <div style={{display: 'grid', gridTemplateColumns: '35px auto min-content'}}>
            {/* <div></div> */}
            <td style={{display: 'grid', justifyContent: 'center', alignContent: 'center'}}>
                {
                card.id === defaultCard ? <>
                    <div key={`card-${card.id}-default`} className="background-mint-green" style={{width: 25, height: 25, borderRadius: '50%'}}></div>
                    {/* <p>Default</p> */}
                </> : <>
                    <div key={`card-${card.id}-not-default`} onClick={makeDefault} className="color-mint-green-outline" style={{width: 25, height: 25, borderRadius: '50%'}}></div>
                </>}
            </td>
            <div>
                <h3>Card ending {card.card.last4} <span className={`fab fa-cc-${card.card.brand}`}></span></h3>
                <p>Expires {card.card.exp_month}/{card.card.exp_year}</p>
                <small style={{color: '#eee'}}>{card.id}</small>
            </div>
            <div>
                <button className="button button-view-basket" onClick={deleteCard}>Delete</button>
            </div>
        </div>
    )
}