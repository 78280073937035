import React from 'react'
import useViewport from '../../hooks/useViewport';
import ShopProductCustomisation from '../shop-product-customisation/shop-product-customisation'
import ReactModal from 'react-modal';

export default function ProductCustomisationModal({
  showModal,
  toggleModal,
  customisation,
  setCustomisation,
  variant
}) {
  const { width } = useViewport();
  return (
    <ReactModal
      isOpen={showModal}
      style={{
        content: {
          width: width < 700 ? '80%' : '500px',
          height: width < 700 ? '65vh' : '70vh',
          top: '50%',
          left: '50%',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
        },
      }}
      onRequestClose={() => toggleModal(false)}
    >
      <ShopProductCustomisation
        customisation={customisation}
        setCustomisation={setCustomisation}
        variant={variant}
        closeModal={toggleModal}
      />
    </ReactModal>
  )
}
