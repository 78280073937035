import React, { useEffect, useState } from 'react';
import Request from '../../helpers/request';

import ProductReel from '../product-reel/product-reel';

export default function FeaturedBoxes({ postcode }) {
  const [featuredBoxes, setFeaturedBoxes] = useState([]);

  useEffect(() => {
    fetchFeaturedBoxes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchFeaturedBoxes = () => {
    Request(false)
      .get(`/products?postcode=${postcode}&featured=1`)
      .then((res) => {
        if (res.data) {
          const list = res.data.data;
          setFeaturedBoxes(list);
        }
      });
  };

  return (
    <>
      <ProductReel
        postcode={postcode}
        key={'featuredBoxs'}
        name="Featured boxes"
        list={featuredBoxes}
      />
    </>
  );
}
