import React from 'react';
import ReactDOM from 'react-dom';
import Router from './router';
import 'promise-polyfill/src/polyfill';
import ReactNotification, { store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';

import './scss/main.scss';

import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';

import { Provider } from 'react-redux';

import common_en from './translations/en/common.json';
import reduxStore from './redux/store';

window.alert = (message) => {
  store.addNotification({
    title: 'Notification',
    message: message,
    type: 'info',
    insert: 'top',
    container: 'top-center',
    animationIn: ['animate__animated', 'animate__fadeIn'],
    animationOut: ['animate__animated', 'animate__fadeOut'],
    dismiss: {
      duration: 5000,
      onScreen: true,
    },
  });
};

require('es6-object-assign').polyfill();

i18next.init({
  interpolation: {
    escapeValue: false,
  },
  lng: 'en',
  resources: {
    en: {
      common: common_en,
    },
  },
});

ReactDOM.render(
  <Provider store={reduxStore}>
    <React.StrictMode>
      <I18nextProvider i18n={i18next}>
        <ReactNotification />
        <Router />
      </I18nextProvider>
    </React.StrictMode>
  </Provider>,
  document.getElementById('root')
);
