import React from 'react';
// import { useTranslation } from 'react-i18next';
import Container from '../container/container';
import './login-register.scss';
import Request from '../../helpers/request';
import Session from '../../stores/session';
import { useHistory } from 'react-router-dom';
// import StickyLogin from './sticky-login';

export default function Login ({ withoutBottomLinks, loginOrRegister, redirectUri, setLoginOrRegister, back, fromBasket, shouldNotRedirect, ...props })
{
    // const { t } = useTranslation('common');

    const [error, setError] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [message, setMessage]  = React.useState('');

    const History = useHistory();

    const submitLoginRequest = React.useCallback((ev) => {
        ev.preventDefault();
        
        Request(false).post('/auth/login', {
            email: email,
            password: password
        }).then(r => {
            localStorage.setItem('user-token', r.data.token);
            Session.token = r.data.token;
            Session.fetch();
            if(!shouldNotRedirect)
            History.push(redirectUri || '/');
        }).catch(e => {
            // console.log(e.response);
            if (e.response.data.error === "INVALID_CREDENTIALS") {
                setError("The specified email or password was incorrect.");
                setPassword("");
            }
        });
    }, [email, password, History, shouldNotRedirect, redirectUri]);

    const submitResetRequest = React.useCallback((ev) => {
        ev.preventDefault();

        Request(false).get('/auth/passwordreset', {
            params: {
                email: email
            }
        }).then(r => {
            setMessage("If an account exists with this email then a password reset request has been sent.")
        }).catch(e => {
            console.error(e);
            console.error(e.response);
        });
    }, [email]);

    return (
        <Container>
            <div className="login-or-register">
                { loginOrRegister === "login" ?
                <>
                    {!fromBasket ? (
                        <h1>Log In</h1>
                    ) : ''}
                    <form onSubmit={submitLoginRequest}>
                        <input type="email" name="email" placeholder="Email address" value={email} onChange={ ev => setEmail(ev.target.value) }/>
                        <input type="password" name="password" placeholder="Password" value={password} onChange={ ev => setPassword(ev.target.value) }/>

                        { error ? <p className="error">
                            { error }
                        </p> : '' }

                        { message ? <p className="message">
                            { message }
                        </p> : '' }

                        <input type="submit" value="Log in"/>

                        <p className="forgotten" onClick={ () => { setLoginOrRegister('forgotten'); setMessage(''); setError(''); } }>
                            Forgotten Password
                        </p>
                            
                        {!withoutBottomLinks && <p className="register-label">New to Localo?</p>}
                        {!withoutBottomLinks && <div className='create-acc-button'>
                            <button onClick={ () => { setLoginOrRegister('register'); setMessage(''); setError(''); } }>
                            Create account
                            </button>
                        </div>}
                    </form>
                </>
                : 
                <>
                    {!fromBasket ? (<h1>Forgotten Password</h1>) : ''}
                    <form onSubmit={submitResetRequest}>
                        <input type="email" name="email" placeholder="Email address" value={email} onChange={ ev => setEmail(ev.target.value) }/>

                        { error ? <p className="error">
                            { error }
                        </p> : '' }

                        { message ? <p className="message">
                            { message }
                        </p> : '' }

                        <input type="submit" value="Reset Password"/>

                        <p className="forgotten" onClick={ () => { setLoginOrRegister('login'); setMessage(''); setError(''); } }>
                            Log in instead
                        </p>
                        {!withoutBottomLinks && <p className="register" onClick={ () => { setLoginOrRegister('register'); setMessage(''); setError(''); } }>
                            Create an account
                        </p>}
                    </form>
                </>
                }
            </div>
            {/* <div className="not-on-laptop">
                <StickyLogin back={() => History.goBack()} register={() => { setLoginOrRegister('register');}} />
            </div> */}
        </Container>
    )
}
