import React from "react";
import Page from "../components/page/page";
import ProductBack from "../components/product-back/product-back";
import ProductNavigator from "../components/product-navigator/product-navigator";
import ProductReel from "../components/product-reel/product-reel";
import ProductView from "../components/product-view/product-view";
import Promotion from "../components/promotion/promotion";
import TopComponent from "../components/top-component/top-component";

export default function ProductsListing() {
  return (
    <Page className="products-listing">
      <TopComponent>
        <ProductBack />
      </TopComponent>
      <ProductNavigator />
      <Promotion />
      <ProductReel name="Featured boxes" />
      <ProductReel name="Special offers" />
      <ProductReel name="Fruit & veg boxes" />
      <ProductReel name="Bakery boxes" />
      <ProductView />
    </Page>
  );
}
