import React from 'react'
import Page from '../../components/page/page';
import LegalPage from '../../components/legal-page/legal-page';
import TitleCard from '../../components/title-card/TitleCard';
import cardBackground from '../../assets/privacy-policy-banner/bg-banner.png';
import MetaDecorator from '../../hooks/metaDecorator';

const subtext = [
    'Welcome to Localo. This page (together with the documents referred to in it) tells you the terms (the “Terms”) which apply when you order any menu items (the "Items") from our www.shoplocalo.com website or mobile applications and related services (each referred to as an “Application”).',
    <br />, <br />,
    'Please read these Terms carefully before creating a Localo account or using our Application. If you have any questions relating to these Terms please contact team@shoplocalo.com. If you are a consumer when you order items using our Application you have certain legal rights. More on these rights can be found at https:/www.citizensadvice.org.uk/consumer/. These Terms do not affect your legal rights, which apply alongside and do not replace them. By creating your Localo account you confirm your acceptance of these Terms.'
];

const headers = [
    '1. Information About Us',
    '2. Purpose',
    '3. Your Account',
    '4. Service Availability',
    '5. Orders',
    '6. Delivery',
    '7. Your Rights if Something is Wrong with Your Items',
    '8. Your status',
    '9. Cancellation',
    '10. Prices, Payment and Offers',
    '11. Our Responsibility for Loss or Damage That You Suffer',
    '12. Data Protection',
    '13. Customer Care',
    '14. Disclaimers',
    '15. Liability',
    '16. Events Outside Our Control',
    '17. Other Terms'
];

const markdown = [
    `# 1. Information About Us

www.shoplocalo.com is operated by Localo Ltd, a company incorporated and registered in Scotland, whose registered office is at Atlantic Chambers, 6th floor 45 Hope Street, Glasgow G2 6AE, United Kingdom. Our Company registration number is SC669324. You may contact us at team@shoplocalo.com, or by using the contact facility on our site.
`,

    `# 2. Purpose

We provide a way for you to communicate your orders (“Order” or "Orders") for products (“Boxes” or "Products") to shops in the UK (“Sellers” or " Sellers") displayed on the Website. The legal contract for the supply and purchase of Products is between you and the Seller that you place your Order with and we will conclude the sale of Products on behalf of, and as commercial agent for, the Seller in all cases. Once you have placed an order, delivery of your Items will be arranged by Localo (“Localo Delivery”). 
`,

    `# 3. Your Account

1. Opening an account

Before you can place orders for Items using our Application, you need to open a Localo account. When you open an account you may create a password, or other secure login methods, and may also have to provide credit card details. You must keep any password you create, or other secure login methods, secret, and prevent others from accessing your email account or mobile phone. If another person uses these methods to access your account, you will be responsible to pay for any items they order, and we are not responsible for any other losses you suffer unless the person using your password obtained it because we did not keep it secure.

2. Closing your account

You may close your account at any time by requesting to do so in your account section of our website or contacting us using the contact details above. We may suspend your access to your account, or close it permanently if we believe that your account has been used by someone else. We may also close your account if in our opinion you are abusing our Service (for example, by applying for refunds or credit to which we do not consider you are entitled, making repeated unreasonable complaints, mistreating our staff or delivery team, or any other good reason). If we close your account permanently, we will refund any remaining credits you have validly received from our customer service team or Application regarding any problem with an order, by crediting your registered credit card or, if this is not possible for any reason, by bank transfer using bank details (provided that you have provided them to us).
`,

    `# 4. Service Availability

Each Seller has a prescribed delivery area. Our Sellers each decide their own operating hours. If you try to order a delivery to a location outside the delivery area of a Seller, or the Application is otherwise unavailable for any reason, we will notify you that ordering will not be possible.
`,

    `# 5. Orders

5.1. Compiling your Order: Once you have selected the Products you wish to order from the menu of your chosen Seller and provided the other required information, you will be given the opportunity to request your Order by clicking or selecting the "proceed", "place my order" or similar button. Before clicking or selecting this button, you must check all the information you enter and correct any errors; once you do so you will enter into a contract with the Seller and errors cannot be corrected (subject to paragraph 5.2 below).

5.2. Changing or cancelling your Order: After you have submitted your Order and your payment has been approved, you will not be entitled to modify or cancel your Order, nor will you be entitled to reimbursement (see paragraphs 4.4 and 5.6 for specifics of the rejected Orders and reimbursement process). If you wish to change or cancel your order, you may contact our Customer Care team as described in paragraph 6.3 and they will attempt to contact the Seller in order to communicate your requests. However, there is no guarantee that we will be able to reach the Seller or that the Seller will agree to your requests as they may have already started processing your Order.

5.3. Payment authorisation: Where any payment you make is not authorised, your Order will not be processed or communicated to the relevant Seller.

5.4. Processing your Order and Seller rejections: On receipt of your order, we will send it to the relevant Seller and will notify you by email that your order has been received and is being processed. Please keep note that any confirmation page you might see on the Website and any Order confirmation e-mail that you may receive each validate that you have a contract with a Seller for the sale of Goods but does not necessarily mean that the Seller fulfils your order. We encourage all our Sellers to accept all Orders and to communicate any rejection promptly, and we will notify you (generally by email) as soon as reasonably practicable if a Seller rejects your Order. However, Sellers have the ability to reject Orders at any time because they are too busy, due to weather conditions or for any other reason. In doing so, Sellers will be in breach of their agreement with you and any payment made in respect of the order will be returned to you in accordance with paragraph 5.6 below.

5.5 All Items are subject to availability. Sellers may use nuts or other allergens in the preparation of certain Items. Increasing numbers of Sellers will be displaying allergens information. Where that information is not available or if you have further questions, please contact the Seller prior to ordering if you have an allergy. Localo cannot guarantee that any of the items sold by our Sellers are free of allergens.
`,

    ` # 6. Delivery

1. Type of order

When you place an order you will have the choice to place it as a one-off order or a Scheduled Delivery. For a one-off Delivery, you can choose a delivery time for your item before you place the order, you must therefore be available to accept delivery from the time you place the order. For a Scheduled Delivery, you can choose a delivery time for your item to be delivered on a recurring basis that you specify. For either delivery option, you must be available to accept delivery for ten minutes before and ten minutes after that time slot.

2. Delivery problems

Unfortunately, despite our, and our Seller’s best efforts, things do not always go to plan and factors such as traffic and weather conditions may prevent us from delivering your box Item on time. If your order is more than 15 minutes late and we haven't informed you of the opportunity to cancel your order, we'll work with you to make it right unless you've caused the delay (e.g. you've given us the wrong address or you haven't answered the door when we came to deliver).

We will attempt delivery at the address you provide to us when you place your order. If you need to change the delivery location after placing your order, we might be able to deliver to an alternate address registered with your account if you let us know before the delivery team dispatches your item and the new address is within the same zone as the address you originally ordered your Item to. If we cannot change the delivery address, you have the option to cancel the order, but if box preparation has started you will be charged the full price for the Item, and if the Delivery Team has been despatched you will also be charged for delivery.

3. Failed deliveries

You will still be charged for the Item and for delivery in the event of a failed delivery if you have caused such failure for any reason. Reasons you might cause a delivery to fail include (but are not limited to): 

- You do not come to the door, did not pick up the phone when the Delivery Team contacted you using the contact information you have provided us and/or you picked up the phone but then failed to provide access within a reasonable amount of time, and the Delivery Team is unable to find a safe location to leave the box.
- The Delivery Team refuses to deliver the item to you in accordance with section 8 (Age Restricted Products).
`,

    ` # 7. Your Rights if Something is Wrong with Your Items

Customer satisfaction

You have the legal right to receive products that comply with their description, that is of acceptable quality and that comply with any reasonable conditions that you may offer us (and we agree to) before placing your order. If you feel that the products you have been supplied do not comply with these legal rights, please let us know. We can ask for a photograph to show what the issue is. The Seller will provide a refund or account credit in respect of the affected part of the Item, and even with regard to shipping if the whole order has been affected unless we have a fair cause to assume that the issue has occurred after delivery. Before processing your refund or credit account, we may take into account relevant factors, including details of the order, including your account history, what happened on delivery and information from the Seller.
`,

    ` # 8. Your status

1. Capacity and age: By placing an Order through the Website, you warrant that:
    - You are legally capable of entering into binding contracts with Sellers; and
    - You are at least 18 years old.
2. You acknowledge and agree that if you have a specific food allergy or intolerance, you will contact the Seller directly to check that the food is suitable for you, before placing your order directly with them.
3. Age-restricted products
    - Age-restricted products (including, without limitation, alcohol, tobacco and cigarettes) can only be sold and delivered to persons aged 18 or over. 
    - By placing an order for an age-restricted product, you confirm that you are at least 18 years old. Localo operates an age verification policy whereby customers ordering age-restricted products will be asked by the Delivery Team to provide proof that they are aged 18 or over before the delivery is completed. The Delivery Team may refuse to deliver any age-restricted product to any person unless they can provide valid photo ID proving that they are aged 18 or over. 
    - The Seller and the Delivery Team may refuse to deliver alcohol to any person who is, or appears to be under the influence of either alcohol or drugs. If delivery of any age-restricted product is refused, you will still be charged for the relevant Item and for delivery.
`,

    ` # 9. Cancellation

1. Cancelling an order

You may cancel an order without charge at any time before the Seller has started preparing the box (a "Started Order"). If you wish to cancel an order before it becomes a Started Order, please contact us immediately, via our Application. If the Seller confirms the order was not a Started Order, we will refund your payment (excluding any discount, or Voucher that was applied to the order - see Voucher and Account Credit Terms for more detail). If you cancel any order after it becomes a Started Order, you will be charged the full price for the Items, and if the Delivery Team has been dispatched you will also be charged for delivery. 

2. Cancellation of an Order

Localo and the Seller may notify you that an order has been cancelled at any time. You will not be charged for any orders cancelled by us or the Seller, and we will reimburse you for any payment already made using the same method you used to pay for your order. We may also add credit to your account to represent the inconvenience caused.
`,

    ` # 10. Prices, Payment and Offers

1. VAT and delivery fees
Prices include VAT and delivery fees. You confirm that you are using our Service for personal, non-commercial use. Prices can also change at any time at the discretion of the Sellers. We reserve the right to charge a Service Fee, which may be subject to change, for the provision of our Services. You will be informed of any relevant service fees and taxes prior to payment on the checkout page of our Application. No changes will impact current reported orders unless there is a significant error in pricing. Nor will price increases impact any orders in production and appearing within your basket, given that you complete the order within 2 hours of adding items to your basket. If you do not complete the order before the 2-hour cut-off deadline, the items will be removed from your shopping cart automatically and the price adjustment will apply.
2. Pricing
The overall price of your order will be displayed on the checkout page of our Website, including the prices of Goods and Delivery and the related Service Fees and Taxes.
Sellers sometimes make special offers available through our Application. These are visible when you look at a Seller’s store. These offers are at the discretion of the Sellers. Unless the terms of the offer specify a fixed or minimum duration for which the offer will be made available, it may be withdrawn at any time unless you have already placed an order on the basis of the offer and we have sent a confirmation note.
3. Incorrect pricing
This website includes a large number of menus and it is likely that some of the menus will contain incorrect prices. If the correct price for an order is higher than the price listed on the website, we will usually notify you before the actual order is delivered. In such an event, neither we nor the relevant Seller is under any obligation to ensure that the Order is provided to you at the incorrect lower price or to compensate you in respect of incorrect pricing. If there is a significant price error, we will contact you as soon as possible and you will have the option of approving the order at the original price or cancelling the order without penalty and with the complete refund of any money already spent.
4. Payments
Payment for all Items and deliveries can be made on our Application by credit or debit card, or other payment method made available by Localo. Upon confirmation of your order, your credit or debit card will be approved and the total sum will be marked for payment. Payment shall be made directly to the Seller.
5. Rejected Orders
As a result of standard banking procedures, once you have submitted an Order that you pay for by credit or debit card and your payment has been approved, your bank or card issuer might "ring-fence" the full amount of your Order. If your Order is subsequently rejected by the Seller (as described in paragraph 5.4 above) ) or cancelled for some other reason, your bank or card issuer will not pass the Order's funds to us, but instead refund the appropriate amount back into your balance. However, this may take between 5 to 7 working days (or longer, depending on your bank or card issuer). You acknowledge and agree that neither we nor the relevant Seller will be responsible or liable to you in relation to this delay by your bank or card issuer in the release of funds back into your account.
`,

    ` # 11. Our Responsibility for Loss or Damage That You Suffer

We are liable to you for any loss or harm you incur as a result of our violation of these Terms or the failure to exercise fair care and ability with respect to your use of our Service. We are not liable for any loss or harm which cannot be foreseen. Loss or harm is "foreseeable" whether it is either apparent that it is going to happen, or if you told us that it would happen, for example, If you inform us about any particular circumstances that may increase the loss or damage resulting from our breach of these Terms before you place an order.

We may not exclude or limit our liability to you for loss or harm where it would be illegal to do so. This includes any responsibility for death or personal injury caused by our failure, or our employees’, agents’ or subcontractors’ failure, to use reasonable care and skill; for fraud or fraudulent misrepresentation; for breach of your legal rights in relation to the Items, as summarised at part 7 above; or for defective Items under the Consumer Protection Act 1987. Similar to the previous paragraph, we shall not be liable for any loss or harm incurred by you as a consequence of your own violation of these Terms or as a consequence of any malfunction of any IT hardware or software other than the malfunction of our Applications.
`,

    ` # 12. Data Protection

We process your personal information in compliance with our [Privacy Policy](/legal/privacy) 
`,

    ` # 13. Customer Care

- General: Customer care is extremely important to us. Subject to paragraphs 13.5 and 14, our Customer Care team will therefore try to assist you where possible if you have any problems with your Order. By selecting the "Need Assistance?", "Assistance" or similar symbol, or by calling the phone number shown on the website.
- Questions about your Order: If your Order is taking longer than expected or you have any other problems with your Order, you can contact our Customer Care Team as described above and one of our Customer Care Advisers will attempt to contact the Seller in order to follow up on your query.
- Changing or cancelling your Order: If you wish to change or cancel your Order after it has been submitted and payment has been authorised, you may contact our Customer Care team as described above and they will attempt to contact the Seller in order to communicate your requests. However, there is no guarantee that we will be able to reach the Seller or that the Seller will agree to your requests as they may have already started processing your Order.
- Complaints or feedback: In the event that you are dissatisfied with the quality of any Products or the service provided by a Seller, Please consider providing feedback to reflect your experience in the form of ratings, comments and reviews on the Website (together, "Reviews"). Reviews are a key component of our quality control process.
- Compensation: If you are dissatisfied with the quality of any Products or the service provided by a Seller and wish to seek a refund, a proportionate price reduction or any other compensation, you should contact the Seller directly to lodge your complaint and, where appropriate, follow the Seller’s own complaint procedures. If you are unable to contact the Seller, or the Seller refuses to deal with your complaint, you can contact our Customer Care Team as described above within 48 hours of placing your Order and one of our Customer Care Advisers will attempt to contact the Seller in order to request compensation on your behalf. Please note, however, that the legal contract for the supply and purchase of Products is between you and the Seller that you place your Order with. We have no control over Sellers and the quality of the Products or service that they provide, and we are not able to provide, and have no responsibility or liability for providing, any compensation to you on behalf of any Seller.
`,

    ` # 14. Disclaimers

1. Website information: While we try to ensure that information on the Website is correct, we do not promise it is accurate or complete. We may, at any time without notice, make changes to the material on the Website or the functionality, Products and prices described on it. The material on the Website may be outdated and we do not commit to update that material.
2. Allergy, dietary and other menu information: When a Seller signs up with us, they have to provide us with up-to-date product information. We then add this on their Website dedicated page. Where this information includes allergy or other dietary information, we will do our best to republish this information on the website or app exactly as it appears in the Seller’s shop. If you have, or someone you are ordering for has, concern about food allergies, intolerances or other dietary preferences, you should always contact the Seller directly before placing your order. Please do not use the "leave a note for the Seller" box for your food allergies or intolerances, please contact the Seller directly.
3. Seller actions and omissions: The legal contract for the supply and purchase of Products is between you and the Seller that you place your Order with. We have no control over the actions or omissions of any Seller. Without limiting the generality of the foregoing, you acknowledge and accept the following by using the Website:
    - We do not give any undertaking that the Products ordered from any Seller through the Website will be of satisfactory quality or suitable for your purpose and we disclaim any such warranties.
    - Estimated times for deliveries and collections are provided by the Sellers and are only estimates. Neither we nor the Sellers guarantees that Orders will be delivered or will be available for collection within the estimated times.
    - We encourage all our Sellers to accept all Orders and to communicate any rejection promptly, and we will notify you (generally by email) as soon as reasonably practicable if a Seller rejects your Order. However, we do not guarantee that Sellers will accept and fulfil all Orders, and Sellers have the discretion to reject Orders at any time because they are too busy, if you do not have proof of age when buying alcohol, cigarettes or other smoking items, due to weather conditions or for some other purpose. Sellers will not be obliged to deliver an Order to an address outside of their set delivery radius, which may change from time to time.
    - The foregoing disclaimers do not affect your statutory rights against any Seller.
4. Exclusion of terms: We provide you with access to and use of the Website on the basis that, to the maximum extent permitted by law, we exclude all representations, warranties, conditions, undertakings and other terms in relation to the Website and your use of it (including any representations, warranties, conditions, undertakings and other words that may otherwise refer to and use of the Website or may be implied or included in these Words of the Website by statute, common law or otherwise).
`,

    ` # 15. Liability

1. General: Nothing in these Website Terms excludes or limits our liability for death or personal injury arising from our negligence, our liability for fraudulent misrepresentation, or any other responsibility not exempt or restricted by relevant legislation. Nothing in these Terms of Use of the Website affects your statutory rights.
2. Exclusion of liability: Subject to clause 15.1, we will under no circumstances whatever be liable to you, whether in contract, tort (including negligence), breach of statutory duty or otherwise, even if foreseeable, arising under or in connection with the Website (including the use, inability to use or the results of the use of the Website) for:
    - any loss of profits, sales, business, or revenue;
    - loss or corruption of data, information or software;
    - loss of business opportunity;
    - loss of anticipated savings;
    - loss of goodwill; or
    - any indirect or consequential loss.
3. Limitation of liability: Subject to clauses 15.1 and 15.2, our total liability to you in respect of all other losses arising under or in connection with the Website or your use of it, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, shall in no circumstances exceed twice the value of your Order or £100, whichever is lower.
4. Additional costs: You assume full and sole responsibility for any additional or associated costs that you may incur in connection with or as a result of your use of the Website, including costs relating to the operation, repair or modification of any equipment, software or data which you might own, lease, licence or otherwise use.
`,

    ` # 16. Events Outside Our Control 

1. We will not be liable or responsible for any failure to perform or delay in performance of, any of our obligations under these Website Terms that is caused by events outside our reasonable control ("Force Majeure Event").
2. A Force Majeure Event includes any act, event, non-occurrence, omission or accident beyond our reasonable control and includes in particular (without limitation) the following:
    - strikes, lock-outs or other industrial action;
    - civil commotion, riot, invasion, terrorist attack or threat of terrorist attack, epidemic or pandemic, war (whether declared or not) or threat or preparation for war;
    - Fire, explosion, earthquake, flood, seismic, subsequent, disease or other natural disasters; 
    - Impossibility of using rail, sea, aircraft, road transport or any other public or private means of transport;
    - The impossibility of using public or private communication networks; and
    - Any government's actions, decrees, statutes, rules or restrictions.
3. Our performance under these Website Terms is deemed to be suspended for the period that any Force Majeure Event continues, and we will have an extension of time for performance for the duration of that period. We will make use of our best efforts to put every Force Majeure Case to a close or to find a way to satisfy our obligations under these Terms notwithstanding the Force Majeure Case.
`,

    ` # 17. Other Terms

If we or you are entitled to impose these Terms against the others, the right will not be lost, even though a person who has the right to postpone or waive the right to implement it in any case. If a court or other body finds that some aspect of these Terms is unconstitutional or ineffective, the remaining terms will remain unchanged and in effect.

We can alter these Words from time to time. If we make any changes that impact your rights in relation to our Service, we will inform you. Changes to the Terms will not impact any orders that you have put where we have sent the Confirmation Note. These Terms are regulated by Scottish law and you can bring litigation in the Scottish courts in relation to our Service. If you live in England, you may bring legal proceedings in respect of the Items before either the English or the Scottish courts. If you live in Northern Ireland, you may bring legal proceedings in respect of the Items before either the Northern Irish or the Scottish courts. We are required by EU law to provide this link to the EU’s online dispute resolution portal, however, we do not participate in dispute resolution under this process.
`
];

export default function TermsOfServiceForCustomers() {
    return (
        <Page>
            <MetaDecorator
                title="Localo | Terms of Service for Customers"
                description="Localo | Read about our terms of service for customers here"
            />
            <TitleCard overlay="mustard-mint" background={cardBackground}>
                <h1>Localo Terms of Service for Customers</h1>
            </TitleCard>

            <LegalPage
                title="Localo terms of service for customers"
                headers={headers}
                contents={markdown}
                subtitle="Last updated: 17 December 2020"
                subtext={subtext}
            />
        </Page>
    )
}
