import React, { useCallback, useState } from 'react'
import Request from '../../../helpers/request'
import Style from './BasicDetails.module.scss'

export default function BasicDetails({self, getData}) {
    const [selfUpdate, setSelfUpdate] = useState({})

    const updateSelfChange = useCallback((e) => {
        setSelfUpdate({
            ...selfUpdate,
            [e.target.name]: e.target.value
        })
        getData()
    }, [selfUpdate, setSelfUpdate, getData])

    const updateSelf = () => {
        Request(true).patch('/self', selfUpdate).then(e => {
            if (e.status === 200) return window.alert('Your account has been updated!')
            return window.alert('We couldn\'t update your profile! Check all the fields are filled out and try again.')
        })
    }
    return (
        <div style={{display: 'flex', flexDirection: 'column', gap: 5}}>
            <div className={Style['col-2']}>
                <input onChange={updateSelfChange} className="color-mint-green-outline color-grey" type="text" name="name" placeholder="Name" defaultValue={self.name} style={{padding:15}} />
                <input onChange={updateSelfChange} className="color-mint-green-outline color-grey" type="email" name="email" placeholder="Email" defaultValue={self.email} style={{padding:15}} />
            </div>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
                <button onClick={updateSelf} className="button button-view-basket button-green">Update</button>
            </div>
        </div>
    )
}
