import React, { useMemo } from 'react';

import './shop-product-customisation.scss';

export default function ShopProductCustomisation({
  variant,
  customisation,
  setCustomisation,
  closeModal,
  onConfirmSelection = () => {},
}) {
  const shouldBeSelected = (option, value) => {
    const isSetManually = customisation.find((a) => option._id === a.option);

    if (isSetManually && isSetManually.value === value) return true;

    if (!isSetManually && option.default === value) return true;

    return false;
  };

  const updateCustomisation = (opt, val) => {
    const indexOfExisting = customisation.findIndex((a) => a.option === opt);
    const toUpdate = customisation;

    // console.log(opt, val);
    if (indexOfExisting !== -1) {
      toUpdate[indexOfExisting].value = val;
      return setCustomisation(JSON.parse(JSON.stringify(toUpdate)));
    }

    toUpdate.push({ option: opt, value: val });
    setCustomisation(JSON.parse(JSON.stringify(toUpdate)));
  };

  const variantData = useMemo(() => {
    return variant;
  }, [variant]);

  const onConfirmSelectionPressed = () => {
    onConfirmSelection();
    closeModal();
  };

  const getPrice = (priceModifier) => {
    let price = priceModifier;
    let sign = '+';

    let isNegative = Math.sign(price) < 0;

    if (isNegative) {
      sign = '-';
      price *= -1;
    }

    return `${sign}£${price.toFixed(2)}`;
  };

  return (
    <>
      <button
        className="shop-product-customisation-close"
        onClick={() => closeModal(false)}
      ></button>
      <div className="shop-product-customisation">
        <p className="shop-product-customisation-title">
          Customise {variant?.title} &amp; confirm selection
        </p>
        {variantData && variantData.title ? (
          <>
            {variantData.customisation.map((cus, a) => {
              return (
                <div
                  key={`opt_${a}`}
                  className="shop-product-customisation-choice"
                >
                  <p className="shop-product-customisation-choice-title">
                    {cus.title}
                  </p>

                  <div>
                    {cus.options.map((opt, i) => {
                      return (
                        <div
                          key={`opt_${a}_${i}`}
                          className="shop-product-customisation-choice-option"
                        >
                          <input
                            type="checkbox"
                            checked={shouldBeSelected(cus, opt._id)}
                            onClick={() =>
                              updateCustomisation(cus._id, opt._id)
                            }
                            id={`opt_${a}_${i}`}
                          />
                          <label htmlFor={`opt_${a}_${i}`}>
                            <p className="shop-product-customisation-choice-option-name">
                              {opt.name}
                            </p>
                          </label>

                          <p className="shop-product-customisation-choice-option-price">
                            {getPrice(opt.priceModifier)}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </>
        ) : (
          ''
        )}
      </div>
      <div className="shop-product-customisation-update">
        <button
          className="button button-green-solid"
          onClick={onConfirmSelectionPressed}
        >
          Confirm Selection
        </button>
      </div>
    </>
  );
}
