import React from 'react';
import ContainerComponent from '../container/container';

import './join-localo-what-sell.scss';

export default function JoinLocaloWhatSell() {
    return (
        <div className='join-localo-what-sell'>
            <ContainerComponent>
                <h2 className='join-localo-what-sell__title'>What can you sell on Localo?</h2>
                <p className='join-localo-what-sell__desc'>
                    Localo is an online marketplace where independent local shops can compete with the big chains at a community level. It’s a place to sell your products in simple, pre-designed or customised boxes, ordered regularly by your local customers. </p>
            </ContainerComponent>
        </div>
    )
}