import React from 'react'
import Page from '../../components/page/page';
import LegalPage from '../../components/legal-page/legal-page';
import TitleCard from '../../components/title-card/TitleCard';
import cardBackground from '../../assets/privacy-policy-banner/bg-banner.png';
import MetaDecorator from '../../hooks/metaDecorator';

const headers = [
    'Mission statement',
    'Responsibility',
    'Policy aims',
    'Paper',
    'Energy and water',
    'Office supplies',
    'Transportation',
    'Maintenance and cleaning',
    'Monitoring and improvement',
    'Culture',
];

const markdown = [
    `# Mission statement

Localo identifies that it has an obligation to the environment beyond legal and regulatory requirements. We are devoted to reducing our environmental impact and continually improving our environmental performance as a vital part of our business policy and operating methods, with regular review points. We will encourage other stakeholders, customers and suppliers to do the same.
`,

    `# Responsibility

The Directors are accountable to ensure that the environmental rule is applied. However, the entire employees have a duty in their area to make sure that the goals and purposes of the policy are met.
`,

    `# Policy aims

We make an effort to:

- Observe and exceed all-important regulatory necessities.
- Continually progress and monitor ecological performance.
- Constantly improve and lessen environmental effects.
- Integrate environmental issues into trade decisions.
- Increase members of staffs mindfulness and training.
`,

    `# Paper

- We will reduce the usage of paper in the workplace.
- We will decrease excessive wrapping as much as possible.
- We will seek out to buy reused and eco-friendly paper items.
- We will recycle and reuse all paper wherever possible.
`,

    `# Energy and water

- We will endeavour to cut the quantity of energy used as much as possible.
- We will switch off lights and electrical equipment when not in use.
- A heating system will be adjusted with energy usage in mind.
- The energy usage and efficiency of new products will be taken into account when purchasing.
`,

    `# Office supplies

- We will assess if the need can be met differently.
- We will estimate if hiring/sharing is an alternative before acquiring equipment.
- We will calculate the environmental effect of any new items we anticipate to purchase.
- We will support other ecologically friendly and resourceful products wherever possible.
- We will recycle and reuse everything as much as we can.
`,

    `# Transportation

- We will ease the need to travel unnecessary, restricting to only essential trips.
- We plan to promote alternatives methods to travelling through communicating with phone conferencing / video or e-mail.
- We will make extra efforts to accommodate the needs for staffs using bicycles or public transport.
- We will support ‘green' automobiles and uphold them rigorously to certify continuing efficiency.
`,

    `# Maintenance and cleaning

- Cleaning supplies used will be as ecologically friendly as possible.
- Materials used in workplace renovation will be as environmentally friendly as possible.
- We will only use certified and suitable bodies to dispose of discarded items.
`,

    `# Monitoring and improvement

- We will obey and surpass all relevant legislation required for our business.
- We will constantly improve and observe environmental performance.
- We will repeatedly improve and decrease environmental effects.
- We will add in environmental fundamentals into business resolutions.
- We will increase workers awareness through training.
- We will analysis this policy and any linked business matters at our once-a-month management meetings.
`,

    `# Culture

- We will include staff in the execution of this policy, for better commitment and enhanced performance.
- We will bring up-to-date this policy at least once every twelve months in consultation with other stakeholders and employee and where necessary.
- We will offer staff with appropriate environmental training.
- We will engage with suppliers, sub-contractors and contractors to help increase their environmental performance.
- We will use nearby labour and resources where possible to help decrease CO2 and assist the community.
`
];

export default function EnvironmentalPolicy() {
    return (
        <Page>
            <MetaDecorator
                title="Localo | Environmental policy"
                description="Localo | Read about our environmental policy here"
            />
            <TitleCard overlay="mustard-mint" background={cardBackground}>
                <h1>Localo Environmental Policy</h1>
            </TitleCard>

            <LegalPage
                title="Localo environmental policy"
                headers={headers}
                contents={markdown}
                subtitle="Last Reviewed: 29/08/2020"
                subtext={null}
            />
        </Page>
    )
}
