import React, { Component } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import About from './pages/About';
import DetailedProduct from './pages/DetailedProduct';
import JoinLocalo from './pages/JoinLocalo';
import Home from './pages/Home';
import Products from './pages/Products';
import Basket from './pages/Basket';
import LoginOrRegister from './pages/LoginOrRegister';
import Activate from './components/login/Activate';
import Logout from './components/login/Logout';
import Privacy from './pages/legal/Privacy';
import EnvironmentalPolicy from './pages/legal/EnvironmentalPolicy';
import FoodHygieneSafetyPolicy from './pages/legal/FoodHygieneSafetyPolicy';
import TermsOfServiceForCustomers from './pages/legal/TermsOfServiceForCustomers';
import WebsiteAppTermsOfService from './pages/legal/WebsiteAppTermsOfService';
import TermsAndConditionsForSellers from './pages/legal/TermsAndConditionsForSellers';
import CookiesPolicy from './pages/legal/CookiesPolicy';
import Contact from './pages/Contact';
import OrderConfirmation from './pages/OrderConfirmation';
import SiteMap from './pages/SiteMap';
import PasswordReset from './components/login/Reset';
import ProductsListing from './pages/ProductsListing';
import Account from './pages/Account';
import ItisonPage from './pages/Itison';
export default class Router extends Component {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route exact={true} path='/' component={Home} />
          <Route exact={true} path='/product-listing' component={ProductsListing} />

          <Route exact={true} path='/sitemap' component={SiteMap} />

          <Route exact={true} path='/join-localo' component={JoinLocalo} />
          <Route exact={true} path='/legal/privacy' component={Privacy} />
          <Route exact={true} path='/legal/environmental-policy' component={EnvironmentalPolicy} />
          <Route exact={true} path='/legal/food-hygiene-and-safety-policy' component={FoodHygieneSafetyPolicy} />
          <Route exact={true} path='/legal/terms-of-service-for-customers' component={TermsOfServiceForCustomers} />
          <Route exact={true} path='/legal/website-and-app-terms-of-Service' component={WebsiteAppTermsOfService} />
          <Route exact={true} path='/legal/terms-and-conditions-for-sellers' component={TermsAndConditionsForSellers} />
          <Route exact={true} path="/legal/cookies-policy" component={CookiesPolicy} />
          <Route exact={true} path='/contact' component={Contact} />
  
          <Route exact={true} path='/itison' component={ItisonPage} />

          <Redirect exact={true} path='/products' to='/' />
          <Route
            exact={true}
            path='/products/:postcode'
            component={Products}
          />
          <Route
            exact={true}
            path='/products/:postcode/:type'
            component={Products}
          />
           <Route
            exact={true}
            path='/products/:postcode/:type/categories/:category'
            component={Products}
          />
          <Route
            exact={true}
            path='/tag/:tag/:type'
            component={Products}
          />
          <Redirect
            exact={true}
            from='/products/:postcode/categories/back'
            to='/products/:postcode/categories'
          />
          <Route
            exact={true}
            path='/products/:postcode/:type/:vendorId'
            component={DetailedProduct}
          />
          <Route
            exact={true}
            path='/tag/:tag/:type/:vendorId'
            component={DetailedProduct}
          />
          <Route
            exact={true}
            path='/products/:postcode/:type/:vendorId/:productId'
            component={DetailedProduct}
          />
            <Route
              exact={true}
              path='/tag/:tag/:type/:vendorId/:productId'
              component={DetailedProduct}
            />
          <Route
            exact={true}
            path='/products/:postcode/:type/:vendorId/:productId/:variantId'
            component={DetailedProduct}
          />

          <Route exact={true} path='/basket' component={Basket} />
          {/* <Route exact={true} path="/basket/schedule" component={ Basket } />
                    <Route exact={true} path="/basket/schedule/edit" component={ Basket } />
                    <Route exact={true} path="/basket/login" component={ Basket } />
                    <Route exact={true} path="/basket/payment" component={ Basket } />
                    <Route exact={true} path="/basket/checkout" component={ Basket } />
                    <Route exact={true} path="/basket/confirmation" component={ Basket } /> */}

          <Route exact={true} path='/about' component={About} />
          <Route exact={true} path='/contact' />

          <Route exact={true} path='/login' component={LoginOrRegister} />

          <Route exact={true} path='/account' component={Account} />
          <Route exact={true} path='/activate' component={Activate} />
          <Route exact={true} path='/reset' component={PasswordReset} />
          <Route exact={true} path='/logout' component={Logout} />
          <Route
            exact={true}
            path='/order/thank-you'
            component={OrderConfirmation}
          />
        </Switch>
      </BrowserRouter>
    );
  }
}
