import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/logo.svg';
import session from '../../stores/session';
import Dropdown from './dropdown';

import './header.scss';
import { view } from '@risingstack/react-easy-state';
import HeaderBasket from '../header-basket/header-basket';
const account = 'account'
const basket = 'basket'
export default view(function Header({location}) {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [active, setActive] = useState()
  const menuContent = React.useMemo(() => {
    return (
      <ul>
        <li>
          <Link to="/">Home</Link>
        </li>
        <li>
          <Link to="/product-listing">Product</Link>
        </li>
        {/* <li>
          <Link to='/account'>Account</Link>
        </li> */}
        <li>
          <Link to="/contact">Contact</Link>
        </li>
        <li>
          <Link to="/about">About</Link>
        </li>
        {!isLoggedIn ? (
          <li>
            <Link
              to={{ pathname: '/login', state: { isShowCreateAccount: true } }}
            >
              Create Account
            </Link>
          </li>
        ) : null}
        <li>
          <Link to="/join-localo">Sellers</Link>
        </li>
        {isLoggedIn ? (
          <>
            <li>
              <Link to="/account">Account</Link>
            </li>
            <li>
              <Link to="/logout">Log Out</Link>
            </li>
          </>
        ) : (
          <li>
            <Link to="/login">Login</Link>
          </li>
        )}
      </ul>
    );
  }, [isLoggedIn]);
  const setLoggedIn = () => {
    if (session.token) setIsLoggedIn(true);
  };
  
  useEffect(setLoggedIn, [session.token]);
  useEffect(()=>{
    if(location.pathname.includes('account')){
      setActive(account)
    } else if(location.pathname.includes('basket')){
      setActive(basket)
    }
  }, [location])
  return (
    <header>
      <div className="content-wrapper">
        <div className="spacer" />
        <Link to="/" className="main-logo">
          <span className="logo-wrapper">
            <div className="logo-background"></div>
            <img id="logo" src={logo} alt="Localo" />
          </span>
        </Link>
        <div className="header-buttons">
        {active === account ? 
        <Link key='active' className="link-account" to="/account">
            <i  title="Account" className="far fa-user active"></i>
            <span className="account active">Account</span>
          </Link>
          :
          <Link key='normal' className="link-account" to="/account">
            <i  title="Account" className="far fa-user"></i>
            <span className="account">Account</span>
          </Link>
          }
          {active === basket ?
            <Link key="activebasket" to="/basket?step=1">
            <HeaderBasket active={active}/>
          </Link>
           :
          <Link key="normalbasket" to="/basket?step=1">
            <HeaderBasket active={false}/>
          </Link>
          }

          <Dropdown title="Menu" className="menu-icon" dropdown={menuContent}></Dropdown>
        </div>
      </div>
    </header>
  );
});
