import React from 'react';
import { Link } from 'react-router-dom';

import './shop-grid-item.scss';

export default function ShopGridItem({
  _id: id,
  name,
  category,
  images,
  address,
  postcode,
}) {  
  return (
    <Link
      to={`/products/${postcode}/shops/${id}`}
      className='shop-grid-item-layout'
    >
      <div className='shop-grid-item'>
        <div className='shop-grid-item-header'>
          <img alt='' src={images && images.length ? images[0].url : ''} />
        </div>
        <div className='shop-grid-item-meta-title'>
          {name}
        </div>
        <div className='shop-grid-item-meta-address'>
          {category ? <p>{category.name}</p> : ''}
          {address ? <p> {address.locality}</p> : ''}
        </div>
      </div>
    </Link>
  );
}
