import React, { useEffect, useState } from 'react';
import Request from '../../helpers/request';
import Promotion from '../promotion/promotion';
import FeaturedBoxes from './featured-box';
import FeaturedCategories from './featured-categries';

export default function ProductIndex({ postcode, history, match }) {
  const [featuredCategories, setFeaturedCategories] = useState([]);

  useEffect(() => {
    fetchFeaturedBoxes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchFeaturedBoxes = () => {
    Request(false)
      .get(`/categories?postcode=${postcode}&featured=1`)
      .then((res) => {
        if (res.data) {
          const list = res.data.data;
          setFeaturedCategories(list);
        }
      });
  };

  return (
    <>
      <Promotion />
      <FeaturedBoxes postcode={postcode} />
      {featuredCategories.map((item) => {
        return React.Children.toArray(
          <FeaturedCategories
            postcode={postcode}
            history={history}
            match={match}
            item={item}
          />
        );
      })}
    </>
  );
}
