import React from 'react';

import './progress-bar.scss';

export default function ProgressBar({ currentStep }) {
    return (
        <div className='progress-bar'>
            <div className={`progress-bar__item ${currentStep === 1 ? 'progress-bar__item--current' : ''}`}>
                <div className='progress-bar__title'>Confirm Products</div>
                <div className='progress-bar__line'></div>
            </div>
            <div className={`progress-bar__item ${currentStep === 2 ? 'progress-bar__item--current' : ''}`}>
                <div className='progress-bar__title'>Choose how often</div>
                <div className='progress-bar__line'></div>
            </div>
            <div className={`progress-bar__item ${currentStep === 3 ? 'progress-bar__item--current' : ''}`}>
                <div className='progress-bar__title'>Payment</div>
                <div className='progress-bar__line'></div>
            </div>
        </div>
    )
}