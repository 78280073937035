import React from 'react'

import './top-component.scss'

export default function TopComponent(props) {
    return (
        <div className="top-component">
            {props.children}
        </div>
    )
}
