import React from 'react';
// import { useEffect } from 'react';

// import request from '../../helpers/request';

import './shop-detailed-header.scss';

export default function ShopDetailedHeader({
  vendor: vendorId,
  postcode,
  tag,
  type,
  history,
}) {
  // const [vendor, setVendor] = useState({});

  // useEffect(() => {
  //   async function getVendor() {
  //     setVendor((await request(false).get(`/vendors/${vendorId}`)).data);
  //   }

  //   getVendor();
  // }, [vendorId]);

  return (
    <div className="shop-detailed-header">
      <button className="button button-green" onClick={() => history.goBack()}>
        <span className="fas fa-angle-left"></span>&nbsp;Back
      </button>
      {/* <p>From {vendor.name ? vendor.name : 'a local shop'}</p> */}
    </div>
  );
}
