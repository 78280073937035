const Errors = {
  "CART_NOT_FOUND": "We were not able to find your cart, try reloading the website.",
  "DISTANCE_ERROR": "We were not able to calculate the delivery distance.",
  "DELIVERY_TOO_FAR": "You live too far from the vendor for delivery.",
  "STRIPE_ERROR": "We were unable to process your payment, you were not charged.",
  "NOT_YOUR_PAYMENT_METHOD": "This payment method doesn't belong to you.",
  "PAYMENT_FAILED": "We were unable to take payment. Please try another card.",
  "GENERIC": "We've been alerted to the issue, but please try again.",
  "INVALID_TOKEN": "The token is invalid, try requesting a new one."
}

export default Errors