import React from 'react';
import { useEffect } from 'react';
import Request from '../../helpers/request';

export default function Activate ({ location, history, ...props })
{
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const token = params.get('token');

        if (token !== null) {
            Request(false).post('auth/activate', {
                token: token
            }).catch(e => {
                // Absorb;
            });
        }
        
        history.push('/');
    });

    return(
        <></>
    );
}
