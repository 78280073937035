import React, { useState } from 'react';
import SimpleSelect from 'react-simple-styleable-select';
import ContainerComponent from '../container/container';
import shopKeeper from '../../assets/join-localo/shop-keeper-join.png';

import './join-localo-form.scss';
import { useForm } from 'react-hook-form';
import Request from '../../helpers/request';

export default function JoinLocaloForm() {
  const options = [
    { value: 1, label: 'Grocer' },
    { value: 2, label: 'Butcher' },
    { value: 3, label: 'Greengrocer' },
    { value: 4, label: 'Fishmonger' },
    { value: 5, label: 'Cheesemonger' },
    { value: 6, label: 'Baker' },
    { value: 7, label: 'Deli' },
    { value: 8, label: 'Chilled food' },
  ];

  const [shopType, setShopType] = useState('');
  const [shopTypeError, setShopTypeError] = useState('');
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm({});

  const onSelectShopType = (e, index) => {
    let selected = options[index];
    setShopTypeError(false);
    setShopType(selected.label);
  };

  const onSubmit = (form) => {
    if (!shopType) {
      setShopTypeError(true);
      return;
    }
      Request(true)
        .post('/forms', {
          type: 'Join Localo',
          data: {
            ...form,
            shopType
          },
        })
        .then(() => {
          reset([]);
        });
  };

  return (
    <div className="join-localo-form" id="join-localo">
      <ContainerComponent>
        <div className="join-localo-form__title">
          Join Localo, the local marketplace helping local shoppers choose to
          buy with independent retailers just like you.
        </div>
        <div className="join-localo-form__form">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="join-localo-form__col1-on-laptop">
              <div className="join-localo-form__field">
                <input
                  type="text"
                  className="join-localo-form__control"
                  placeholder="Business name"
                  {...register('businessName', {
                    required: true,
                    maxLength: { value: 20, message: 'length must be < 20' },
                  })}
                />
                {errors.businessName && (
                  <span className="error">
                    {errors.businessName.message || 'This field is required'}
                  </span>
                )}
              </div>
              <div className="join-localo-form__field">
                <input
                  type="text"
                  className="join-localo-form__control"
                  placeholder="Your name"
                  {...register('name', {
                    required: true,
                    maxLength: { value: 20, message: 'length must be < 20' },
                  })}
                />
                {errors.name && (
                  <span className="error">
                    {errors.name.message || 'This field is required'}
                  </span>
                )}
              </div>
            </div>
            <div className="join-localo-form__col1-on-laptop">
              <div className="join-localo-form__field">
                <input
                  type="text"
                  className="join-localo-form__control"
                  placeholder="Address"
                  {...register('address', {
                    required: true,
                    maxLength: { value: 20, message: 'length must be < 20' },
                  })}
                />
                {errors.address && (
                  <span className="error">
                    {errors.address.message || 'This field is required'}
                  </span>
                )}
              </div>
              <div className="join-localo-form__field">
                <SimpleSelect
                  onChange={onSelectShopType}
                  options={options}
                  hasSharpCorners
                  fixedHeight
                />
                {shopTypeError && (
                  <span className="error">{'This field is required'}</span>
                )}
              </div>
            </div>
            <div className="join-localo-form__col2">
              <div className="join-localo-form__field">
                <input
                  type="text"
                  className="join-localo-form__control"
                  placeholder="Area"
                  {...register('area', {
                    required: true,
                    maxLength: { value: 20, message: 'length must be < 20' },
                  })}
                />
                {errors.area && (
                  <span className="error">
                    {errors.area.message || 'This field is required'}
                  </span>
                )}
              </div>
              <div className="join-localo-form__field">
                <input
                  type="text"
                  className="join-localo-form__control"
                  placeholder="Postcode"
                  {...register('postcode', {
                    required: true,
                    maxLength: { value: 20, message: 'length must be < 20' },
                  })}
                />
                {errors.postcode && (
                  <span className="error">
                    {errors.postcode.message || 'This field is required'}
                  </span>
                )}
              </div>
            </div>
            <div className="join-localo-form__col2">
              <div className="join-localo-form__field">
                <input
                  type="text"
                  className="join-localo-form__control"
                  placeholder="Phone number"
                  {...register('phoneNumber', {
                    required: true,
                    minLength: {
                      value: 10,
                      message: 'Please enter a valid phone number',
                    },
                    maxLength: {
                      value: 12,
                      message: 'Please enter a valid phone number',
                    },
                  })}
                />
                {errors.phoneNumber && (
                  <span className="error">
                    {errors.phoneNumber.message || 'This field is required'}
                  </span>
                )}
              </div>
              <div className="join-localo-form__field">
                <input
                  type="text"
                  className="join-localo-form__control"
                  placeholder="Email"
                  {...register('email', {
                    required: true,
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: 'Invalid email address',
                    },
                  })}
                />
                {errors.email && (
                  <span className="error">
                    {errors.email.message || 'This field is required'}
                  </span>
                )}
              </div>
            </div>
            <div className="join-localo-form__field">
              <textarea
                className="join-localo-form__control"
                placeholder="Comments"
                {...register('message', { required: true })}
              ></textarea>
              {errors.message && (
                <span className="error">
                  {errors.message.message || 'This field is required'}
                </span>
              )}
            </div>
            <button className="join-localo-form__submit" type="submit">
              Submit
            </button>
          </form>
        </div>
        <div className="join-localo-form__infographic">
          <img src={shopKeeper} alt="Join Localo" />
          <span className="join-localo-form__fruit-right"></span>
          <span className="join-localo-form__fruit-left"></span>
        </div>
      </ContainerComponent>
    </div>
  );
}
