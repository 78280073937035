import React from 'react';
import { Link } from 'react-router-dom';
import OneTimeOrderItem from '../order-item/one-time-order';
import OrderItem from '../order-item/order-item';

import './order-confirmation.scss';

const OrderConfirmation = () => {
  return (
    <>
    <div className='order__confirmation-layout'>
      <button className='order__confirmation-btn button button-green'>
        back
      </button>
      <div className='order__confirmation-section_one'>
        <h2 className='order__confirmation-heading'>Order confirmation</h2>
        <p className='order__confirmation-paragraph'>
          Thanks, we have received your payment! You can change or pause your
          deliveries anytime in your account. We’ll also email you a summary of
          your orders for future reference.
        </p>
      </div>
      <h2 className='order__confirmation-number'>
        Your order number is #123456
      </h2>
      <div className='order__confirmation-section_two'>
        <h2 className='order__confirmation-summary'>Your order summary</h2>
        <OrderItem
          title='A Butcher Box'
          text='A box for 4 people 1 time per month'
          price='$10.00x1'
        />
        <OrderItem
          title='A Butcher Box'
          text='A box for 4 people 1 time per month'
          price='$10.00x1'
        />
        <OrderItem
          title='A Butcher Box'
          text='A box for 4 people 1 time per month'
          price='$10.00x1'
        />
      </div>
      <div className='order__confirmation-section_three'>
        <h2 className='order__confirmation-list_item'>
          Your weekly Subscription totals
        </h2>
        <OneTimeOrderItem item='week' price='£6.99' />
        <OneTimeOrderItem item='week' price='£6.99' />
        <OneTimeOrderItem item='week' price='£6.99' />
        <OneTimeOrderItem item='week' price='£6.99' />
      </div>
      <div className='order__confirmation-section_four'>
        <h2 className='order__confirmation-list_item'>Your one-time orders</h2>
        <OneTimeOrderItem item='Box name' price='£x.xx' />
      </div>
    </div>
    <div className="not-on-laptop">
      <div className='sticky-step-1-bar'>
        <Link to='/'>
          <div className='back'>
            Home
          </div>
        </Link>
      </div>
    </div>
    </>
  );
};

export default OrderConfirmation;
