import React from 'react';

import Container from '../components/container/container';
import Page from '../components/page/page';
import OrderConfirmationComponent from '../components/order-confirmation-page/Order-confirmation';

const OrderConfirmation = () => {
  return (
    <Page>
      <Container>
        <OrderConfirmationComponent />
      </Container>
    </Page>
  );
};

export default OrderConfirmation;
