import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import Container from '../components/container/container'
import header from '../components/header/header'
import Page from '../components/page/page'
import TopComponent from '../components/top-component/top-component'
import Request from '../helpers/request'

require('../scss/page-sitemap.scss')

export default function SiteMap() {

  const [highstreets, setHighstreets] = useState([])
  const [vendors, setVendors] = useState([])
  const [products, setProducts] = useState([])

  useEffect(() => {
    Request(false).get('/highstreets?limit=500').then(e => setHighstreets(e.data.data))
    Request(false).get('/vendors?limit=500').then(e => setVendors(e.data.data))
    Request(false).get('/products?limit=500').then(e => setProducts(e.data.data))
  }, [])

  return (
    <Page>
    <TopComponent/>
    <Container>
      <h1>Site Map</h1>
      <div className='sitemap-wrapper'>
      {
        highstreets.map(hs => {
          return (<div className="highstreet">
            <h2><a href={`/products/${hs._id}/shops`}>{hs.name}</a></h2>

            {
              vendors.filter(a => a.highstreet === header._id && hs._id === a.highStreet).map(vendor => {
                return (<div className="vendor">
                  <h3><a href={`/products/${hs._id}/shops/${vendor._id}`}>{vendor.name}</a></h3>

                  {
                    products.filter(prod => prod.vendor !== null && prod.vendor._id === vendor._id).map(product => {
                      return (<div className="product">
                        <h4><a href={`/products/${hs._id}/shops/${vendor._id}/${product._id}`}>{product.name}</a></h4>

                        {
                          product.products.map(variant => {
                            return (<div className="variant">
                              <h5><a href={`/products/${hs._id}/shops/${vendor._id}/${product._id}/${variant._id}`}>{variant.title}</a></h5>
                            </div>)
                          })
                        }
                        </div>)
                    })
                  }
                </div>)
              })                 
            }
          </div>)
        })
      }
      </div>
      </Container>
      
    </Page>
  )
}
