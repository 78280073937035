import React from "react";
import Page from "../components/page/page";
import TitleCard from "../components/title-card/TitleCard";
import cardBackground from "../assets/about-us-banner/bag.png";
import { useTranslation } from "react-i18next";
import "../scss/page-about.scss";
import shopImage from "../assets/about-us-slice/shop.png";
import { Link } from "react-router-dom";
import Container from "../components/container/container";
import MetaDecorator from '../hooks/metaDecorator';

export default function About(props) {
  const { t } = useTranslation("common");

  return (
    <Page>
      <MetaDecorator
        title="Localo | Making local shopping easy for you and fair for shops"
        description="Whether it’s a one-off foodbox or a weekly subscription, see how Localo makes buying
        groceries from your local shops quick and easy."
      />
      <TitleCard overlay="mustard-mint" background={cardBackground}>
        <h1>{t("about.title")}</h1>
      </TitleCard>
      <Container>
        <div className="about-block">
         <h1>{t("about.how")}</h1>
          <h2>{t("about.delivered-direct")}</h2>
          <div>
            <h3>{t("about.choose-your-neighbourhood.title")}</h3>
            <p>{t("about.choose-your-neighbourhood.text")}</p>
          </div>
          <div>
            <h3>{t("about.build-your-own-box.title")}</h3>
            <p>{t("about.build-your-own-box.text")}</p>
          </div>
          <div>
            <h3>{t("about.doorstep-delivery.title")}</h3>
            <p>{t("about.doorstep-delivery.text")}</p>
          </div>
        </div>
        </Container>
        <div className="supporting-wrapper">
          <div className="supporting-local">
            <h2>{t("about.supporting-local.title")}</h2>
            <p>{t("about.supporting-local.text")}</p>
          </div>
          <div
            className="supporting-local-image"
            style={{ backgroundImage: `url(${shopImage})` }}
          />
        </div>
        <Container>
        <div className="easy-wrapper">
          <div className="localo-is-easy">
            <h2>{t("about.localo-is-easy.title")}</h2>
            <h3>{t("about.localo-is-easy.subtitle")}</h3>
            <p
              dangerouslySetInnerHTML={{
                __html: t("about.localo-is-easy.text"),
              }}
            />
          </div>
          <div className="video-wrapper">
            <div className="video-16-9">
              <iframe
                title='How Localo works'
                width='100%'
                height='430'
                src='https://www.youtube.com/embed/h3Ee0GLSWHI'
                frameBorder='0'
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                allowFullScreen
              ></iframe>
            </div>
          </div>
          <Link className="get-started" to="/">
            {t("about.get-started")}
          </Link>
        </div>
      </Container>
    </Page>
  );
}
