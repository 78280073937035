import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// import { useHistory } from 'react-router';
// import Request from '../../helpers/request';
import EmptyProductModal from '../empty-product-modal/empty-product-modal';
import './find-local-shops.scss';

export default function FindLocalShops() {
  const { t } = useTranslation('common');
  // const history = useHistory();
  const [emptyModal, setEmptyModal] = useState(false);
  // const [highstreets, setHighstreets] = useState([])
  // const [postcode, setPostcode] = useState('');

  const toggleEmptyModal = () => {
    setEmptyModal(!emptyModal);
  };

  // useEffect(() => {
  //     request(false).get(`/highstreets`).then(data => {
  //         setHighstreets(data.data.data)
  //     })
  // }, [])

  // const highstreetOptions = useMemo(() => {
  //     let toSet = []
  //     highstreets.forEach(e => { toSet.push({ label: e.name, value: e._id }) })
  //     return JSON.parse(JSON.stringify(toSet))
  // }, [highstreets])

  // const onVendorSelect = (a, value) => {
  //     // alert(value)
  //     history.push(`/products/${value}/products`)
  // }

  const postcodeRedirect = () => {
    window.scrollTo(0, 0);
    // if (!postcode) return;
    // Request(false)
    //   .get(`/highstreets/available?postcode=${postcode}`)
    //   .then((response) => {
    //     if (response?.data?.available) {
    //       history.push(`/products/${postcode}/products`);
    //     }
    //     toggleEmptyModal();
    //   })
    //   .catch((e) => {
    //     console.error(e.response);
    //     toggleEmptyModal();
    //   });
  };

  return (
    <div className="find-local-shops">
      <div className="find-local-shops__form">
        <div className="find-local-shops__title">{t('fls.title')}</div>
        <div className="find-local-shops__search">
          {/* <input
            onKeyDown={(_) => {
              if (_.key === 'Enter') {
                postcodeRedirect();
              }
            }}
            placeholder="Your postcode"
            className="field-postcode"
            type="text"
            onChange={(_) => setPostcode(_.target.value)}
          /> */}
          <span className="postcode-btn" onClick={postcodeRedirect}>
            Shop Now
          </span>
        </div>
      </div>
      <EmptyProductModal
        showModal={emptyModal}
        toggleModal={toggleEmptyModal}
      />
    </div>
  );
}
