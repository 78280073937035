import React from 'react'
import Page from '../../components/page/page';
import LegalPage from '../../components/legal-page/legal-page';
import TitleCard from '../../components/title-card/TitleCard';
import cardBackground from '../../assets/privacy-policy-banner/bg-banner.png';
import MetaDecorator from '../../hooks/metaDecorator';

const headers = [
    'These terms',
    '1. What is Delivery Service?',
    '2. Delivery Service Fees',
    '3. What we will do',
    '4. What you must do',
    '5. Use of Delivery Service',
    '6. Service Standards',
    '7. General Requirements',
    '8. Equipment and joining fees',
    '9. Intellectual property',
    '10. Suspension and termination',
    '11. Legal terms',
    '12. Data protection'
];

const markdown = [
    `# These terms

These terms apply to your access to and use of Delivery Service on www.shoplocalo.com and our mobile app (the Marketplace). This service is provided by Localo or us (Localo Ltd company number SC669324). Localo Ltd.’s registered address is Atlantic Chambers, 6th floor 45 Hope Street, Glasgow G2 6AE UK.

By clicking “Accept” or by accepting a Delivery order on the Marketplace you confirm that:

- You have the authority to do so and to bind the person or company you are accepting for.
- You accept our offer to provide the Delivery Service on these terms.
- You agree to and will comply in full with these terms. 

This forms a contract between you and Localo, which commences on the date of your acceptance.  You should save a copy of these terms for your records.  We may make changes to these terms, so check back here from time to time. We’ll notify you of material changes before they happen. If you access or use the Delivery Service after being notified of a change, you’re deemed to have accepted that change.

If you have questions about these terms or our Delivery Service contact team@shoplocalo.com. You may also find answers to your questions here. 

If we need to contact you, we’ll do so via the email address you have provided to us.
`,

    `# 1. What is Delivery Service?

Through its Delivery Service, Localo will provide you with an ordering process that enables customers to place orders with you for delivery on the Marketplace in the UK (Delivery Orders). We may agree to amend the Sites from time to time. Localo will also provide Delivery Services and Customer Support Services for your Delivery Orders - see more on this below.

By signing up to Delivery Service, you authorise Localo to act as your agent to solicit, promote and conclude contracts for Delivery Service orders in your name and on your behalf, and to process customer payments owed to you in respect of those orders. 

Customer payments made in respect of orders placed with you through the Delivery Service will be held by the third-party payment provider on your behalf; payment via the payment provider settles the customer’s payment obligation to you for these orders. 
`,

    `# 2. Delivery Service Fees 

Your use of Delivery Service is subject to our applicable Service Fee. Our service fee is calculated as a percentage of the total (inclusive of VAT) per order, which is deducted after the Delivery Fee.  

You will receive payment for goods, less the Delivery Fee and Service Fee, less any relevant adjustments for refunds paid to customers during the relevant payment period.

You will receive payments via the payment provider in accordance with our payment terms applicable in the country you operate in. You must cooperate with us so that we can comply with our VAT accounting obligations.
`,

    `# 3. What we will do

- If you are new to Localo, when you accept these terms we will on-board you onto our Marketplace. You agree that we may subcontract part of the on boarding process.
- We will, subject to our rights to suspend your use of Delivery Service under these terms, display your site/s and Box items on the Marketplace as available for Delivery Orders.
- We will facilitate and implement a real-time ordering process which will enable customers to place and pay for Delivery Orders on the Marketplace.
- Provide support services for customers to deal with complaints or enquiries relating to Delivery Orders (Customer Support Service) through a professionally manned contact centre at no further cost to you.
- Procure the delivery of Delivery Orders from you to the customer (Delivery Services) using delivery drivers or riders authorised by Localo to carry out the Delivery Services (Delivery Team).
`,

    ` # 4. What you must do

You must:

- Comply with our [Food Hygiene and Safety Policy](/legal/food-hygiene-and-safety-policy) 
- Provide all information, materials and assistance reasonably required by Localo to on-board you to the Marketplace or launch Delivery Service for you.  You must ensure that the information you provide to Localo and customers in or in respect of your use of Delivery Service is complete and accurate in all material respects.
- If you are new to the Marketplace, provide us with accurate descriptions of Box items to be offered in Delivery Service (including any relevant allergen advice and applicable VAT amounts). You agree that
  1. We may make limited and reasonable edits to Box item descriptions from time to time (including where legally required); and
  2. To ensure a great customer experience the prices for the Box items you provide for display on the Marketplace should be the same as the prices for Box items published in-store.
- Display any Delivery Service signage provided by Localo in accordance with our instructions.
`,

    ` # 5. Use of Delivery Service

- Keep your Box descriptions up to date - by giving us at least one months’ notice of changes you require us to make for you or by making your own changes through the Seller Portal (when live). 
- Ensure that all relevant Box items are available to be ordered by a customer in a Delivery Order, and accept and reject Delivery Service orders as appropriate.
- Ensure that Delivery Orders are prepared using all due skill, care and diligence in line with best practice in your industry. 
- Ensure that all Delivery Orders are packaged in a way that avoids tampering and minimises spillages.
- Prepare Delivery Orders promptly, accurately and ready to be collected at the appropriate collection slot. In particular, you must ensure that Box items:
  1. correspond with the descriptions on the Marketplace (including indications that particular Box items are gluten-free, nut-free, or are suitable for vegetarians and/or vegans);
  2. are not harmful to health or the environment;
  3. have been properly prepared and are otherwise safe, fit for transportation and consumption and palatable;
  4. are safely, securely and appropriately packaged at all times (including as reasonably required to withstand delivery).
- Take account of any information relating to customer allergies provided with the Delivery Order.
- Ensure that Delivery Orders are available for collection by our Delivery Team at the appropriate time.
- Ensure that the order number on the Delivery Order packaging corresponds with the order number provided by Localo before the Delivery Order is handed over to the relevant Delivery Team.
`,

    ` # 6. Service Standards

- Use reasonable endeavours to reject less than 1% of Delivery Orders received through the Marketplace.
- Use reasonable endeavours to ensure that Boxes are available for collection by our Delivery Team at the time communicated on the Marketplace and to keep Delivery Teams waiting for Delivery Orders for no longer than five minutes.
- Use reasonable endeavours to ensure that no more than 1% of Delivery Orders contain errors.
`,

    ` # 7. General Requirements

- Maintain the security and confidentiality of any access credentials we provide to you for the Seller Portal (when live) at all times. You are responsible for the actions of any person using your access credentials.
- Comply with all applicable laws and licensing, registration and approvals requirements at all times, and any and all Localo policies made available to you from time to time.
- Without prejudice to your general obligation above, you warrant that you have not and will not do anything that breaches any applicable code and/or sanction relating to the prevention or prohibition of bribery, money laundering and similar activities. You must immediately notify Localo if you become aware of any breach of this clause.
- Without prejudice to your general obligation above, you shall comply, and procure that all of your employees, contractors, agents and representatives comply, with applicable anti-slavery laws, as relevant to your use of Delivery Service and your fulfilment of Delivery Orders. You must use reasonable endeavours on a continuing basis to ensure that no form of slavery takes place in your supply chains.
- Cooperate with Localo and provide, in a timely manner, such assistance and information as Localo may reasonably require. 
`,

    ` # 8. Equipment and joining fees

You will need a computer or smartphone to start accepting Delivery Orders. You can use your own device (which you are responsible for obtaining and maintaining).  Localo reserves the right to introduce joining fees at any time, you shall be notified in advance of any such fees. 
`,

    ` # 9. Intellectual property

All rights, title and interest in and to Localo, any software we provide for your use and any other materials we provide to you under or in connection with these terms are and shall at all times remain Localo’s intellectual property (Localo IP). We grant you a limited, non-exclusive, non-transferable, non-sub licensable, revocable licence to use the Localo IP in the country you operate in during the Term for the sole purpose of using Delivery Service.

You must not (and shall not permit any third party to):  

- copy, adapt, reverse engineer, decompile, modify or make error corrections to any Localo IP other than with our express prior written consent;
- breach, disable, tamper with, or develop or use any workaround for any security measure in any Localo IP or otherwise do anything that disrupts any Localo IP, Localo or any person.

Localo grants you a non-exclusive, royalty-free, non-sub licensable, non-transferable licence to use Localo branding, which includes the “Localo” logo, name and/or website address for the Term in the country you operate in to allow you to advertise Delivery Service at your sites. You must comply with any Localo policy issued from time to time.

You grant us and our affiliates a non-exclusive, royalty-free licence to use your branding which includes your logos, images, videos, name and website address(es) for the Term in the country you operate in. You warrant and undertake that your branding does not and will not infringe any third party intellectual property rights.  We can use your branding on the Marketplace, for marketing purposes and as reasonably necessary to provide Delivery Service.

Except for these limited licences:

- Localo retains ownership of and all rights in and to Localo branding; and
- You retain ownership of and all rights in and to your branding. 

We may collect data about your use of Delivery Service. By using Delivery Service you agree to us collecting and using this information. We may use this information for various purposes, including to improve, maintain, protect and develop our products, and to provide services to you.
`,

    ` # 10. Suspension and termination

We may suspend your use of the Marketplace on giving you notice if we know or suspect you have breached these terms, or if we otherwise consider suspension reasonably necessary taking account of all relevant circumstances. 

We will give you the reasons for the suspension where permitted by law. We will maintain the suspension until you have remedied the breach to our reasonable satisfaction or we no longer consider the suspension necessary in the circumstances.

These terms take effect on acceptance (see above) and continue until terminated by either party giving 30 days’ written notice to the other. If permitted by law and where we consider immediate termination necessary in the circumstances, we may give you written notice to terminate these terms with immediate effect. 
`,

    ` # 11. Legal terms

SAVE AS SET OUT BELOW, WE EXCLUDE ALL LIABILITY TO THE MAXIMUM EXTENT PERMITTED BY LAW. WE HAVE NO LIABILITY FOR INCIDENTAL, CONSEQUENTIAL, DIRECT, INDIRECT OR SPECIAL DAMAGES OF ANY KIND OR FOR LOSS OF PROFITS, REVENUE OR BUSINESS ARISING OUT OF OR RELATING TO DELIVERY SERVICE, ORDERS PLACED BY CUSTOMERS USING DELIVERY SERVICE OR THESE TERMS.

Localo will be responsible for customer claims that a Delivery Order has been spilt or crushed in the course of the delivery (except where the Delivery Order wasn’t packaged in accordance with these terms). 

Customers may be eligible for compensation in respect of a Delivery Order. Localo will determine this in accordance with its Complaints Matrix from time to time.  Refunds for which you are responsible under our Complaints Matrix will be deducted from our payment to you.

DELIVERY SERVICE IS OTHERWISE PROVIDED AS-IS. WE DISCLAIM ALL WARRANTIES AND CONDITIONS, EXPRESS, IMPLIED, OR STATUTORY, WITH RESPECT TO DELIVERY SERVICE INCLUDING, BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY, SATISFACTORY QUALITY, FITNESS FOR A SPECIFIC REASON, PRECISION, PEACEFUL ENJOYMENT, AND NO THIRD-PARTY RIGHTS.

Each party shall not at any time during this agreement and for a period of two years after termination of this agreement, use or disclose to any person any confidential information concerning the business, affairs, customers, clients or suppliers of the other party, except as necessary to perform its obligations or exercise its rights under these terms. A party may disclose the other’s confidential information if required by law, provided it notifies the other in advance where permitted. 

These terms are the whole agreement we have with respect to Delivery Service. You accept that you have not relied on any assertion, pledge, representation, guarantee or warranty rendered or provided by or on behalf of us that is not set out in these terms, and that you shall have no claim for innocent or negligent misrepresentation based on any assertion in these terms.

A delay in enforcing these terms is not a waiver. If part of these terms is found to be illegal or unenforceable, the rest of the terms remain in force. These terms do not provide any protection under the Agreements (Rights of Third Parties) Act 1999. 

None of us shall be liable for the violation of these terms or for the delay in the performance or non-performance of any of its obligations under these terms if the delay or failure arises from incidents, circumstances or causes beyond our rational control. 

These terms are governed by the laws of Scotland. The courts of Scotland have exclusive jurisdiction in respect of any dispute or claim arising out of or in connection with these terms. If you wish to raise a dispute in connection with these terms you may do so in connection with our Support Policy. 
`,

    ` # 12. Data protection

We will each comply with the following with respect to data protection.

1. **DEFINED TERMS**

Controller, Data Subject, Personal Data and processing all have the meanings given to them in DP Laws (and related terms like process have corresponding meanings).

Complaint a notice, complaint or request relating to the obligations of either party under DP Laws that is relevant to the Protected Data.

Data Subject Request a Data Subject’s request to exercise their rights under DP Laws.

DP Laws any law, enactment, regulation, regulatory policy, by law, ordinance or subordinate legislation relating to the processing, privacy, and use of Personal Data, that applies to the Partner, Localo and/or the Delivery Service, including (a) any laws or regulations implementing EU Directives 95/46/EC (Data Protection Directive) or 2002/58/EC (ePrivacy Directive); and (b) the General Data Protection Regulation (GDPR) (Regulation (EU) 2016/679) and all relevant Member State laws giving effect to or corresponding with the GDPR, in each case, as in force and applicable.

Protected Data Personal Data received from or on behalf of the Customer for the purposes of placing a Delivery Service order.

Supervisory Authority any local, national or multinational agency, department, official, parliament, public or statutory person or any government or professional body, regulatory or supervisory authority, board or other body responsible for administering DP Laws. 

2. **USE OF PROTECTED DATA**

We will give you the details of the customer’s Delivery Service order to allow you to process and fulfil it (Order Info).  You must not access or use any Order Info for any purpose other than the fulfilment of the Delivery Service order to which it relates in accordance with these terms.

We will also provide access to Protected Data at your request if you reasonably require access to deal with a customer Complaint or respond to a Data Subject Request.

3. **PROTECTED DATA OBLIGATIONS**

The parties acknowledge and agree that, in respect of the Protected Data, each party is an independent controller. Each party shall comply with DP Laws and its obligations under these terms in connection with the access to and use of Protected Data. 

Each party may deal at its discretion with all Data Subject Requests and Complaints that it receives directly from a Data Subject or the person making the Complaint.

Each party agrees to provide reasonable and prompt assistance to the other party as necessary to assist the other party to ensure compliance with its obligations under DP Laws and enable the other party to comply with Data Subject Requests and/or respond to other queries or Complaints received from Data Subjects or Supervisory Authorities, in each case related to the Protected Data.

To the extent permitted by law, you must not issue any public statement or notification about Protected Data without first obtaining Localo’s consent.
`
];

export default function TermsAndConditionsForSellers() {
    return (
        <Page>
            <MetaDecorator
                title="Localo | Terms &amp; Conditions for Sellers"
                description="Localo | Read about our terms and conditions for sellers here"
            />
            <TitleCard overlay="mustard-mint" background={cardBackground}>
                <h1>Localo Terms &amp;<br />Conditions for Sellers</h1>
            </TitleCard>

            <LegalPage
                title="Localo Terms &amp; Conditions for Sellers"
                headers={headers}
                contents={markdown}
                subtitle="Last updated: 17 December 2020"
            />
        </Page>
    )
}
