import React, { useEffect } from 'react'
import './loader.scss';

export default function Loader({ loadingText }) {
    useEffect(() => {
        return window.scrollTo(0, 0);
      });
    return (
        <div className='loader'>
            <span className='loader__icon'></span>
            <span className='loader__text'>{loadingText ? loadingText : 'Loading...'}</span>
        </div>
    )
}