import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
// import Header from '../components/header/header'
import Page from '../components/page/page';
import ProductGrid from '../components/product-grid/product-grid';
// import ShopGridHeader from '../components/shop-grid-header/shop-grid-header';
import ShopGrid from '../components/shop-grid/shop-grid';
import TopComponent from '../components/top-component/top-component';
import CategoryGrid from '../components/category-grid/category-grid';

import ProductBack from '../components/product-back/product-back';
import ProductNavigator from '../components/product-navigator/product-navigator';
import Request from '../helpers/request';
import ProductIndex from '../components/product-index/product-index';

export default function Products() {
  const match = useParams();
  const history = useHistory();

  const [goTo, setGoTo] = useState(null);

  const TYPE = match.type;

  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(match.category);

  useEffect(() => {
    scrollTo();
    Request(false)
      .get('/categories')
      .then((res) => {
        if (res.data) {
          const list = res.data.data;
          const firstElement = [
            {
              name: 'All categories',
              _id: 'index',
              withIcon: true,
              icon: <i className="all-cate"></i>,
            },
            {
              name: 'All shops',
              _id: 'shops',
              withIcon: true,
              icon: <i className="all-shops-icon"></i>,
            },
          ];
          setCategories([...firstElement].concat(list));
        }
      });
  }, []);

  const scrollTo = (x = 0, y = 0) => window.scrollTo(x, y);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (match.type === 'shops' || (match.type === 'index' && !match.category)) {
      setSelectedCategory(match.type);
    } else {
      setSelectedCategory(match.category);
    }
  }, [match]);

  const onSelectCategory = (id, name) => {
    scrollTo();
    if (id === 'shops') {
      history.push(`/products/${match.postcode}/shops`);
      return true;
    }

    if (id === 'index') {
      history.push(`/products/${match.postcode}/index`);
      return true;
    }

    history.push(`/products/${match.postcode}/categories/categories/${id}`, {
      categoryName: name,
    });
  };
  const categoryName = history?.location?.state?.categoryName;
  return (
    <Page className="product-page">
      <TopComponent>
        <ProductBack
          goTo={goTo}
          history={history}
          postcode={match.postcode}
          type={TYPE}
          tag={match.tag}
        />
      </TopComponent>
      <ProductNavigator
        onSelectCategory={onSelectCategory}
        selectedCategory={selectedCategory}
        categories={categories}
      />

      {TYPE === 'index' ? (
        <ProductIndex
          postcode={match.postcode}
          match={match}
          history={history}
        />
      ) : null}
      {TYPE === 'shops' ? (
        <ShopGrid postcode={match.postcode} match={match} />
      ) : null}
      {TYPE === 'products' ? (
        <ProductGrid postcode={match.postcode} match={match} tag={match.tag} />
      ) : null}
      {TYPE === 'categories' ? (
        <CategoryGrid
          categoryName={categoryName}
          setGoTo={setGoTo}
          postcode={match.postcode}
          match={match}
        />
      ) : null}
      {/* <div className='not-on-laptop sticky-nav-wrap'>
        <StickyActionsProduct
          highstreet={match.highstreet}
          isActive={isActive}
          goTo={goTo}
          history={history}
        />
      </div> */}
    </Page>
  );
}
