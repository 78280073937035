import React from 'react';
import './titleCard.scss';

export default function TitleCard ({ children, overlay, className, background, invert, ...props })
{
    return (
        <div className={ `${className} title-card ${invert ? 'invert' : ''}`}>
            { background ? <div className="background" style={{ backgroundImage: `url(${background}` }}/> : '' }
            { overlay ? <div className={`overlay ${overlay}`}/> : '' }
            { children }
        </div>
    );
}
